import {
  Box, Icon, Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Tooltip,
  useColorModeValue
} from "@chakra-ui/react";
import { FiFile, FiImage } from "react-icons/fi";
import React from "react";
import { getExtension } from "utils/functions";

function LightBox({ fileSource }) {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const iconColor = useColorModeValue('gray.400', 'gray.500');
  //console.log(fileSource && getExtension(fileSource).toLowerCase());

  return (
    <>
      <Modal scrollBehavior={'inside'} isOpen={isOpen} onClose={onClose} size={'2xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody >
            {fileSource && getExtension(fileSource).toLowerCase() === "pdf" ? (
              <object data={fileSource} type="application/pdf" width="100%" height="800px">
                <p>It appears you don't have a PDF plugin for this browser.
                  No biggie... you can <a href={fileSource}>click here to
                    download the PDF file.</a></p>
              </object>
            ) : (
              <Image w={"full"} h={'full'} src={fileSource} borderRadius={2} />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <Box role='group' position={"relative"} w={"40px"} _hover={{ cursor: "pointer", transition: "all 0.3s ease" }}>
        <Tooltip label="click to view" placement='right' hasArrow fontSize='xs'>

          <Box onClick={onOpen}>
            {fileSource && getExtension(fileSource).toLowerCase() === "pdf" ? (
              <Icon
                as={FiFile}
                boxSize={10}
                _hover={{ color: 'gray.500' }}
                onClick={onOpen}
                color={iconColor}
              />
            ) : (
              <Icon
                as={FiImage}
                boxSize={10}
                _hover={{ color: 'gray.500' }}
                onClick={onOpen}
                color={iconColor}
              />
            )}
          </Box>
        </Tooltip>
      </Box>
    </>
  );
}

export default LightBox;
