import { api } from '../api'

export const requestsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getRequests: builder.query({
            query: ({ currentPage, searchValue }) => `/tickets?page=${currentPage}&filter=${searchValue}`,
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['Request'],
        }),
        addNewRequest: builder.mutation({
            query: request => ({
                url: '/tickets',
                method: 'POST',
                body: request
            }),
            invalidatesTags: ['Request']
        }),
        editRequest: builder.mutation({
            query: ({ requestId, payload }) => ({
                url: `/tickets/${requestId}`,
                method: 'PUT',
                body: payload
            }),
            invalidatesTags: ['Request']
        }),
        removeRequest: builder.mutation({
            query: requestId => ({
                url: `/tickets/${requestId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Request']
        }),
        uploadFile: builder.mutation({
            query: payload => ({
                url: '/tickets/upload',
                method: 'POST',
                body: payload
            }),
            //invalidatesTags: ['Request']
        }),
        replyToRequest: builder.mutation({
            query: ({ requestId, payload }) => ({
                url: `/tickets/${requestId}/reply`,
                method: 'PUT',
                body: payload
            }),
            invalidatesTags: ['Request']
        }),
        updateStatus: builder.mutation({
            query: ({ requestId, payload }) => ({
                url: `/tickets/${requestId}/status`,
                method: 'PUT',
                body: payload
            }),
            invalidatesTags: ['Request']
        }),
    })
})

export const {
    useGetRequestsQuery,
    useAddNewRequestMutation,
    useEditRequestMutation,
    useRemoveRequestMutation,
    useUploadFileMutation,
    useReplyToRequestMutation,
    useUpdateStatusMutation,
    usePrefetch
} = requestsApi