import { Box, Button, Fade, Flex, Spinner } from "@chakra-ui/react";

import DropBox from "./DropBox";
import LightBox from "./LightBox";


function FileUploadBox({ fileKey, fileSource, onFileUpload, onRemoveClick, isInvalid, multiUpload, isUploading }) {
    // console.log(fileKey);

    if (isUploading) {
        <Spinner size={'sm'} />
    }

    return (

        <>
            {isUploading ? <Spinner size={'sm'} /> : (
                <>
                    {
                        fileSource?.length > 0 ? (
                            <>
                                {
                                    multiUpload ? (
                                        <Fade in={fileSource}>
                                            <Flex alignItems={'flex-start'} flexWrap={'wrap'}>
                                                {fileSource?.map((file, index) => (
                                                    <Flex flexWrap={'wrap'} flexDirection={"row"} gap={"10px"} minW={"220px"} key={'multiUploadUrl' + index}>
                                                        <LightBox fileSource={file.value} />
                                                        <Box>
                                                            <Button onClick={() => onRemoveClick(index)} size='xs' colorScheme="red">
                                                                Remove
                                                            </Button>
                                                        </Box>
                                                    </Flex>
                                                ))}
                                            </Flex>
                                        </Fade>
                                    ) : (
                                        <Fade in={fileSource}>
                                            <Flex flexDirection={"column"} gap={"10px"} minW={"220px"}>
                                                <LightBox fileSource={fileSource} />
                                                <Box>
                                                    <Button onClick={onRemoveClick} size='xs' colorScheme={'red'}>
                                                        Remove
                                                    </Button>
                                                </Box>
                                            </Flex>
                                        </Fade>
                                    )
                                }
                            </>
                        ) : (
                            <Box>
                                <DropBox onDrop={(file) => { onFileUpload(fileKey, file); }} isInvalid={isInvalid} multiUpload={multiUpload} />
                            </Box>
                        )
                    }
                </>
            )}
        </>
    );
}

export default FileUploadBox;
