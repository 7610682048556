import { api } from '../api'

export const clientsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getClient: builder.mutation({
            query: accountId => ({
                url: `/accounts/${accountId}`,
                method: 'GET',
            }),
        }),
        getClients: builder.query({
            query: ({ currentPage, searchValue }) => `/accounts?page=${currentPage}&filter=${searchValue}`,
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['Clients']
        }),
        getAllClients: builder.query({
            query: () => '/accounts',
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['Clients']
        }),
        addNewClient: builder.mutation({
            query: body => ({
                url: '/accounts',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Clients']
        }),
        editClient: builder.mutation({
            query: ({ accountId, payload }) => ({
                url: `/accounts/${accountId}`,
                method: 'PUT',
                body: payload
            }),
            invalidatesTags: ['Clients']
        }),
        removeClient: builder.mutation({
            query: accountId => ({
                url: `/accounts/${accountId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Clients']
        }),
    })
})

export const { useGetClientMutation, useGetClientsQuery, useEditClientMutation, useAddNewClientMutation, useRemoveClientMutation, usePrefetch, useGetAllClientsQuery } = clientsApi;