import { Navigate, Outlet, useLocation } from 'react-router-dom';

import React from 'react'
import { useSelector } from 'react-redux';

const RequireAuth = ({ allowedRoles }) => {
    const location = useLocation()
    const userInfo = useSelector(state => state.auth?.userInfo)
    //console.log(userInfo.role, isOnboarded);

    //console.log(isOnboarded);
    //console.log(allowedRoles.includes(userInfo.role));
    //return !isOnboarded ? <Navigate to={"/companies"} replace /> : <Outlet />

    // if (!allowedRoles.includes(userInfo.role)) {
    //     return <Navigate to={"/signIn"} state={{ from: location }} replace />
    // }

    // if (userInfo?.role == 'client') {
    // } else {
    //     return <Outlet />
    // }

    // return allowedRoles.includes(userInfo.role) ? (
    //     userInfo?.role == 'client' ? (
    //         isActive ? <Outlet />
    //             : <Navigate to="/companies" state={{ from: location }} replace />
    //     ) : (
    //         <Outlet />
    //     )
    // ) : <Navigate to={"/signIn"} state={{ from: location }} replace />



    return userInfo?.role ? <Outlet />
        : <Navigate to={"/signIn"} state={{ from: location }} replace />


}

export default RequireAuth;