import React from 'react';
import {
    Select
} from "@chakra-ui/react";

function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    //console.log(id);

    return (
        <Select
            size={'xs'}
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            textTransform={'uppercase'}
            fontSize={'10px'}
            variant='unstyled'
        >
            <option value=''>All</option>
            {options.map((option, i) => {
                return (
                    <option key={i} value={option} >
                        {option}
                    </option>
                )
            })}
        </Select>
    )
}

export default SelectColumnFilter