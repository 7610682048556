import { Flex, Heading, Spacer, Text, Spinner } from '@chakra-ui/react'
import AppTableWithPagination from 'components/AppTableWithPagination'
import SidebarWithHeader from 'components/SidebarWithHeader'
import moment from 'moment'
import React, { useState, useMemo } from 'react'
import { useGetLogsQuery } from 'store/logs/logsApi'

function LogsForAdmin() {


    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setsearchValue] = useState('')

    const { data: logs, isLoading, refetch } = useGetLogsQuery({ currentPage, filterValue: searchValue }, { pollingInterval: 10000 })

    //console.log({ logs });

    const columns = useMemo(
        () => [
            {
                Header: 'Activity',
                accessor: 'action',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => {

                    //console.log(original);
                    const { company, profileId, clientId, adsAccount, topup, ticket } = original;

                    let actionStatement = ``;

                    if (original.action == "forex.company.created") {
                        actionStatement = `${company?.name} is created by ${clientId?.name}`;
                    } else if (original.action == "forex.company.verification.request") {
                        actionStatement = `${company?.name}'s verification request sent by ${clientId?.name}`;
                    } else if (original.action == "forex.company.commissions.added") {
                        actionStatement = `Commission added for ${company?.name} by ${profileId?.name}`;
                    } else if (original.action == "forex.company.activated") {
                        actionStatement = `${company?.name} is activated by ${profileId?.name}`;
                    } else if (original.action == "forex.company.deactivated") {
                        actionStatement = `${company?.name} is de-activated by ${profileId?.name}`;
                    } else if (original.action == "forex.adsaccount.request") {
                        actionStatement = `Ads account request #${adsAccount?.name} is raised by ${clientId?.name}`;
                    } else if (original.action == "forex.adsaccount.initialbalance.added") {
                        actionStatement = `Initial balance for ads account #${adsAccount?.name} is added by ${profileId?.name}`;
                    } else if (original.action == "forex.adsaccount.status.created") {
                        actionStatement = `Status updated to active by ${profileId?.name} for ads account #${adsAccount?.name}`;
                    } else if (original.action == "forex.adsaccount.status.pending") {
                        actionStatement = `Status updated to pending by ${profileId?.name} for ads account #${adsAccount?.name}`;
                    } else if (original.action == "forex.topup.request.created") {
                        actionStatement = `Topup request #${topup?.name} is created by ${clientId?.name}`;
                    } else if (original.action == "forex.topup.received.amount") {
                        actionStatement = `Amount received is updated by ${profileId?.name} for topup #${topup?.name}`;
                    } else if (original.action == "forex.topup.status.toppedup") {
                        actionStatement = `Status updated to topped up by ${profileId?.name} for topup #${topup?.name}`;
                    } else if (original.action == "forex.topup.status.pending") {
                        actionStatement = `Status updated to pending by ${profileId?.name} for topup #${topup?.name}`;
                    } else if (original.action == "forex.ticket.created") {
                        actionStatement = `Ticket #${ticket?.name} is raised by ${clientId?.name}`;
                    } else if (original.action == "forex.ticket.status.pending") {
                        actionStatement = `Status updated to pending by ${profileId?.name} for ticket #${ticket?.name}`;
                    } else if (original.action == "forex.ticket.status.closed") {
                        actionStatement = `Status updated to closed by ${profileId?.name} for ticket #${ticket?.name}`;
                    }

                    return (
                        <Text>{actionStatement}</Text>
                    )
                }
            },
            {
                Header: 'Updated at',
                accessor: '',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => <Text>{moment(original.updatedDate).format("DD-MM-YYYY hh:mm A")}</Text>
            },
        ],
        []
    )

    return (
        <SidebarWithHeader>
            <Flex mb={"20px"}>
                <Heading as='h1' size='xl'>
                    User Logs
                </Heading>
                <Spacer />

            </Flex>
            <Flex flexDirection={'column'} flex={'1'} >
                {isLoading ?
                    <Spinner /> :
                    (
                        <AppTableWithPagination
                            columns={columns}
                            data={logs?.data}
                            searchTerm={searchValue}
                            paginationMeta={logs?.meta}
                            onPageChange={(index) => setCurrentPage(index)}
                            onSearchInputChange={(searchInput) => setsearchValue(searchInput)}
                            searchEnabled={true}
                        />
                    )}
            </Flex>
        </SidebarWithHeader>
    )
}

export default LogsForAdmin