const toCurrency = (amount, currency) => {
    const symbol = currency === 'INR' ? '₹' : ''
    let number = parseFloat(amount);
    return symbol + number.toLocaleString(currency);
}

function getExtension(filename) {
    return filename?.split('.').pop()
}

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function hasDecimal(number) {
    const numberAsString = number.toString();
    return numberAsString.includes('.');
}

function truncateString(str, maxLength) {
    if (str.length <= maxLength) {
        return str;
    } else {
        return str.slice(0, maxLength) + '...';
    }
}


export { toCurrency, getExtension, isJsonString, hasDecimal, truncateString };