import {
    Text, Link, Badge, Tab, TabPanel, TabPanels, Tabs, TabList, Box, Spinner, SimpleGrid, Stat, StatLabel,
    StatNumber, useColorModeValue,
    Heading,
    Divider,
    Flex,
    Button
} from '@chakra-ui/react';
import AppTable from 'components/AppTable';
import SidebarWithHeader from 'components/SidebarWithHeader';
import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useGetClientwiseDashboardTableCCQuery, useGetClientwiseDashboardTableCLQuery, useGetClientwiseDebitsCCQuery, useGetClientwiseDebitsCLQuery } from 'store/adAccounts/adAccountsApi';
import { useGetSingleClientQuery } from 'store/auth/authApi';
import { useGetTopupByAdAccountQuery, useGetOverallCCCreditsByClientQuery, useGetOverallCLCreditsByClientQuery, useGenerateCCReportByClientQuery, useGenerateCLReportByClientQuery } from 'store/topUp/topUpApi';

function ClientWiseDashboard() {

    const { id } = useParams();

    const [isCLReportQueried, setisCLReportQueried] = useState(false);
    const [isCCReportQueried, setisCCReportQueried] = useState(false);

    const {
        data: clientInfo,
        isLoading: isClientInfoLoading,
    } = useGetSingleClientQuery(id);

    //console.log({ clientInfo });

    const {
        data: CCAdsAccounts,
        isLoading: isCCAdsAccountsLoading,
        isFetching: isCCAdsAccountsFetching
    } = useGetClientwiseDashboardTableCCQuery({ id }, {
        //pollingInterval: 30000,
        //skipPollingIfUnfocused: true,
    });

    const {
        data: CLAdsAccounts,
        isLoading: isCLAdsAccountsLoading,
        isFetching: isCLAdsAccountsFetching
    } = useGetClientwiseDashboardTableCLQuery({ id }, {
        //pollingInterval: 30000,
        //skipPollingIfUnfocused: true,
    });

    const {
        data: overallCCCredits,
        isLoading: isOverallCCCreditsLoading,
    } = useGetOverallCCCreditsByClientQuery({ id });

    const {
        data: overallCCDebits,
        isLoading: isOverallCCDebitsLoading,
    } = useGetClientwiseDebitsCCQuery({ id })

    const {
        data: overallCLCredits,
        isLoading: isOverallCLCreditsLoading,
    } = useGetOverallCLCreditsByClientQuery({ id });

    const {
        data: overallCLDebits,
        isLoading: isOverallCLDebitsLoading,
    } = useGetClientwiseDebitsCLQuery({ id })

    const { data: ccReportCSV,
        isLoading: isCCReportCSVLoading
    } = useGenerateCCReportByClientQuery({ id }, {
        skip: !isCCReportQueried
    })

    const { data: clReportCSV,
        isLoading: isCLReportCSVLoading
    } = useGenerateCLReportByClientQuery({ id }, {
        skip: !isCLReportQueried
    })


    const columnsForCL = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'adsAccountNumber',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{'#' + original.adsAccountNumber}</Text>)
            },
            {
                Header: 'Ad Account ID',
                accessor: 'adsAccountId',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.adsAccountId}</Text>)
            },


            {
                Header: 'Top up',
                accessor: '',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => {
                    // console.log(original);
                    const { _id } = original;
                    const { data: topupData } = useGetTopupByAdAccountQuery(_id);
                    //console.log({ topupData });
                    return (
                        <>
                            {topupData?.data ? (
                                <Text fontSize={'14px'} textAlign={'right'}>{(Number(topupData?.data?.totalTopup))?.toFixed(2)}</Text>
                            ) : (
                                null
                            )}
                        </>
                    )
                }
            },
            {
                Header: 'Spend Cap',
                accessor: '',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'} textAlign={'right'}>{Number(original.adsAccountData?.spend_cap * 0.01)?.toFixed(2)}</Text>)
            },
            {
                Header: 'amount Spent',
                accessor: 'amountSpent',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'} textAlign={'right'}>{Number(original?.adsAccountData?.amountSpent * 0.01)?.toFixed(2)}</Text>)
            },
            {
                Header: 'Balance',
                accessor: '',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => {
                    // console.log(original);
                    const { _id } = original;
                    const { data: topupData } = useGetTopupByAdAccountQuery(_id);
                    // console.log({ topupData });
                    return (
                        <>
                            <Text fontSize={'14px'} textAlign={'right'}>{(Number(original?.adsAccountData?.spend_cap * 0.01) - Number(original?.adsAccountData?.amountSpent * 0.01))?.toFixed(2)}</Text>

                        </>
                    )
                }
            },
            {
                Header: 'Ad Account Name',
                accessor: 'adsAccountName',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Link href={`/dashboard/ads-accounts/${original._id}`} textDecoration={'underline'} _hover={{ color: '#ea7869' }}><Text fontSize={'14px'}>{original.adsAccountName}</Text></Link>)
            },
            {
                Header: 'Company Name',
                accessor: 'companyName',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.company?.name}</Text>)
            },
            {
                Header: 'currency',
                accessor: 'currency',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.currency}</Text>)
            },
            {
                Header: 'Account status',
                accessor: 'accountStatus',
                Filter: '',
                filter: '',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    // console.log(original);
                    return (
                        <>
                            <Badge
                                fontSize={"10px"}
                                marginRight={2}
                                colorScheme={original.accountStatus == 1 ? 'green' : 'gray'}
                            >
                                {original.accountStatus == 1 ? 'Active' : 'In active'}
                            </Badge>
                        </>
                    )
                }
            },
        ],
        []
    )

    const columnsForCC = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'adsAccountNumber',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{'#' + original.adsAccountNumber}</Text>)
            },
            {
                Header: 'Ad Account ID',
                accessor: 'adsAccountId',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.adsAccountId}</Text>)
            },


            {
                Header: 'Top up',
                accessor: '',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => {
                    //console.log(original);
                    const { _id } = original;
                    const { data: topupData } = useGetTopupByAdAccountQuery(_id);
                    //console.log({ topupData });
                    return (
                        <>
                            {topupData?.data ? (
                                <Text fontSize={'14px'} textAlign={'right'}>{(Number(topupData?.data?.totalTopup))?.toFixed(2)}</Text>
                            ) : (
                                null
                            )}
                        </>
                    )
                }
            },
            {
                Header: 'Spend Cap',
                accessor: '',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'} textAlign={'right'}>{Number(original.adsAccountData?.spend_cap * 0.01)?.toFixed(2)}</Text>)
            },
            {
                Header: 'amount Spent',
                accessor: 'amountSpent',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'} textAlign={'right'}>{Number(original?.adsAccountData?.amountSpent * 0.01)?.toFixed(2)}</Text>)
            },
            {
                Header: 'Balance',
                accessor: '',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => {
                    // console.log(original);
                    const { _id } = original;
                    const { data: topupData } = useGetTopupByAdAccountQuery(_id);
                    // console.log({ topupData });
                    return (
                        <>
                            <Text fontSize={'14px'} textAlign={'right'}>{(Number(original?.adsAccountData?.spend_cap * 0.01) - Number(original?.adsAccountData?.amountSpent * 0.01))?.toFixed(2)}</Text>

                        </>
                    )
                }
            },
            {
                Header: 'Ad Account Name',
                accessor: 'adsAccountName',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Link href={`/dashboard/ads-accounts/${original._id}`} textDecoration={'underline'} _hover={{ color: '#ea7869' }}><Text fontSize={'14px'}>{original.adsAccountName}</Text></Link>)
            },
            {
                Header: 'Company Name',
                accessor: 'companyName',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.company?.name}</Text>)
            },
            {
                Header: 'currency',
                accessor: 'currency',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.currency}</Text>)
            },
            {
                Header: 'Account status',
                accessor: 'accountStatus',
                Filter: '',
                filter: '',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    // console.log(original);
                    return (
                        <>
                            <Badge
                                fontSize={"10px"}
                                marginRight={2}
                                colorScheme={original.accountStatus == 1 ? 'green' : 'gray'}
                            >
                                {original.accountStatus == 1 ? 'Active' : 'In active'}
                            </Badge>
                        </>
                    )
                }
            },
        ],
        []
    )

    //console.log({ overallCLDebits });

    return (
        <SidebarWithHeader>
            <Box mb={'20px'}>
                <Heading as={'h4'} size='md'>
                    Client: {isClientInfoLoading ? 'Loading...' : clientInfo?.firstName + ' ' + clientInfo?.lastName} { }
                </Heading>
            </Box>
            <Tabs p={0} colorScheme='orange'>
                <TabList>
                    <Tab fontWeight={'bold'}>CL ACCOUNTS</Tab>
                    <Tab fontWeight={'bold'}>CC ACCOUNTS</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel px={0}>
                        {(isCLAdsAccountsLoading) ?
                            <Spinner mt={'10px'} />
                            : (
                                <Box mt={'10px'}>
                                    {CLAdsAccounts?.data?.length > 0 ? (
                                        <>
                                            <Flex justifyContent={'space-between'}>
                                                <Flex flexDir={'column'}>
                                                    <Flex >Total Commission:&nbsp; <Text fontWeight={'bold'}>{overallCLCredits?.totalCommission?.toFixed(2)}</Text> </Flex>
                                                    <Flex >Total Topup:&nbsp; <Text fontWeight={'bold'}>{overallCLCredits?.totalTopup?.toFixed(2)}</Text> </Flex>
                                                </Flex>
                                                <Flex>
                                                    {isCLReportCSVLoading ? (
                                                        <Spinner />
                                                    ) : (
                                                        <>
                                                            {clReportCSV ? (
                                                                <Link isExternal href={clReportCSV} download>
                                                                    <Button colorScheme='orange' size={'xs'}>
                                                                        Download
                                                                    </Button>
                                                                </Link>
                                                            ) : (
                                                                <Button colorScheme='orange' size='xs' onClick={() => setisCLReportQueried(true)}>
                                                                    Generate CSV
                                                                </Button>
                                                            )}
                                                        </>
                                                    )}

                                                </Flex>
                                            </Flex>
                                            <SimpleGrid columns={{ base: 1, md: 4 }} spacing={{ base: 5, lg: 8 }} mt={'20px'}>
                                                <StatsCard title={'Current Spend Cap'} stat={overallCLDebits?.data[0]?.spend_cap?.toFixed(2)} />
                                                <StatsCard title={'Current Spent'} stat={overallCLDebits?.data[0]?.amountSpent?.toFixed(2)} />

                                                {overallCLDebits?.data[0]?.spend_cap ? (
                                                    <StatsCard title={'Current Balance'} stat={Number(overallCLDebits?.data[0].spend_cap - overallCLDebits?.data[0]?.amountSpent)?.toFixed(2)} />
                                                ) : null}
                                            </SimpleGrid>
                                            <Box mt={'40px'}>
                                                <AppTable
                                                    columns={columnsForCL}
                                                    data={CLAdsAccounts?.data}
                                                    isFetching={isCLAdsAccountsFetching}
                                                    searchEnabled={false}
                                                />
                                            </Box>
                                        </>
                                    ) : (
                                        <Text>No account found</Text>
                                    )}

                                </Box>
                            )}

                    </TabPanel>
                    <TabPanel px={0}>
                        {(isCCAdsAccountsLoading) ?
                            <Spinner mt={'10px'} />
                            : (
                                <Box mt={'10px'}>
                                    {CCAdsAccounts?.data?.length > 0 ? (
                                        <>
                                            <Flex justifyContent={'space-between'}>
                                                <Flex flexDir={'column'}>
                                                    <Flex >Total Commission:&nbsp; <Text fontWeight={'bold'}>{overallCCCredits?.totalCommission?.toFixed(2)}</Text> </Flex>
                                                    <Flex >Total Topup:&nbsp; <Text fontWeight={'bold'}>{overallCCCredits?.totalTopup?.toFixed(2)}</Text> </Flex>
                                                </Flex>
                                                <Flex>
                                                    {isCCReportCSVLoading ? (
                                                        <Spinner />
                                                    ) : (
                                                        <>
                                                            {ccReportCSV ? (
                                                                <Link isExternal href={ccReportCSV} download>
                                                                    <Button colorScheme='orange' size={'xs'}>
                                                                        Download
                                                                    </Button>
                                                                </Link>
                                                            ) : (
                                                                <Button colorScheme='orange' size='xs' onClick={() => setisCCReportQueried(true)}>
                                                                    Generate CSV
                                                                </Button>
                                                            )}
                                                        </>
                                                    )}
                                                </Flex>
                                            </Flex>

                                            <SimpleGrid columns={{ base: 1, md: 4 }} spacing={{ base: 5, lg: 8 }} >
                                                {/* <StatsCard title={'Total Commission'} stat={overallCCCredits?.totalCommission?.toFixed(2)} />
                                                <StatsCard title={'Total Topup'} stat={(overallCCCredits?.totalTopup + 0)?.toFixed(2)} /> */}
                                                <StatsCard title={'Current Spend Cap'} stat={overallCCDebits?.data[0]?.spend_cap?.toFixed(2)} />
                                                <StatsCard title={'Current Spent'} stat={overallCCDebits?.data[0]?.amountSpent?.toFixed(2)} />

                                                {overallCCCredits?.data[0]?.spend_cap ? (
                                                    <StatsCard title={'Current Balance'} stat={Number(overallCCCredits?.data[0].spend_cap - overallCCCredits?.data[0]?.amountSpent)?.toFixed(2)} />
                                                ) : null}
                                            </SimpleGrid>
                                            <Box mt={'40px'}>
                                                <AppTable
                                                    columns={columnsForCC}
                                                    data={CCAdsAccounts?.data}
                                                    isFetching={isCCAdsAccountsFetching}
                                                    searchEnabled={false}
                                                />
                                            </Box>
                                        </>
                                    ) : (
                                        <Text>No account found</Text>
                                    )}

                                </Box>
                            )}

                    </TabPanel>

                </TabPanels>
            </Tabs>
        </SidebarWithHeader>
    )
}


function StatsCard(props) {
    const { title, stat } = props
    return (
        <Stat
            px={{ base: 4, md: 8 }}
            py={'5'}
            //shadow={'md'}
            border={'1px solid'}
            borderColor={useColorModeValue('gray.800', 'gray.500')}
            rounded={'lg'}>
            <StatLabel fontWeight={'medium'} isTruncated>
                {title}
            </StatLabel>
            <StatNumber fontSize={'xl'} fontWeight={'bold'}>
                {stat}
            </StatNumber>
        </Stat>
    )
}

export default ClientWiseDashboard