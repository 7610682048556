import {
  FormControl,
  Grid,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import React from "react";
import DocumentApprovalCard from "../DocumentApprovalCard";
import FieldApprovalCard from "../FieldApprovalCard";

function LegalDetailsVerificationForm({ entity, details, isLoading, canVerify }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    panNumber,
    gstNumber,
    registrationCertificate,
    gstCertificate,
    panCard,
    cancelledCheque,
    msmeCertificate,
    taxId,
    additionalDocument,
    bankDeclaration,
    company,
    formTenF,
    noPeCertificate,
    taxResidencyCertificate,
    bussinessTradeLicense,
    selfDeclaration,
    vatCertificate,
    bankDetails,
    itDeclaration,
  } = details || {};

  //console.log(details && bankDeclaration);


  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={0}>
            <Image w={"full"} src='https://fastly.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI' />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* <Button onClick={onOpen}>Open Modal</Button> */}

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {details && Object.keys(details).length > 0 ? (
            <>
              {entity == "indian" ? (
                <FormControl>
                  <Grid gridTemplateColumns={"repeat( auto-fill, minmax(320px, 1fr) )"} gap={"20px"} >

                    <FieldApprovalCard
                      canVerify={canVerify}
                      for_={'legal'}
                      fieldData={panNumber}
                      title='PAN Number'
                    />

                    <FieldApprovalCard
                      canVerify={canVerify}
                      for_={'legal'}
                      fieldData={gstNumber}
                      title='GST Number'
                    />

                    <DocumentApprovalCard
                      canVerify={canVerify}
                      for_={'legal'}
                      fieldData={gstCertificate}
                      title='GST Certificate'
                    />

                    <DocumentApprovalCard
                      canVerify={canVerify}
                      for_={'legal'}
                      fieldData={panCard}
                      title='PAN Card'
                    />

                    {cancelledCheque?.value ? (
                      <DocumentApprovalCard
                        canVerify={canVerify}
                        for_={'legal'}
                        fieldData={cancelledCheque}
                        title='Cancelled Cheque'
                      />
                    ) : null}

                    {bankDetails?.value ? (
                      <DocumentApprovalCard
                        canVerify={canVerify}
                        for_={'legal'}
                        fieldData={bankDetails}
                        title='Bank Details'
                      />
                    ) : null}

                    {msmeCertificate?.value ? (
                      <DocumentApprovalCard
                        canVerify={canVerify}
                        for_={'legal'}
                        fieldData={msmeCertificate}
                        title='MSME Certificate'
                      />
                    ) : null}

                    {registrationCertificate?.value ? (
                      <DocumentApprovalCard
                        canVerify={canVerify}
                        for_={'legal'}
                        fieldData={registrationCertificate}
                        title='Registration Certificate'
                      />
                    ) : null}

                    {additionalDocument?.value ? (
                      <DocumentApprovalCard
                        canVerify={canVerify}
                        for_={'legal'}
                        fieldData={additionalDocument}
                        title='Additional Documents'
                      />
                    ) : null}

                    {itDeclaration?.value ? (
                      <DocumentApprovalCard
                        canVerify={canVerify}
                        for_={'legal'}
                        fieldData={itDeclaration}
                        title='IT Declaration'
                      />
                    ) : null}

                  </Grid>
                </FormControl>
              ) : (
                <FormControl>
                  <Grid gridTemplateColumns={"repeat( auto-fill, minmax(320px, 1fr) )"} gap={"20px"} >

                    <DocumentApprovalCard
                      canVerify={canVerify}
                      for_={'legal'}
                      fieldData={registrationCertificate}
                      title='Registration Certificate'
                    />

                    <FieldApprovalCard
                      canVerify={canVerify}
                      for_={'legal'}
                      fieldData={taxId}
                      title='Tax ID'
                    />
                    {taxResidencyCertificate?.value ? (
                      <DocumentApprovalCard
                        canVerify={canVerify}
                        for_={'legal'}
                        fieldData={taxResidencyCertificate}
                        title='Tax Residency Certificate'
                      />
                    ) : null}

                    {formTenF?.value ? (
                      <DocumentApprovalCard
                        canVerify={canVerify}
                        for_={'legal'}
                        fieldData={formTenF}
                        title='Form 10F'
                      />
                    ) : null}

                    {noPeCertificate?.value ? (
                      <DocumentApprovalCard
                        canVerify={canVerify}
                        for_={'legal'}
                        fieldData={noPeCertificate}
                        title='No PE Certificate'
                      />
                    ) : null}

                    {bankDeclaration?.value ? (
                      <DocumentApprovalCard
                        canVerify={canVerify}
                        for_={'legal'}
                        fieldData={bankDeclaration}
                        title='Bank Declaration'
                      />
                    ) : null}


                    {additionalDocument?.value ? (
                      <DocumentApprovalCard
                        canVerify={canVerify}
                        for_={'legal'}
                        fieldData={additionalDocument}
                        title='Additional Documents'
                      />
                    ) : null}


                  </Grid>
                </FormControl>
              )}
            </>
          ) : (
            <Text>No data available</Text>
          )}
        </>
      )}
    </>
  );
}

export default LegalDetailsVerificationForm;
