import { Box, Heading, Text, Link, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

function PrivacyPolicy() {
    return (
        <Box maxW={'960px'} mx={'auto'} pt={'100px'} pb={'80px'} px={'20px'} >

            <Heading>Privacy Policy</Heading>
            <br />
            <Text fontSize={20} fontWeight={'bold'} mb={'10px'}>Who are we?</Text>
            <Text>Midas by Blink Digital Platform and this website are managed by&nbsp;<Link color={useColorModeValue('gray.600', 'gray.400')} href="https://blinkdigital.in/" isExternal>Blink Digital</Link>.</Text>
            <br />
            <Text fontSize={20} fontWeight={'bold'} mb={'10px'}>About this Privacy Policy</Text>
            <Text>This is our main Privacy Policy which applies to all users of Midas by Blink Digital Services and visitors of this website and explains how we collect, process, or otherwise use personal data and information about them.</Text>
            <Text>Midas by Blink Digital reviews this Privacy Policy regularly and may revise it, in whole or in part, at any time to adapt to changes in legal requirements or the manner in which we process data. Such changes will be published on our website and will take effect immediately.</Text>
            <br />
            <Text fontSize={20} fontWeight={'bold'} mb={'10px'}>How and what data do we collect and process?</Text>
            <Text>When you start using the Services or visit our website, Midas by Blink Digital may collect some basic identifiers such as your first name, last name, telephone number, e-mail address, company name, or IP address. When you acquire the Services, additional information, such as billing information, business address, and other data necessary for the Services (e.g., usage and browser data, the web browser or operating system utilized, user name, and passwords), may be additionally requested from you;, however, we always aim to minimize the data collected to the extent practicable.</Text>
            <br />
            <Text fontSize={20} fontWeight={'bold'} mb={'10px'}>Purposes of processing activities</Text>
            <Text>Depending on the services used, Midas by Blink Digital may process the data for the following purposes: providing the Services, including technical support; this website; identification and authentication; billing; product and operational improvements; monitoring of the quality of the Services; introducing new features; market research and business development; as well as marketing and advertising, subject to the conditions set out below.</Text>
            <br />
            <Text fontSize={20} fontWeight={'bold'} mb={'10px'}>Legitimate interest</Text>
            <Text>We base our processing activities either on our legitimate interest to provide services that are technically functional and user-friendly, or as a part of the contract for the provision of Midas by Blink Digital Services. In certain cases, we may process your data to comply with legal or contractual obligations.</Text>
            <br />
            <Text fontSize={20} fontWeight={'bold'} >Third-parties</Text>
            <Text>Personal data may also be collected while connecting to Third-Party Platforms, such as online social networks, through the Services or via URL links to third-party websites. Midas by Blink Digital is not responsible for the manner in which third parties handle their processing activities. We encourage you to refer to the applicable privacy provisions of such Third-Party Platforms for more information.</Text>
            <br />
            <Text fontSize={20} fontWeight={'bold'} mb={'10px'}>Communication received from Midas by Blink Digital</Text>
            <Text>Based on your consent, Midas by Blink Digital may use personal data to discuss the Services or to send communications concerning Midas by Blink Digital and/or the Services and/or for promotional activities via newsletters, messages, calls, and letters to the e-mail address, telephone number, or postal address provided. Except as mentioned in this Privacy Policy, Midas by Blink Digital does not use collected personal data for any other kind of marketing or promotional purposes.</Text>
            <br />
            <Text fontSize={20} fontWeight={'bold'} mb={'10px'}>Right of objection</Text>
            <Text>At any time, you may opt out of being contacted by Midas by Blink Digital either by using the opt-out link provided in any newsletter or by contacting Midas by Blink Digital at the following e-mail address: midas.media@blinkdigital.in and your data will be removed from our database without undue delay, in accordance with our internal procedures.</Text>
            <br />
            <Text fontSize={20} fontWeight={'bold'} mb={'10px'}>Data retention</Text>
            <Text>Unless earlier requested by you, we will delete your personal data as soon as it's no longer necessary for the purpose for which it was collected, except as required by law. In such an event, the data will be blocked to prohibit any other use.</Text>
            <br />
            <Text fontSize={20} fontWeight={'bold'} mb={'10px'}>We respect your privacy</Text>
            <Text>Subject to applicable data protection laws or regulations, you may be entitled to the following rights: the right to request access to your data; the right to request that your data is updated or rectified; the right to request that we refrain from further use of your personal data; and the right to request that your personal data is deleted.</Text>
            <Text>All requests will be handled in a timely manner, but we may ask for additional information in order to confirm your identity.</Text>
            <Text>We may not always be able to immediately remove all of your personal data, for example, if there is a legal requirement to keep such data (e.g., for compliance or legal retention purposes).</Text>
        </Box>
    )
}

export default PrivacyPolicy