import { api } from "../api";

export const uploadDocumentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    uploadLegalFile: builder.mutation({
      query: ({ companyId, payload }) => ({
        url: `/companies/legal/${companyId}/upload`,
        method: "POST",
        body: payload,
      }),
      transformResponse: (response, meta, arg) => response?.data[0],
      invalidatesTags: ["Company", "Companies"],
    }),
    removeFile: builder.mutation({
      query: ({ companyId, payload }) => ({
        url: `/companies/legal/${companyId}/remove`,
        method: 'DELETE',
        body: payload,
      }),
      invalidatesTags: ["Company", "Companies"],
    }),
  }),
});

export const { useUploadLegalFileMutation, useRemoveFileMutation } = uploadDocumentApi;
