import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Link,
    Radio,
    RadioGroup,
    Select,
    Spacer,
    Stack,
    Text,
    Textarea,
    Tooltip
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { InfoOutlineIcon } from "@chakra-ui/icons";

import { useFormik } from "formik";
import { newCompanySchema } from "utils/schema";


function GeneralDetailsForm({ details, onGeneralDetailsFormSubmit }) {

    // //console.log(details);

    const { _id, companyName, nickName, companyType, registerAddress, correspondenceAddress, entity, typeOfBussiness, otherTypeOfBussiness, otherCompanyType, websiteUrl, serviceDescription, representativeName, representativePhone, representativeEmail, representativeDesignation, ownerName, ownerPhone } = details || {}

    const [initialFields, setinitialFields] = useState({})

    useEffect(() => {
        setinitialFields({
            companyName: companyName || "",
            nickName: nickName || "",
            typeOfBussiness: typeOfBussiness || "",
            companyType: companyType || "",
            registerAddress: registerAddress || "",
            correspondenceAddress: correspondenceAddress || "",
            entity: entity || "indian",
            otherTypeOfBussiness: "",
            otherCompanyType: "",
            websiteUrl: websiteUrl || "",
            serviceDescription: serviceDescription || "",
            representativeName: representativeName || "",
            representativePhone: representativePhone || "",
            representativeEmail: representativeEmail || "",
            representativeDesignation: representativeDesignation || "",
            ownerName: ownerName || "",
            ownerPhone: ownerPhone || "",
            isOwnerSame: representativeName == ownerName,
        })
    }, [details])

    const formik = useFormik({
        initialValues: initialFields,
        validationSchema: newCompanySchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            // //console.log(values);
            if (values) {
                const {
                    companyName,
                    nickName,
                    typeOfBussiness,
                    registerAddress,
                    entity,
                    otherTypeOfBussiness,
                    companyType,
                    otherCompanyType,
                    websiteUrl,
                    serviceDescription,
                    representativeName,
                    representativePhone,
                    representativeEmail,
                    representativeDesignation,
                    ownerName,
                    ownerPhone,
                    isOwnerSame,
                    correspondenceAddress,
                } = values;

                const updatedTypeOfBussiness = typeOfBussiness == "Other" ? otherTypeOfBussiness : typeOfBussiness;
                const updatedcompanyType = companyType == "Other" ? otherCompanyType : companyType;
                const updatedOwnerName = isOwnerSame ? representativeName : ownerName;
                const updatedOwnerPhone = isOwnerSame ? representativePhone : ownerPhone;

                const payload = {
                    companyName,
                    nickName,
                    companyType: updatedcompanyType,
                    registerAddress,
                    correspondenceAddress,
                    entity,
                    typeOfBussiness: updatedTypeOfBussiness,
                    websiteUrl,
                    serviceDescription,
                    representativeName,
                    representativePhone,
                    representativeEmail,
                    representativeDesignation,
                    ownerName: updatedOwnerName,
                    ownerPhone: updatedOwnerPhone,
                }

                //console.log(values);
                onGeneralDetailsFormSubmit(payload)
            }
        },
    });

    return (
        <FormControl style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            <Flex flex={1}>
                <RadioGroup onChange={(value) => formik.setFieldValue("entity", value)} value={formik.values.entity}>
                    <Stack spacing={5} direction='row' justify={"center"}>
                        <Radio colorScheme='orange' value='indian'>
                            Indian Entity
                        </Radio>
                        <Radio colorScheme='orange' value='non-indian'>
                            Non-Indian Entity
                        </Radio>
                    </Stack>
                </RadioGroup>
                {formik.touched.entity && formik.errors.entity && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {formik.errors.entity}
                    </Text>
                )}
            </Flex>
            <Flex gap={'20px'}>
                <Box flex={1}>
                    <FormLabel fontSize={"sm"} mb='10px'> Company Legal Name</FormLabel>
                    <Input
                        name='companyName'
                        placeholder='Company Legal Name'
                        onChange={formik.handleChange}
                        value={formik.values.companyName}
                        isInvalid={formik.touched.companyName && formik.errors.companyName ? true : false}
                    />
                    {formik.touched.companyName && formik.errors.companyName && (
                        <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                            {formik.errors.companyName}
                        </Text>
                    )}
                </Box>
                <Box flex={1}>
                    <FormLabel fontSize={"sm"} mb='10px'>Nick Name &nbsp;
                        <Tooltip
                            borderRadius={5}
                            label={
                                <Box fontSize={"sm"} p={"5px"}>
                                    <Text>Please add the Whatsapp/Telegram/Signal group name</Text>
                                </Box>
                            }
                            fontSize='md'
                        >
                            <InfoOutlineIcon pointerEvents={'all'} />
                        </Tooltip>
                    </FormLabel>
                    <Input
                        name='nickName'
                        placeholder='Nick Name'
                        onChange={formik.handleChange}
                        value={formik.values.nickName}
                        isInvalid={formik.touched.nickName && formik.errors.nickName ? true : false}
                    />
                    {formik.touched.nickName && formik.errors.nickName && (
                        <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                            {formik.errors.nickName}
                        </Text>
                    )}
                </Box>
            </Flex>
            <Box flex={1}>
                <FormLabel fontSize={"sm"} mb='10px'>Legal Structure</FormLabel>
                <Select
                    flex={1}
                    name='companyType'
                    placeholder='Legal Structure'
                    onChange={formik.handleChange}
                    value={formik.values.companyType}
                    isInvalid={formik.touched.companyType && formik.errors.companyType ? true : false}
                >
                    <option value='Sole Proprietor'>Sole Proprietor</option>
                    <option value='Partnership'>Partnership</option>
                    <option value='Corporation'>Corporation</option>
                    <option value='Joint Venture'>Joint Venture</option>
                    <option value='Franchise'>Franchise</option>
                    <option value='Non-profit'>Non-profit</option>
                    <option value='Other'>Other (please specify)</option>
                </Select>
                {formik.touched.companyType && formik.errors.companyType && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {formik.errors.companyType}
                    </Text>
                )}
                {formik.values.companyType == "Other" ? (
                    <>
                        <Spacer mt={"20px"} />
                        <Textarea
                            name='otherCompanyType'
                            placeholder='Specify Other Legal Structure'
                            onChange={formik.handleChange}
                            value={formik.values.otherCompanyType}
                            isInvalid={formik.touched.otherCompanyType && formik.errors.otherCompanyType ? true : false}
                            minLength={10}
                        />
                        {formik.touched.otherCompanyType && formik.errors.otherCompanyType && (
                            <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                {formik.errors.otherCompanyType}
                            </Text>
                        )}
                    </>
                ) : null}

                {/* {formik.values.companyType == "Sole Proprietor" ? (
                    <>
                        <Spacer mt={"10px"} />
                        <Text fontSize={"sm"} color={'red'}>
                            If you want to register as an individual entity, please contact us by clicking{" "}
                            <Link textDecoration={"underline"} href='mailto:accounts@blinkdigital.in' isExternal>
                                here
                            </Link>
                        </Text>
                    </>
                ) : null} */}
            </Box>
            <Box flex={1}>
                <FormLabel fontSize={"sm"} mb='10px'>Type of Business</FormLabel>
                <Select
                    flex={1}
                    name='typeOfBussiness'
                    placeholder='Type of Business'
                    onChange={formik.handleChange}
                    value={formik.values.typeOfBussiness}
                    isInvalid={formik.touched.typeOfBussiness && formik.errors.typeOfBussiness ? true : false}
                >
                    <option value='Distributor/Dealer'>Distributor/Dealer</option>
                    <option value='Publication/Broadcaster'>Publication/Broadcaster</option>
                    <option value='Professional Service'>Professional Service</option>
                    <option value='Service Provider'>Service Provider</option>
                    <option value='Manufacturer'>Manufacturer</option>
                    <option value='Consultant'>Consultant</option>
                    <option value='Freight/Transporation'>Freight/Transporation</option>
                    <option value='Wholesaler'>Wholesaler</option>
                    <option value='Other'>Other (please specify)</option>
                </Select>
                {formik.touched.typeOfBussiness && formik.errors.typeOfBussiness && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {formik.errors.typeOfBussiness}
                    </Text>
                )}
                {formik.values.typeOfBussiness == "Other" ? (
                    <>
                        <Spacer mt={"20px"} />
                        <Textarea
                            name='otherTypeOfBussiness'
                            placeholder='Specify Other Business Type'
                            onChange={formik.handleChange}
                            value={formik.values.otherTypeOfBussiness}
                            isInvalid={formik.touched.otherTypeOfBussiness && formik.errors.otherTypeOfBussiness ? true : false}
                            minLength={10}
                        />
                        {formik.touched.otherTypeOfBussiness && formik.errors.otherTypeOfBussiness && (
                            <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                {formik.errors.otherTypeOfBussiness}
                            </Text>
                        )}
                    </>
                ) : null}
            </Box>
            <Box flex={1}>
                <FormLabel fontSize={"sm"} mb='10px'>Website URL</FormLabel>
                <Input
                    name='websiteUrl'
                    placeholder='Website URL'
                    onChange={formik.handleChange}
                    value={formik.values.websiteUrl}
                    isInvalid={formik.touched.websiteUrl && formik.errors.websiteUrl ? true : false}
                    minLength={10}
                />
                {formik.touched.websiteUrl && formik.errors.websiteUrl && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {formik.errors.websiteUrl}
                    </Text>
                )}
            </Box>

            <Flex gap={"20px"}>
                <Box flex={1}>
                    <FormLabel fontSize={"sm"} mb='10px'>Registered Office Address</FormLabel>

                    <Textarea
                        name='registerAddress'
                        placeholder='Registered Office Address'
                        onChange={formik.handleChange}
                        value={formik.values.registerAddress}
                        isInvalid={formik.touched.registerAddress && formik.errors.registerAddress ? true : false}
                        minLength={10}
                    />
                    {formik.touched.registerAddress && formik.errors.registerAddress && (
                        <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                            {formik.errors.registerAddress}
                        </Text>
                    )}
                </Box>

                <Box flex={1}>
                    <FormLabel fontSize={"sm"} mb='10px'>Correspondence Address</FormLabel>
                    <Textarea
                        name='correspondenceAddress'
                        placeholder='Correspondence Address'
                        onChange={formik.handleChange}
                        value={formik.values.correspondenceAddress}
                        isInvalid={formik.touched.correspondenceAddress && formik.errors.correspondenceAddress ? true : false}
                        minLength={10}
                    />
                    {formik.touched.correspondenceAddress && formik.errors.correspondenceAddress && (
                        <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                            {formik.errors.correspondenceAddress}
                        </Text>
                    )}
                </Box>
            </Flex>

            <Box flex={1}>
                <FormLabel fontSize={"sm"} mb='10px'>Details on Services/Goods</FormLabel>
                <Textarea
                    name='serviceDescription'
                    placeholder='Details on Services/Goods your company provides'
                    onChange={formik.handleChange}
                    value={formik.values.serviceDescription}
                    isInvalid={formik.touched.serviceDescription && formik.errors.serviceDescription ? true : false}
                    minLength={10}
                />
                {formik.touched.serviceDescription && formik.errors.serviceDescription && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {formik.errors.serviceDescription}
                    </Text>
                )}
            </Box>
            <Flex gap={"20px"}>
                <Box flex={1}>
                    <FormLabel fontSize={"sm"} mb='10px'>Representative Name</FormLabel>

                    <Input
                        name='representativeName'
                        placeholder='Representative Name'
                        onChange={formik.handleChange}
                        value={formik.values.representativeName}
                        isInvalid={formik.touched.representativeName && formik.errors.representativeName ? true : false}
                        minLength={10}
                    />
                    {formik.touched.representativeName && formik.errors.representativeName && (
                        <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                            {formik.errors.representativeName}
                        </Text>
                    )}
                </Box>
                <Box flex={1}>
                    <FormLabel fontSize={"sm"} mb='10px'>Representative Phone Number</FormLabel>

                    <Input
                        name='representativePhone'
                        placeholder='Representative Phone Number'
                        onChange={formik.handleChange}
                        value={formik.values.representativePhone}
                        isInvalid={formik.touched.representativePhone && formik.errors.representativePhone ? true : false}
                        minLength={10}
                    />
                    {formik.touched.representativePhone && formik.errors.representativePhone && (
                        <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                            {formik.errors.representativePhone}
                        </Text>
                    )}
                </Box>
            </Flex>
            <Flex gap={"20px"}>
                <Box flex={1}>
                    <FormLabel fontSize={"sm"} mb='10px'>Representative Email ID</FormLabel>
                    <Input
                        name='representativeEmail'
                        placeholder='Representative Email ID'
                        onChange={formik.handleChange}
                        value={formik.values.representativeEmail}
                        isInvalid={formik.touched.representativeEmail && formik.errors.representativeEmail ? true : false}
                        minLength={10}
                    />
                    {formik.touched.representativeEmail && formik.errors.representativeEmail && (
                        <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                            {formik.errors.representativeEmail}
                        </Text>
                    )}
                </Box>
                <Box flex={1}>
                    <FormLabel fontSize={"sm"} mb='10px'>Representative Designation</FormLabel>
                    <Input
                        name='representativeDesignation'
                        placeholder='Representative Designation'
                        onChange={formik.handleChange}
                        value={formik.values.representativeDesignation}
                        isInvalid={formik.touched.representativeDesignation && formik.errors.representativeDesignation ? true : false}
                        minLength={10}
                    />
                    {formik.touched.representativeDesignation && formik.errors.representativeDesignation && (
                        <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                            {formik.errors.representativeDesignation}
                        </Text>
                    )}
                </Box>
            </Flex>
            <Box flex={1}>
                <FormLabel fontSize={"sm"} mb='10px'>
                    Authorised Signatory Details
                </FormLabel>
                <Checkbox colorScheme={"orange"} isChecked={formik.values.isOwnerSame} onChange={(e) => formik.setFieldValue("isOwnerSame", e.target.checked)}>
                    Same as Representative's
                </Checkbox>
            </Box>

            {!formik.values.isOwnerSame ? (
                <Flex gap={"20px"}>
                    <Box flex={1}>
                        <FormLabel fontSize={"sm"} mb='10px'>Authorised Signatory Name</FormLabel>
                        <Input
                            name='ownerName'
                            placeholder='Authorised Signatory Name'
                            onChange={formik.handleChange}
                            value={formik.values.ownerName}
                            isInvalid={formik.touched.ownerName && formik.errors.ownerName ? true : false}
                            minLength={10}
                        />
                        {formik.touched.ownerName && formik.errors.ownerName && (
                            <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                {formik.errors.ownerName}
                            </Text>
                        )}
                    </Box>
                    <Box flex={1}>
                        <FormLabel fontSize={"sm"} mb='10px'>Authorised Signatory Phone</FormLabel>
                        <Input
                            name='ownerPhone'
                            placeholder='Authorised Signatory Phone'
                            onChange={formik.handleChange}
                            value={formik.values.ownerPhone}
                            isInvalid={formik.touched.ownerPhone && formik.errors.ownerPhone ? true : false}
                            minLength={10}
                        />
                        {formik.touched.ownerPhone && formik.errors.ownerPhone && (
                            <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                {formik.errors.ownerPhone}
                            </Text>
                        )}
                    </Box>
                </Flex>
            ) : null}

            <Button colorScheme="orange" mt={4} mb={4} onClick={formik.handleSubmit}>
                Submit
            </Button>
        </FormControl>
    )
}

export default GeneralDetailsForm