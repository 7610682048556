import { AddIcon, DeleteIcon, EditIcon, InfoOutlineIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Spacer,
  Spinner,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import SidebarWithHeader from "components/SidebarWithHeader";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAddNewCompanyMutation, useGetCompaniesQuery, useRemoveCompanyMutation } from "store/companies/companiesApi";
import { useVerifyCompanyMutation } from "store/companies/verifyCompanyApi";
import { newCompanySchema } from "utils/schema";
import { isBankFormFulfilled, isLegalFormFulfilled } from "utils/verifyFormDetails";

function CompaniesForClient() {

  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useDisclosure();
  const boxBorderColor = useColorModeValue("gray.400", "gray.200");
  const editIconColor = useColorModeValue("gray.600", "gray.400");
  const deleteIconColor = useColorModeValue("red.600", "red.400");
  const editIconHoverColor = useColorModeValue("gray.500", "gray.300");
  const deleteIconHoverColor = useColorModeValue("red.500", "red.300");
  const addBoxBorder = useColorModeValue("gray.400", "gray.200");
  const addBoxHoverBackground = useColorModeValue("gray.50", "gray.700");
  const [accountToDelete, setaccountToDelete] = useState(null);
  const isOnboarded = useSelector((state) => state.auth?.isUserOnBoarded);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setsearchValue] = useState('');

  /* QUERIES */

  const { data: companies, isLoading: isCompaniesDataLoading, isFetching, refetch } = useGetCompaniesQuery({ currentPage, searchValue }, {
    pollingInterval: 300000,
    skipPollingIfUnfocused: true,
  });

  const [addNewCompany, result] = useAddNewCompanyMutation();
  const [removeCompany] = useRemoveCompanyMutation();
  const [verifyCompany, verifyResponse] = useVerifyCompanyMutation();

  //console.log(companies && companies);
  //companies && (isLegalFormFulfilled(companies[0].entity, companies[0].bank))

  useEffect(() => {
    refetch();
  }, [refetch])


  useEffect(() => {
    //console.log(result);
    if (verifyResponse.status == "rejected") {
      const {
        data: { message },
      } = verifyResponse.error;
      toast({
        position: "top-right",
        title: "Error",
        //description: message,
        status: "error",
        duration: 8000,
        isClosable: true,
      });
    }
    if (verifyResponse?.data) {
      toast({
        position: "top-right",
        title: "Sent for verification",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [verifyResponse]);

  useEffect(() => {
    //console.log(result);
    if (result.status == "rejected") {
      const {
        data: { message },
      } = result.error;
      toast({
        position: "top-right",
        title: "Error",
        description: message,
        status: "error",
        duration: 8000,
        isClosable: true,
      });
    }
    if (result?.data) {
      const { companyName } = result.data;
      toast({
        position: "top-right",
        title: "Added New Company",
        description: companyName,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [result]);

  const formik = useFormik({
    initialValues: {
      nickName: "",
      companyName: "",
      typeOfBussiness: "",
      companyType: "",
      registerAddress: "",
      correspondenceAddress: "",
      entity: "indian",
      typeOfBussiness: "",
      otherTypeOfBussiness: "",
      othercompanyType: "",
      websiteUrl: "",
      serviceDescription: "",
      representativeName: "",
      representativePhone: "",
      representativeEmail: "",
      representativeDesignation: "",
      ownerName: "",
      ownerPhone: "",
      isOwnerSame: true,
    },
    validationSchema: newCompanySchema,
    onSubmit: async (values) => {
      // alert(JSON.stringify(values, null, 2));
      //console.log(values);
      if (values) {
        const {
          nickName,
          companyName,
          typeOfBussiness,
          registerAddress,
          entity,
          otherTypeOfBussiness,
          companyType,
          othercompanyType,
          websiteUrl,
          serviceDescription,
          representativeName,
          representativePhone,
          representativeEmail,
          representativeDesignation,
          ownerName,
          ownerPhone,
          isOwnerSame,
          correspondenceAddress,
        } = values;

        const updatedTypeOfBussiness = typeOfBussiness == "Other" ? otherTypeOfBussiness : typeOfBussiness;
        const updatedcompanyType = companyType == "Other" ? othercompanyType : companyType;
        const updatedOwnerName = isOwnerSame ? representativeName : ownerName;
        const updatedOwnerPhone = isOwnerSame ? representativePhone : ownerPhone;

        // //console.log(values);
        await addNewCompany({
          companyName,
          nickName,
          companyType: updatedcompanyType,
          registerAddress,
          correspondenceAddress,
          entity,
          typeOfBussiness: updatedTypeOfBussiness,
          websiteUrl,
          serviceDescription,
          representativeName,
          representativePhone,
          representativeEmail,
          representativeDesignation,
          ownerName: updatedOwnerName,
          ownerPhone: updatedOwnerPhone,
        });
      }
      formik.resetForm();
      onClose();
    },
  });

  const handleOnDeleteCancel = () => {
    onDeleteModalClose();
    setaccountToDelete(null);
  };

  const handleOnDeleteConfirm = async () => {
    onDeleteModalClose();
    await removeCompany(accountToDelete);
  };

  const companyList = companies?.data?.map(({ companyName, typeOfBussiness, registerAddress, _id, entity, legal, bank, verify, status }, index) => {
    const isLegalFormDone = isLegalFormFulfilled(entity, legal);
    const isBankFormDone = isBankFormFulfilled(entity, bank);

    //console.log({ bank });

    return (
      (
        <Flex
          borderWidth={1}
          borderColor={boxBorderColor}
          borderRadius={5}
          p={"20px"}
          w='100%'
          minH={"200px"}
          justifyContent={"space-between"}
          flexDirection={"column"}
          key={companyName + index}
          pos={'relative'}
        >
          <Box >
            <Heading as='h3' size={"md"} mb={4} mt={'5px'}>
              {companyName}
            </Heading>
            <Text fontSize={'16px'} >{typeOfBussiness}</Text>
            <Text fontSize={'14px'} color={'gray.600'}>{registerAddress}</Text>
            <Flex wrap={"wrap"} gap={"10px"} mt={3}>
              <Badge>{entity}</Badge>
              <Flex gap={"5px"}>
                <Badge fontSize={'12px'} colorScheme={!isLegalFormDone ? "orange" : "green"}>KYC Details</Badge>
                <Badge fontSize={'12px'} colorScheme={bank?.length > 0 ? "green" : "orange"}>Bank Details</Badge>
              </Flex>
            </Flex>
          </Box>

          {status ? (
            <Badge fontSize='10px' alignItems={'center'} pos={'absolute'} top={'5px'} right={'5px'} colorScheme={'green'}>
              verified</Badge>
          ) : (
            <>
              {verify ? (
                <Box>
                  <Badge alignItems={'center'} pos={'absolute'} top={'5px'} right={'5px'} fontSize={'10px'} colorScheme={'yellow'}>Under verification</Badge>
                </Box>
              ) : null}
            </>
          )}



          <Flex gap={"20px"} mt={"20px"}>
            {Object.keys(legal).length > 0 ? (
              <Tooltip label='View' fontSize='xs' >
                <ViewIcon transition={"0.2s ease"} color={editIconColor} _hover={{ cursor: "pointer", color: editIconHoverColor }} onClick={() => navigate(`/companies/${_id}`)} />
              </Tooltip>
            ) : null}
            <Tooltip label='Edit' fontSize='xs' >
              <EditIcon transition={"0.2s ease"} color={editIconColor} _hover={{ cursor: "pointer", color: editIconHoverColor }} onClick={() => navigate(`/companies/${_id}/edit`)} />
            </Tooltip>
            {!status && !verify ?
              <>
                {/* 
                <Tooltip label='Edit' fontSize='xs' >
                  <EditIcon transition={"0.2s ease"} color={editIconColor} _hover={{ cursor: "pointer", color: editIconHoverColor }} onClick={() => navigate(`/companies/${_id}/edit`)} />
                </Tooltip>
                <DeleteIcon
                  transition={"0.2s ease"}
                  color={deleteIconColor}
                  _hover={{ cursor: "pointer", color: deleteIconHoverColor }}
                  onClick={() => {
                    onDeleteModalOpen();
                    setaccountToDelete(_id);
                  }}
                /> */}
              </>
              : null
            }


          </Flex>
        </Flex>
      )
    )
  });

  return (
    <>
      <Modal
        size={"2xl"}
        isCentered={true}
        isOpen={isOpen}
        scrollBehavior={"inside"}
        onClose={() => {
          onClose();
          formik.resetForm();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New Company</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl style={{ display: "flex", flexDirection: "column", gap: 20 }}>
              <Flex flex={1}>
                <RadioGroup onChange={(value) => formik.setFieldValue("entity", value)} value={formik.values.entity}>
                  <Stack spacing={5} direction='row' justify={"center"}>
                    <Radio colorScheme='orange' value='indian'>
                      Indian Entity
                    </Radio>
                    <Radio colorScheme='orange' value='non-indian'>
                      Non-Indian Entity
                    </Radio>
                  </Stack>
                </RadioGroup>
                {formik.touched.entity && formik.errors.entity && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {formik.errors.entity}
                  </Text>
                )}
              </Flex>
              <Flex gap={'20px'}>
                <Box flex={1}>
                  <FormLabel ms='4px' fontSize='sm'>Company Legal Name</FormLabel>
                  <Input
                    name='companyName'
                    placeholder='Company Legal Name'
                    onChange={formik.handleChange}
                    value={formik.values.companyName}
                    isInvalid={formik.touched.companyName && formik.errors.companyName ? true : false}
                  />
                  {formik.touched.companyName && formik.errors.companyName && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                      {formik.errors.companyName}
                    </Text>
                  )}
                </Box>
                <Box flex={1}>
                  <FormLabel ms='4px' fontSize='sm'>Group Name <Tooltip
                    borderRadius={5}
                    label={
                      <Box fontSize={"sm"} p={"5px"}>
                        <Text>Please add the Whatsapp/Telegram/Signal group name</Text>
                      </Box>
                    }
                    fontSize='md'
                  >
                    <InfoOutlineIcon pointerEvents={'all'} />
                  </Tooltip></FormLabel>
                  <Input
                    name='nickName'
                    placeholder='Group Name'
                    onChange={formik.handleChange}
                    value={formik.values.nickName}
                    isInvalid={formik.touched.nickName && formik.errors.nickName ? true : false}
                  />
                  {formik.touched.nickName && formik.errors.nickName && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                      {formik.errors.nickName}
                    </Text>
                  )}
                </Box>
              </Flex>

              <Box flex={1}>
                <Select
                  flex={1}
                  name='companyType'
                  placeholder='Legal Structure'
                  onChange={formik.handleChange}
                  value={formik.values.companyType}
                  isInvalid={formik.touched.companyType && formik.errors.companyType ? true : false}
                >
                  <option value='Sole Proprietor'>Sole Proprietor</option>
                  <option value='Partnership'>Partnership</option>
                  <option value='Corporation'>Corporation</option>
                  <option value='Joint Venture'>Joint Venture</option>
                  <option value='Franchise'>Franchise</option>
                  <option value='Non-profit'>Non-profit</option>
                  <option value='Other'>Other (please specify)</option>
                </Select>
                {formik.touched.companyType && formik.errors.companyType && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {formik.errors.companyType}
                  </Text>
                )}
                {formik.values.companyType == "Other" ? (
                  <>
                    <Spacer mt={"20px"} />
                    <Textarea
                      name='othercompanyType'
                      placeholder='Specify Other Legal Structure'
                      onChange={formik.handleChange}
                      value={formik.values.othercompanyType}
                      isInvalid={formik.touched.othercompanyType && formik.errors.othercompanyType ? true : false}
                      minLength={10}
                    />
                    {formik.touched.othercompanyType && formik.errors.othercompanyType && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {formik.errors.othercompanyType}
                      </Text>
                    )}
                  </>
                ) : null}

                {/* {formik.values.companyType == "Sole Proprietor" ? (
                  <>
                    <Spacer mt={"10px"} />
                    <Text fontSize={"xs"} color={'red.400'}>
                      If you want to register as an individual entity, please contact us by clicking{" "}
                      <Link textDecoration={"underline"} href='mailto:accounts@blinkdigital.in' isExternal>
                        here
                      </Link>
                    </Text>
                  </>
                ) : null} */}
              </Box>
              <Box flex={1}>
                <Select
                  flex={1}
                  name='typeOfBussiness'
                  placeholder='Type of Business'
                  onChange={formik.handleChange}
                  value={formik.values.typeOfBussiness}
                  isInvalid={formik.touched.typeOfBussiness && formik.errors.typeOfBussiness ? true : false}
                >
                  <option value='Distributor/Dealer'>Distributor/Dealer</option>
                  <option value='Publication/Broadcaster'>Publication/Broadcaster</option>
                  <option value='Professional Service'>Professional Service</option>
                  <option value='Service Provider'>Service Provider</option>
                  <option value='Manufacturer'>Manufacturer</option>
                  <option value='Consultant'>Consultant</option>
                  <option value='Freight/Transporation'>Freight/Transporation</option>
                  <option value='Wholesaler'>Wholesaler</option>
                  <option value='E-Commerce Store'>E-Commerce Store</option>
                  <option value='Other'>Other (please specify)</option>
                </Select>
                {formik.touched.typeOfBussiness && formik.errors.typeOfBussiness && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {formik.errors.typeOfBussiness}
                  </Text>
                )}
                {formik.values.typeOfBussiness == "Other" ? (
                  <>
                    <Spacer mt={"20px"} />
                    <Textarea
                      name='otherTypeOfBussiness'
                      placeholder='Specify Other Business Type'
                      onChange={formik.handleChange}
                      value={formik.values.otherTypeOfBussiness}
                      isInvalid={formik.touched.otherTypeOfBussiness && formik.errors.otherTypeOfBussiness ? true : false}
                      minLength={10}
                    />
                    {formik.touched.otherTypeOfBussiness && formik.errors.otherTypeOfBussiness && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {formik.errors.otherTypeOfBussiness}
                      </Text>
                    )}
                  </>
                ) : null}
              </Box>
              <Box flex={1}>
                <Input
                  name='websiteUrl'
                  placeholder='Website URL'
                  onChange={formik.handleChange}
                  value={formik.values.websiteUrl}
                  isInvalid={formik.touched.websiteUrl && formik.errors.websiteUrl ? true : false}
                  minLength={10}
                />
                {formik.touched.websiteUrl && formik.errors.websiteUrl && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {formik.errors.websiteUrl}
                  </Text>
                )}
              </Box>

              <Flex gap={"20px"}>
                <Box flex={1}>
                  <Textarea
                    name='registerAddress'
                    placeholder='Registered Office Address'
                    onChange={formik.handleChange}
                    value={formik.values.registerAddress}
                    isInvalid={formik.touched.registerAddress && formik.errors.registerAddress ? true : false}
                    minLength={10}
                  />
                  {formik.touched.registerAddress && formik.errors.registerAddress && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                      {formik.errors.registerAddress}
                    </Text>
                  )}
                </Box>

                <Box flex={1}>
                  <Textarea
                    name='correspondenceAddress'
                    placeholder='Correspondence Address'
                    onChange={formik.handleChange}
                    value={formik.values.correspondenceAddress}
                    isInvalid={formik.touched.correspondenceAddress && formik.errors.correspondenceAddress ? true : false}
                    minLength={10}
                  />
                  {formik.touched.correspondenceAddress && formik.errors.correspondenceAddress && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                      {formik.errors.correspondenceAddress}
                    </Text>
                  )}
                </Box>
              </Flex>

              <Box flex={1}>
                <Textarea
                  name='serviceDescription'
                  placeholder='Details on Services/Goods your company provides'
                  onChange={formik.handleChange}
                  value={formik.values.serviceDescription}
                  isInvalid={formik.touched.serviceDescription && formik.errors.serviceDescription ? true : false}
                  minLength={10}
                />
                {formik.touched.serviceDescription && formik.errors.serviceDescription && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {formik.errors.serviceDescription}
                  </Text>
                )}
              </Box>
              <Flex gap={"20px"}>
                <Box flex={1}>
                  <Input
                    name='representativeName'
                    placeholder='Representative Name'
                    onChange={formik.handleChange}
                    value={formik.values.representativeName}
                    isInvalid={formik.touched.representativeName && formik.errors.representativeName ? true : false}
                    minLength={10}
                  />
                  {formik.touched.representativeName && formik.errors.representativeName && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                      {formik.errors.representativeName}
                    </Text>
                  )}
                </Box>
                <Box flex={1}>
                  <Input
                    name='representativePhone'
                    placeholder='Representative Phone Number'
                    onChange={formik.handleChange}
                    value={formik.values.representativePhone}
                    isInvalid={formik.touched.representativePhone && formik.errors.representativePhone ? true : false}
                    minLength={10}
                  />
                  {formik.touched.representativePhone && formik.errors.representativePhone && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                      {formik.errors.representativePhone}
                    </Text>
                  )}
                </Box>
              </Flex>
              <Flex gap={"20px"}>
                <Box flex={1}>
                  <Input
                    name='representativeEmail'
                    placeholder='Representative Email ID'
                    onChange={formik.handleChange}
                    value={formik.values.representativeEmail}
                    isInvalid={formik.touched.representativeEmail && formik.errors.representativeEmail ? true : false}
                    minLength={10}
                  />
                  {formik.touched.representativeEmail && formik.errors.representativeEmail && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                      {formik.errors.representativeEmail}
                    </Text>
                  )}
                </Box>
                <Box flex={1}>
                  <Input
                    name='representativeDesignation'
                    placeholder='Representative Designation'
                    onChange={formik.handleChange}
                    value={formik.values.representativeDesignation}
                    isInvalid={formik.touched.representativeDesignation && formik.errors.representativeDesignation ? true : false}
                    minLength={10}
                  />
                  {formik.touched.representativeDesignation && formik.errors.representativeDesignation && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                      {formik.errors.representativeDesignation}
                    </Text>
                  )}
                </Box>
              </Flex>
              <Box flex={1}>
                <FormLabel fontSize={"sm"} mb='10px'>
                  Authorised Signatory Details
                </FormLabel>
                <Checkbox colorScheme={"orange"} isChecked={formik.values.isOwnerSame} onChange={(e) => formik.setFieldValue("isOwnerSame", e.target.checked)}>
                  Same as Representative's
                </Checkbox>
              </Box>

              {!formik.values.isOwnerSame ? (
                <Flex gap={"20px"}>
                  <Box flex={1}>
                    <Input
                      name='ownerName'
                      placeholder='Owner Name'
                      onChange={formik.handleChange}
                      value={formik.values.ownerName}
                      isInvalid={formik.touched.ownerName && formik.errors.ownerName ? true : false}
                      minLength={10}
                    />
                    {formik.touched.ownerName && formik.errors.ownerName && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {formik.errors.ownerName}
                      </Text>
                    )}
                  </Box>
                  <Box flex={1}>
                    <Input
                      name='ownerPhone'
                      placeholder='Owner Phone'
                      onChange={formik.handleChange}
                      value={formik.values.ownerPhone}
                      isInvalid={formik.touched.ownerPhone && formik.errors.ownerPhone ? true : false}
                      minLength={10}
                    />
                    {formik.touched.ownerPhone && formik.errors.ownerPhone && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {formik.errors.ownerPhone}
                      </Text>
                    )}
                  </Box>
                </Flex>
              ) : null}

              <Button mt={4} mb={4} onClick={formik.handleSubmit}
                colorScheme='orange'
              //isDisabled={formik.values.companyType == 'Sole Proprietor'}
              >
                Submit
              </Button>
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>

      <ConfirmDeleteModal isModalOpen={isDeleteModalOpen} copy={"Are you sure you want to delete the company?"} onCancel={handleOnDeleteCancel} onConfirm={handleOnDeleteConfirm} />

      <SidebarWithHeader>
        <Box mb={'20px'}>
          <Heading as='h1' size='xl' mb={'10px'}>
            Companies
          </Heading>

          {!isOnboarded ? (
            <Text >Please complete verification to gain ad account access</Text>
          ) : null}

        </Box>
        <Box >
          <Grid gridTemplateColumns={"repeat( auto-fill, minmax(240px, 1fr) )"} gap={"20px"}>
            {isCompaniesDataLoading ? (
              <Spinner />
            ) :
              <>

                <Flex
                  borderWidth={1}
                  borderColor={addBoxBorder}
                  borderRadius={5}
                  p={"20px"}
                  w='100%'
                  minH={"200px"}
                  _hover={{
                    bg: addBoxHoverBackground,
                    cursor: "pointer",
                  }}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  onClick={onOpen}
                >
                  <AddIcon boxSize={6} mb={2} />
                  <Text size={"md"}>Add New</Text>
                </Flex>
                {companyList}
              </>}
          </Grid>
          {!companies?.data ? (
            <Text mt={'20px'}>Click the 'Add' button to add your Company details</Text>
          ) : null}
        </Box>
      </SidebarWithHeader>
    </>
  );
}

export default CompaniesForClient;
