import {
    Button,
    Flex,
    FormControl,
    Heading,
    Input,
    Spinner,
    Stack,
    useColorModeValue,
    useToast,
    InputGroup,
    InputRightElement,
} from '@chakra-ui/react';
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { useChangePasswordMutation } from "store/auth/authApi";
import React, { useState } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

export default function ForgotPasswordForm() {

    const navigate = useNavigate()
    const toast = useToast()
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const passwordValidationSchema = Yup.object().shape({
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
    });

    const [changePassword, { data: changeResponse, isLoading }] = useChangePasswordMutation();


    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('white', '#231f20')}>
            <Stack
                spacing={4}
                w={'full'}
                maxW={'md'}
                bg={useColorModeValue('white', 'gray.700')}
                rounded={'xl'}
                boxShadow={'lg'}
                p={6}
                my={12}
                gap={'10px'}
            >
                <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
                    Change password
                </Heading>
                <>
                    <Formik
                        validationSchema={passwordValidationSchema}
                        initialValues={{
                            password: '',
                            confirmPassword: '',
                        }}
                        onSubmit={async (values) => {
                            const { password } = values;
                            const payload = {
                                password: password
                            }
                            //console.log(payload);
                            if (payload) {
                                await changePassword(payload)
                                    .unwrap()
                                    .then((response) => {
                                        const { status } = response;
                                        if (status == 'success') {
                                            toast({
                                                position: "top-right",
                                                title: "Password Changed",
                                                status: "success",
                                                duration: 4000,
                                                isClosable: true,
                                            });
                                            setTimeout(() => {
                                                navigate('/signin')
                                            }, 800);
                                        }
                                    })
                                    .catch((error) => console.error('rejected', error))
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isValid
                        }) => (
                            <>
                                <FormControl>
                                    <FormControl id="password_" isRequired mb={'20px'}>
                                        <InputGroup>
                                            <Input
                                                type={showPassword ? 'text' : 'password'}
                                                id="password"
                                                borderRadius='5px'
                                                fontSize='sm'
                                                size='lg'
                                                placeholder='New password'
                                                name="password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.password}
                                                isInvalid={touched.password && errors.password ? true : false}
                                                isDisabled={isLoading}
                                            />
                                            <InputRightElement h={'full'}>
                                                <Button
                                                    variant={'ghost'}
                                                    onClick={() =>
                                                        setShowPassword((showPassword) => !showPassword)
                                                    }>
                                                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                                </Button>
                                            </InputRightElement>
                                        </InputGroup>
                                    </FormControl>

                                    <FormControl id="confirmPassword_" isRequired mb={'20px'}>
                                        <InputGroup>
                                            <Input
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                id="confirmPassword"
                                                borderRadius='5px'
                                                fontSize='sm'
                                                size='lg'
                                                placeholder='Confirm Password'
                                                name="confirmPassword"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.confirmPassword}
                                                isInvalid={touched.confirmPassword && errors.confirmPassword ? true : false}
                                                isDisabled={isLoading}
                                            />
                                            <InputRightElement h={'full'}>
                                                <Button
                                                    variant={'ghost'}
                                                    onClick={() =>
                                                        setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword)
                                                    }>
                                                    {showConfirmPassword ? <ViewIcon /> : <ViewOffIcon />}
                                                </Button>
                                            </InputRightElement>
                                        </InputGroup>
                                    </FormControl>
                                    <Button
                                        type='submit'
                                        fontSize='16px'
                                        color='white'
                                        fontWeight='bold'
                                        w='100%'
                                        h='45'
                                        mb='24px'
                                        colorScheme="brand"
                                        _hover={{
                                            bg: 'brand.300'
                                        }}
                                        bg={'brand.200'}
                                        onClick={handleSubmit}
                                        isDisabled={!isValid}
                                    >
                                        Confirm {isLoading ? (<Spinner />) : null}
                                    </Button>
                                </FormControl>
                            </>
                        )}
                    </Formik>
                </>

            </Stack>
        </Flex>
    );
}