import { Box, Button, Fade, Flex, Icon, Tooltip } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FiPaperclip } from "react-icons/fi";
import LightBox from './LightBox';

const MultiFileUploadButton = ({ fileSource, fileKey, onFilesSelected, onRemoveClick, isDisabled }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const fileInputRef = React.createRef();;

    const handleFileChange = (event) => {
        const files = event.target.files;
        setSelectedFiles([...files]);
    };

    useEffect(() => {
        if (selectedFiles?.length > 0) {
            onFilesSelected(fileKey, selectedFiles);
            setSelectedFiles([]);
        }

    }, [selectedFiles])

    return (
        <Box>
            {fileSource?.length > 0 ? (
                <Fade in={fileSource}>
                    <Flex alignItems={'flex-start'} flexWrap={'wrap'}>
                        {fileSource?.map((file, index) => (
                            <Flex flexWrap={'wrap'} flexDirection={"row"} gap={"10px"} minW={"220px"} key={'multiUploadUrl' + index}>
                                <LightBox fileSource={file.value} />
                                <Box>
                                    <Button onClick={() => onRemoveClick(index)} size='xs' colorScheme={'red'}>
                                        Remove
                                    </Button>
                                </Box>
                            </Flex>
                        ))}
                    </Flex>
                </Fade>
            ) : (
                <>
                    <input
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                    />
                    <Tooltip label='Attachments' fontSize='xs'>
                        <Button
                            isDisabled={isDisabled}
                            onClick={() => fileInputRef.current.click()}
                            size={'sm'}
                        >
                            <Icon
                                _hover={{ color: "gray.500" }}
                                color={"gray.600"}
                                as={FiPaperclip}
                                cursor={"pointer"}

                            />
                        </Button>
                    </Tooltip>
                </>
            )}
        </Box>
    );
};

export default MultiFileUploadButton;
