const requiredIndianLegalFields = [
    'panNumber',
    'gstNumber',
    'panCard',
    'gstCertificate',
    //'bankDetails',
    //'cancelledCheque',
    //'itDeclaration',
    //'registrationCertificate',
    //'bussinessTradeLicense',
    //'selfDeclaration',
];

const requiredNonIndianLegalFields = [
    'registrationCertificate',
    'bankDeclaration',
    'taxId',
    //'formTenF',
    //'noPeCertificate',
    //'taxResidencyCertificate',
]

const requiredIndianBankFields = [
    "bankName",
    "accountName",
    "accountNumber",
    "ifscCode",
    "branchName",
    "branchAddress",
]

const requiredNonIndianBankFields = [
    "bankingPartner",
    "accountName",
    "accountNumber",
    "accountAddress",
    "bankName",
    "branchAddress",
    "country"
]

const requiredComissionFields = [
    "firstRetroPartnerCommission",
    "firstRetroPartnerName",
    "totalfee",
    "retroComission",
]

const isInArray = (arr, target) => target.every(v => arr.includes(v))

const isLegalFormFulfilled = (entity, values) => {
    const requiredFormFields = entity === 'indian' ? requiredIndianLegalFields : requiredNonIndianLegalFields
    //console.log(Object.entries(values));
    const fieldKeys = Object.keys(values);
    //console.log(isInArray(fieldKeys, requiredFormFields));
    //console.log({ fieldKeys, requiredFormFields });
    if (isInArray(fieldKeys, requiredFormFields)) {
        const truthyArray = requiredFormFields.map((field) => {
            return (values[field]?.value !== '' && values[field]?.value !== undefined)
        })
        return truthyArray.every(v => v === true)
    }

}

const isBankFormFulfilled = (entity, values) => {
    const requiredFormFields = entity === 'indian' ? requiredIndianBankFields : requiredNonIndianBankFields
    //console.log(Object.entries(values));
    let fieldKeys = Object.keys(values);
    //console.log(isInArray(fieldKeys, requiredFormFields));
    //console.log({ fieldKeys, requiredFormFields });
    //const hasIBAN = values['iban']?.value;
    //console.log(!!values['iban']);
    //console.log(values['swift']);

    /** FOR SECOND ITERATION WHEN BOTH IBAN/SWIFT ARE EMPTY IF ONE IS REJECTED */
    if (values['iban']?.value == '' && values['swift']?.value == '') {
        return false;
    }

    fieldKeys = !!values['iban'] ? fieldKeys.filter((v) => v !== 'swift') : fieldKeys.filter((v) => v !== 'iban')
    if (isInArray(fieldKeys, requiredFormFields)) {
        //console.log({ fieldKeys, requiredFormFields });
        const truthyArray = requiredFormFields.map((field) => {
            //console.log(values[field]?.name + ":  " + values[field]?.value);
            return (values[field]?.value !== '' && values[field]?.value !== undefined)
        })
        //console.log(truthyArray);
        return truthyArray.every(v => v === true)
    }

}

const isLegalVerificationChecked = (entity, values) => {
    const requiredFormFields = entity === 'indian' ? requiredIndianLegalFields : requiredNonIndianLegalFields
    const fieldKeys = Object.keys(values);
    //console.log({ requiredFormFields, fieldKeys });
    if (isInArray(fieldKeys, requiredFormFields)) {
        const truthyArray = requiredFormFields.map((field) => {
            return values[field]?.checked
        })
        //console.log(truthyArray);
        return truthyArray.every(v => v === true)
    }

}

const isBankVerificationChecked = (entity, values) => {
    const requiredFormFields = entity === 'indian' ? requiredIndianBankFields : requiredNonIndianBankFields
    let fieldKeys = Object.keys(values);

    fieldKeys = !!values['iban'] ? fieldKeys.filter((v) => v !== 'swift') : fieldKeys.filter((v) => v !== 'iban')
    //console.log(fieldKeys);
    if (isInArray(fieldKeys, requiredFormFields)) {
        const truthyArray = requiredFormFields.map((field) => {
            return values[field]?.checked
        })
        // //console.log(truthyArray);
        return truthyArray.every(v => v === true)
    }
}

const isLegalDetailsApproved = (entity, values) => {
    const requiredFormFields = entity === 'indian' ? requiredIndianLegalFields : requiredNonIndianLegalFields
    const fieldKeys = Object.keys(values);
    //console.log({ requiredFormFields, fieldKeys });
    if (isInArray(fieldKeys, requiredFormFields)) {
        const truthyArray = requiredFormFields.map((field) => {
            return values[field]?.approved
        })
        //console.log(truthyArray);
        return truthyArray.every(v => v === true)
    }

}

const isBankDetailsApproved = (entity, values) => {
    const requiredFormFields = entity === 'indian' ? requiredIndianBankFields : requiredNonIndianBankFields
    let fieldKeys = Object.keys(values);

    fieldKeys = !!values['iban'] ? fieldKeys.filter((v) => v !== 'swift') : fieldKeys.filter((v) => v !== 'iban')
    //console.log(fieldKeys);
    if (isInArray(fieldKeys, requiredFormFields)) {
        const truthyArray = requiredFormFields.map((field) => {
            return values[field]?.approved
        })
        //console.log(truthyArray);
        return truthyArray.every(v => v === true)
    }
}


const isComissionDetailsFilled = (values) => {
    // console.log(values);
    if (!values?.cc && !values?.cl) {
        return false;
    }

    if (values?.cc && !values?.cl) {
        if (Object.keys(values?.cc).length > 0) {
            return true;
        }
        return false;
    } else if (!values?.cc && values?.cl) {
        if (Object.keys(values?.cl).length > 0) {
            return true;
        }
        return false;
    }

    return true;

    // const requiredFormFields = requiredComissionFields;
    // let fieldKeys = Object.keys(values);
    // if (isInArray(fieldKeys, requiredFormFields)) {
    //     const truthyArray = requiredFormFields.map((field) => {
    //         return values[field] !== ""
    //     })
    //     //console.log(truthyArray);
    //     return truthyArray.every(v => v === true)
    // }
}

export { isLegalFormFulfilled, isBankFormFulfilled, isLegalVerificationChecked, isBankVerificationChecked, isBankDetailsApproved, isLegalDetailsApproved, isComissionDetailsFilled };