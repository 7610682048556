import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text
} from '@chakra-ui/react';

import React from 'react';

function ConfirmDeleteModal({ copy, isModalOpen, onCancel, onConfirm }) {


    return (
        <Modal size={'lg'} isCentered={true} isOpen={isModalOpen} onClose={onCancel}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader textAlign={'center'}>Delete</ModalHeader>
                <ModalCloseButton />
                <ModalBody textAlign={'center'}>
                    <Text>{copy}</Text>

                    <Flex gap={'20px'} mb={'20px'} mt={'40px'} align={'center'} justify={'center'}>
                        <Button onClick={onCancel}>Cancel</Button>
                        <Button colorScheme='red' onClick={onConfirm}>Confirm</Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default ConfirmDeleteModal