import FirstIcon from "assets/images/01.png";
import SecondIcon from "assets/images/02.png";
import ThirdIcon from "assets/images/03.png";
import ForthIcon from "assets/images/04.png";
import Fifth from "assets/images/05.png";
import FacebookImage from "assets/images/Facebook-Logo.wine.png";
import Logo from "assets/images/LOGO.png";
import NextfirstIcon from "assets/images/_01.png";
import NextSecondIcon from "assets/images/_02.png";
import NextThirdIcon from "assets/images/_03.png";
import BgMidas from "assets/images/bg-MIDAS.png";
import Map from "assets/images/bg-map.png";
import Illustration from "assets/images/illustration.png";
import React from "react";
import { useNavigate } from "react-router-dom";

import { FiArrowUpRight } from "react-icons/fi";
import { HamburgerIcon } from "@chakra-ui/icons";
import HomeBanner from "./HomeBanner";
import LogoNav from "assets/images/logo-main.png";
import ServiceBack from "assets/images/bg_dots02.png";
import { Link as ChakraLink } from "@chakra-ui/react";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Flex,
  Heading,
  IconButton,
  Image,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { LinkIcon } from "@chakra-ui/icons";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import { Link } from "react-scroll";

function SizeExample() {
  const [size, setSize] = React.useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClick = (newSize) => {
    setSize(newSize);
    onOpen();
  };

  const sizes = ["full"];

  return (
    <>
      {sizes.map((size, index) => (
        <Button
          key={index}
          onClick={() => handleClick(size)}
          display={{ base: "block", md: "none" }}
          as={IconButton}
          aria-label='Options'
          icon={<HamburgerIcon />}
          variant='outline'
        >{`Open ${size} Drawer`}</Button>
      ))}

      <Drawer onClose={onClose} isOpen={isOpen} size={size}>
        <DrawerOverlay />
        <DrawerContent bg='#231f20'>
          <DrawerCloseButton color='#915490' />
          <DrawerHeader>
            {" "}
            <Box>
              {/* <Text
                fontSize='2xl'
                bgGradient='linear(to-r,  #ef9175, #e7646c,#ec7768,#915490)'
                bgClip='text'
              >
                Midas
              </Text>
              <Text fontSize='xs'>By Blink Digital</Text> */}
              <Image w='200px' src={LogoNav} alt='logo - image' />
            </Box>{" "}
          </DrawerHeader>
          <DrawerBody>
            <Flex
              direction='column'
              gap='4'
              display={{ base: "block", md: "none" }}
            >
              <VStack>
                <Link
                  to='section03' // Scroll karna chahte hain section ka ID den
                  smooth={true} // Smooth scrolling enable karein
                  duration={500} // Scroll hone me kitna samay lagana chahte hain, milliseconds me
                  onClick={onClose}
                >
                  <Button
                    bg='transparent'
                    colorScheme='teal'
                    _hover={{ textDecoration: "underline" }}
                  >
                    {" "}
                    <Text
                      bgGradient='linear(to-r, #ef9175, #e7646c,#ec7768,#915490)'
                      bgClip='text'
                      fontSize='2xl'
                    >
                      Why Us
                    </Text>
                  </Button>
                </Link>

                <Link
                  to='section04' // Scroll karna chahte hain section ka ID den
                  smooth={true} // Smooth scrolling enable karein
                  duration={500} // Scroll hone me kitna samay lagana chahte hain, milliseconds me
                  onClick={onClose}
                >
                  <Button
                    bg='transparent'
                    colorScheme='teal'
                    _hover={{ textDecoration: "underline" }}
                  >
                    {" "}
                    <Text
                      bgGradient='linear(to-r,#ef9175, #e7646c,#ec7768,#915490)'
                      bgClip='text'
                      fontSize='2xl'
                    >
                      Our Services
                    </Text>
                  </Button>
                </Link>

                <Link
                  to='section05' // Scroll karna chahte hain section ka ID den
                  smooth={true} // Smooth scrolling enable karein
                  duration={500} // Scroll hone me kitna samay lagana chahte hain, milliseconds me
                  onClick={onClose}
                >
                  <Button
                    bg='transparent'
                    colorScheme='teal'
                    _hover={{ textDecoration: "underline" }}
                  >
                    {" "}
                    <Text
                      bgGradient='linear(to-r, #ef9175, #e7646c,#ec7768,#915490)'
                      bgClip='text'
                      fontSize='2xl'
                    >
                      Our Clients
                    </Text>
                  </Button>
                </Link>
                <Link
                  to='section07' // Scroll karna chahte hain section ka ID den
                  smooth={true} // Smooth scrolling enable karein
                  duration={500} // Scroll hone me kitna samay lagana chahte hain, milliseconds me
                  onClick={onClose}
                >
                  <Button
                    bg='transparent'
                    colorScheme='teal'
                    _hover={{ textDecoration: "underline" }}
                  >
                    {" "}
                    <Text
                      bgGradient='linear(to-r,#ef9175, #e7646c,#ec7768,#915490)'
                      bgClip='text'
                      fontSize='2xl'
                    >
                      Contact Us
                    </Text>
                  </Button>
                </Link>
              </VStack>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

function ImageCarousel() {
  return (
    <Carousel>
      <Box>
        <Image src='image1.jpg' alt='Image-1' />
      </Box>
      <Box>
        <Image src='image2.jpg' alt='Image 2' />
      </Box>
      <Box>
        <Image src='image3.jpg' alt='Image 3' />
      </Box>
    </Carousel>
  );
}

function LandingPage() {
  const navigate = useNavigate();

  return (
    <Box bg='#fbfbfb' color='black'>
      {/* nav */}
      <Box bg='#231f20' py='20px'>
        <Container maxW='1200px'>
          {" "}
          <Flex p='4' alignItems='center' justifyContent='space-between'>
            <Heading color='white'>
              <Box>
                {/* <Text
              fontSize='6xl'
              bgGradient='linear(to-r,  #ef9175, #e7646c,#ec7768,#915490)'
              bgClip='text'
              textTransform='uppercase'
              fontWeight='light'
            >
              Midas
            </Text>
            */}
                <Image w='200px' src={LogoNav} alt='logo-image' />
              </Box>
            </Heading>
            <Spacer />
            <ButtonGroup gap='2' display={{ base: "none", md: "block" }}>
              <Button
                color='white'
                _hover={{
                  background:
                    "linear-gradient(to right,  #ef9175, #e7646c,#ec7768,#915490)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                bg='transparent'
              >
                {" "}
                <Link
                  to='section03'
                  smooth={true} // Smooth scrolling enable karein
                  duration={500} // Scroll hone me kitna samay lagana chahte hain, milliseconds me
                  fontSize='xl'
                >
                  Why Us
                </Link>
              </Button>
              <Button
                color='white'
                _hover={{
                  background:
                    "linear-gradient(to right,  #ef9175, #e7646c,#ec7768,#915490)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                bg='transparent'
              >
                {" "}
                <Link fontSize='xl' to='section04' smooth={true} duration={500}>
                  Our Services
                </Link>
              </Button>

              <Button
                color='white'
                _hover={{
                  background:
                    "linear-gradient(to right,  #ef9175, #e7646c,#ec7768,#915490)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                bg='transparent'
              >
                {" "}
                <Link
                  to='section05' // Scroll karna chahte hain section ka ID den
                  smooth={true} // Smooth scrolling enable karein
                  duration={700} // Scroll hone me kitna samay lagana chahte hain, milliseconds me
                  fontSize='xl'
                >
                  Our Clients
                </Link>
              </Button>
              <Button
                color='white'
                _hover={{
                  background:
                    "linear-gradient(to right, #ef9175, #e7646c,#ec7768,#915490)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                bg='transparent'
              >
                {" "}
                <Link
                  to='section07' // Scroll karna chahte hain section ka ID den
                  smooth={true} // Smooth scrolling enable karein
                  duration={700}
                  fontSize='xl'
                >
                  Contact Us
                </Link>
              </Button>
              <Button
                bgGradient='linear(to-r, #ef9175, #e7646c,#ec7768,#915490)'
                color='white'
                borderRadius='none'
                _hover={{ color: "black" }}
                onClick={() => {
                  navigate("/signin");
                }}
              >
                {" "}
                <Text fontSize='xl'>Login</Text>
              </Button>
            </ButtonGroup>
            <SizeExample display={{ base: "block", md: "none" }} />
          </Flex>
        </Container>
      </Box>

      {/* banner 1 */}

      <HomeBanner />

      {/* 2nd banner */}
      {/* <Box id='section02' bg='#ededed' p={4}>
        <Container maxW='1200px'>
          <Button
            fontSize={{ base: "17px", md: "22px", xl: "30px" }}
            py='70px'
            w='100%'
            colorScheme='messenger'
            borderRadius='18px'
          >
            Partner Advertising banner
          </Button>
        </Container>
      </Box> */}

      {/* 3rd banner */}
      <Container
        id='section03'
        py={{ base: "80px", md: "40px", xl: "100px" }}
        maxW='1200px'
      >
        <Text
          bgGradient='linear(to-r, #ef9175, #e7646c,#ec7768,#915490)'
          // bgClip='text'
          color='white'
          fontSize={{ base: "24px", md: "30px", lg: "40px" }}
          textTransform='uppercase'
          lineHeight='1.1'
          maxW='max-content'
          letterSpacing='2px'
          fontWeight='black'
          px={["20px", "30px"]}
          py={["9px", "13px"]}
          mb={{ base: "20px", md: "30px", xl: "40px" }}
        >
          {" "}
          Why Us
        </Text>
        <SimpleGrid columns={[, 2, 2]} spacing='40px'>
          {/* <Box>
            <Flex direction='column' gap='2'>
              <Box>
                <Image src={FirstIcon} alt='icon1' />
              </Box>
              <Box>
                <Text
                  fontSize={{ base: "18px", md: "22px", xl: "24px" }}
                  noOfLines={["1", "2"]}
                >
                  Get Cashback <br /> On Your Ad Spends
                </Text>
              </Box>
            </Flex>
          </Box> */}
          <Box>
            <Flex direction='column' gap='2'>
              <Box>
                <Image src={Fifth} alt='icon5' />
              </Box>
              <Box
                fontSize={{ base: "18px", md: "22px", xl: "24px" }}
                fontWeight='black'
              >
                Attach Your Credit Card And Get Cashback On Your Ad Spends
              </Box>
            </Flex>
          </Box>

          <Box>
            <Flex direction='column' gap='2'>
              <Box>
                <Image src={ThirdIcon} alt='icon3' />
              </Box>
              <Box
                fontWeight='black'
                fontSize={{ base: "18px", md: "22px", xl: "24px" }}
              >
                Instant Top Up Based On Transaction Screenshot
              </Box>
            </Flex>
          </Box>
          <Box>
            <Flex direction='column' gap='2'>
              <Box>
                <Image src={ForthIcon} alt='icon4' />
              </Box>
              <Box
                fontWeight='black'
                fontSize={{ base: "18px", md: "22px", xl: "24px" }}
              >
                High Score Business Manager With Better CPMs and CPAs
              </Box>
            </Flex>
          </Box>
          <Box>
            <Flex direction='column' gap='2'>
              <Box>
                <Image src={SecondIcon} alt='icon2' />
              </Box>
              <Box
                fontWeight='black'
                fontSize={{ base: "18px", md: "22px", xl: "24px" }}
              >
                24/7 Support Across Worldwide Time Zones
              </Box>
            </Flex>
          </Box>
        </SimpleGrid>
      </Container>

      {/* 4th banner */}
      <Box
        id='section04'
        py={{ base: "80px", md: "40px", xl: "120px" }}
        bgGradient='linear(to-r,  #ef9175, #e7646c,#ec7768,#915490)'
        position='relative'
        zIndex='20'
        pb={{ xl: "0px" }}
      >
        {/* <Image
          w='100%'
          top={{ base: "0", lg: "0px" }}
          src={ServiceBack}
          position='absolute'
          alt='backDots'
        /> */}
        <Box position='relative' zIndex='10'>
          <SimpleGrid columns={[1, 2]} spacing='20px'>
            <Box pl={{ base: "20px", lg: "123px", xl: "170px" }}>
              <Box bg='white' maxW='max-content'>
                {" "}
                <Text
                  bgGradient='linear(to-r, #ef9175, #e7646c,#ec7768,#915490)'
                  bgClip='text'
                  color='transparent'
                  // bg='white'
                  fontSize={{ base: "24px", md: "30px", lg: "40px" }}
                  textTransform='uppercase'
                  lineHeight='1.1'
                  maxW='max-content'
                  letterSpacing='2px'
                  fontWeight='black'
                  px={["20px", "30px"]}
                  py={["9px", "13px"]}
                  mb='5px'
                >
                  {" "}
                  Our Services
                </Text>
              </Box>

              <Text
                my={{ base: "15px", md: "10px", xl: "20px" }}
                fontSize={{ base: "18px", md: "20px", xl: "26px" }}
                color='white'
              >
                Increase social media advertising and reach with whitelisted and
                stable Ad accounts with our Media Partner - Meta.
              </Text>
              <Image
                border='1px'
                borderColor='white'
                py={["15px", "30px"]}
                px='60px'
                src={FacebookImage}
                alt='iconfacbook'
                w={{ base: "232px", xl: "336px" }}
              />
            </Box>

            <Box pl={{ base: "20px" }}>
              <Image src={Illustration} alt='iconillstraction' />
            </Box>
          </SimpleGrid>
        </Box>
      </Box>

      {/* 5th clients */}
      <Box id='section05' py={{ base: "80px", md: "40px", xl: "100px" }}>
        <Container maxW='1200px'>
          <Text
            bgGradient='linear(to-r, #ef9175, #e7646c,#ec7768,#915490)'
            // bgClip='text'
            color='white'
            fontSize={{ base: "24px", md: "30px", lg: "40px" }}
            textTransform='uppercase'
            lineHeight='1.1'
            maxW='max-content'
            letterSpacing='2px'
            fontWeight='black'
            px={["20px", "30px"]}
            py={["9px", "13px"]}
            mb='40px'
          >
            {" "}
            Our Clients
          </Text>
          <SimpleGrid columns={[1, 2, 3]} spacing='40px'>
            <Box border='1px' p={["6", "8"]}>
              <Flex direction='column' gap='2'>
                <Box>
                  <Image src={NextfirstIcon} alt='iconNext' mb='20px' />
                </Box>
                <Box
                  fontSize={{ base: "22px", md: "22px", xl: "28px" }}
                  mb='15px'
                >
                  Lead Generation
                </Box>
                <Text
                  fontSize={["18px", "25px"]}
                  color='#a0a0a0'
                  textTransform='capitalize'
                  lineHeight='1.6'
                >
                  {" "}
                  Health/Car Insurance,
                  <br /> Finance,
                  <br /> Home Improvement, Etc.
                </Text>
              </Flex>
            </Box>
            <Box border='1px' p={["6", "8"]}>
              <Flex direction='column' gap='2'>
                <Box>
                  <Image src={NextSecondIcon} alt='iconsecond icon' mb='20px' />
                </Box>
                <Box
                  fontSize={{ base: "22px", md: "22px", xl: "28px" }}
                  mb='15px'
                >
                  E-Commerce
                </Box>
                <Text
                  fontSize={["18px", "25px"]}
                  color='#a0a0a0'
                  lineHeight='1.6'
                  textTransform='capitalize'
                >
                  {" "}
                  Shopify,
                  <br /> Dropshipping,
                  <br /> Brand stores, Etc.
                </Text>
              </Flex>
            </Box>
            <Box border='1px' p={["6", "8"]}>
              <Flex direction='column' gap='2'>
                <Box>
                  <Image src={NextThirdIcon} alt='iconthird' mb='20px' />
                </Box>
                <Box
                  color
                  fontSize={{ base: "22px", md: "22px", xl: "32px" }}
                  mb='15px'
                >
                  Affiliate
                </Box>
                <Text
                  fontSize={["18px", "25px"]}
                  color='#a0a0a0'
                  lineHeight='1.6'
                  textTransform='capitalize'
                >
                  {" "}
                  Nutra,
                  <br /> Ecom,
                  <br /> DIY, Etc.
                </Text>
              </Flex>
            </Box>
          </SimpleGrid>
        </Container>
      </Box>

      {/* 6th banner */}
      <Box
        id='section06'
        py={{ base: "80px", md: "40px", xl: "100px" }}
        bg='#231f20'
        bgImage={Map}
        bgPosition={{ base: "45%", xl: "top" }}
        bgSize={{ base: "cover", md: "cover", xl: "cover" }}
        bgRepeat='no-repeat'
      >
        <Container maxW='1200px'>
          <Box
            pb={{ base: "24px", md: "30px", lg: "120px" }}
            // pt={{ base: "24px", md: "30px", lg: "80px" }}
            justify='space-between'
          >
            <Text
              textTransform='uppercase'
              color='white'
              fontSize={{ base: "28px", md: "30px", lg: "40px" }}
              fontWeight='bold'
              pb={{ base: "24px", md: "30px", lg: "100px" }}
              pt={["0px", "35px"]}
              // pt={{ base: "24px", md: "30px", lg: "40px" }}
            >
              global <br /> presence
            </Text>
          </Box>
        </Container>
      </Box>

      {/* 7th banner */}
      <Box id='section07' pt={{ base: "80px", md: "40px", xl: "100px" }}>
        <Container pb='20px' maxW='1200px'>
          <Text
            bgGradient='linear(to-r, #ef9175, #e7646c,#ec7768,#915490)'
            // bgClip='text'
            color='white'
            fontSize={{ base: "24px", md: "30px", lg: "40px" }}
            textTransform='uppercase'
            lineHeight='1.1'
            maxW='max-content'
            letterSpacing='2px'
            fontWeight='black'
            px={["20px", "30px"]}
            py={["9px", "13px"]}
            mb='5px'
          >
            {" "}
            Contact Us
          </Text>
          <Text py='10px' fontSize={["md", "xl"]}>
            Unlock the power of Meta Ad accounts and explore our range of
            creative, digital, and tech services. We're just a click away!
          </Text>
        </Container>
        <Container my={4} maxW='1200px'>
          <Divider />
        </Container>

        <Box
          backgroundImage={BgMidas}
          backgroundPosition='top'
          backgroundRepeat='no-repeat'
          backgroundSize='cover'
          css={{
            "@media (max-width: 768px)": {
              backgroundImage: "none",
            },
          }}
        >
          <Container py={["20px", "90px"]} maxW='1200px' pb={["45px", "90px"]}>
            <SimpleGrid columns={[1, null, 2]} spacing='40px'>
              <Box height=''>
                <Text fontSize={["25px", "35px"]} mb='20px'>
                  Ad Accounts for Meta
                </Text>
                <Text color='#9d9d9d' fontSize={["18px", "22px"]} mb='20px'>
                  Get a stable and trustworthy Ad Account
                </Text>
                <Box
                  fontSize='22px'
                  color='black'
                  href='https://media.blinkdigital.in/signUp'
                  isExternal='true'
                >
                  <Flex align='center'>
                    <ChakraLink
                      bgGradient='linear(to-r, #ef9175, #e7646c,#ec7768,#915490)'
                      // bgClip='text'
                      color='white'
                      px='15px'
                      py='5px'
                      fontSize={["18px", "20px"]}
                      fontWeight='black'
                      _hover={{ textDecorationLine: "none", color: "black" }}
                    >
                      <Flex align='center'>
                        <Text>Request now </Text>
                        <FiArrowUpRight />
                      </Flex>
                    </ChakraLink>
                  </Flex>
                </Box>
              </Box>
              <Box height=''>
                <Text fontSize={["25px", "35px"]} mb='20px'>
                  Diverse Digital Services
                </Text>
                <Text color='#9d9d9d' fontSize={["18px", "22px"]} mb='20px'>
                  Explore our full range of Services
                </Text>
                <Box>
                  <Flex>
                    <ChakraLink
                      bgGradient='linear(to-r, #ef9175, #e7646c,#ec7768,#915490)'
                      // bgClip='text'
                      color='white'
                      px='15px'
                      py='5px'
                      fontSize={["18px", "20px"]}
                      fontWeight='black'
                      _hover={{ textDecorationLine: "none", color: "black" }}
                    >
                      {" "}
                      <Flex align='center'>
                        <Text> Browse now</Text>
                        <FiArrowUpRight display='inline' />
                      </Flex>
                    </ChakraLink>
                  </Flex>
                </Box>
              </Box>
            </SimpleGrid>
          </Container>
        </Box>
      </Box>
      {/* footer */}
      <Box
        bgGradient='linear(to-r, #ef9175, #e7646c,#ec7768,#915490)'
        // bgClip='text'
        color='white'
        py='30px'
      >
        <Container maxW='1200px'>
          <Flex
            justify='space-between'
            align={["center", "center"]}
            direction={{ base: "column", md: "row", lg: "row" }}
            gap={{ base: "20px", md: "20px", lg: "20px" }}
          >
            <Text
              textTransform='uppercase'
              fontSize={{ base: "16px", md: "16px", lg: "18px" }}
            >
              all rights reserved 2023
            </Text>
            <Flex
              align='center'
              direction={{ base: "column", md: "row", lg: "row" }}
              gap={{ base: "10px", md: "20px", lg: "30px" }}
            >
              <ChakraLink
                isExternal='true'
                href='https://www.facebook.com/BlinkDigitalIndia'
              >
                <Text fontSize={["15px", "18px"]} textTransform='uppercase'>
                  facebook
                </Text>
              </ChakraLink>
              <ChakraLink
                isExternal='true'
                href='https://www.instagram.com/blink.digital/'
              >
                <Text fontSize={["15px", "18px"]} textTransform='uppercase'>
                  instagram
                </Text>
              </ChakraLink>

              <ChakraLink
                isExternal='true'
                href='https://twitter.com/blink_india'
              >
                <Text fontSize={["15px", "18px"]} textTransform='uppercase'>
                  twitter
                </Text>
              </ChakraLink>

              <ChakraLink
                isExternal='true'
                href='https://www.linkedin.com/company/blink-digital'
              >
                <Text fontSize={["15px", "18px"]} textTransform='uppercase'>
                  LinkedIn
                </Text>
              </ChakraLink>
            </Flex>
          </Flex>
        </Container>
      </Box>

      {/* <ImageCarousel /> */}
    </Box>
  );
}

export default LandingPage;
