import {
    Box,
    Flex,
    Heading,
    SimpleGrid,
    Spinner,
    Stat,
    StatLabel,
    StatNumber,
    Text,
    Link,
    useColorModeValue
} from '@chakra-ui/react';
import { RangeDatepicker } from 'chakra-dayzed-datepicker';
import AppTable from 'components/AppTable';
import SidebarWithHeader from 'components/SidebarWithHeader';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetAdsAccountQuery } from 'store/adAccounts/adAccountsApi';
import { useGetTopupsByAdsAccountQuery } from 'store/topUp/topUpApi';

function AdAccountWiseDashboard() {

    const { id } = useParams();

    const [selectedDates, setSelectedDates] = useState([new Date(), new Date()]);


    // console.log({ selectedDates });

    // TOPUP TRANSACTIONS 

    const {
        data: topUpsData,
        isLoading: isTopUpsDataLoading,
        isFetching: isTopUpsDataFetching
    } = useGetTopupsByAdsAccountQuery({ id }, {
        //pollingInterval: 30000,
        //skipPollingIfUnfocused: true,
    });

    // FOR STATS : DEBITS

    const {
        data: overallDebits,
        isLoading: isOverallDebitsLoading,
        isFetching: isOverallDebitsFetching
    } = useGetAdsAccountQuery(id);


    const topUpListColumns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: '',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>#{original.topupNumber}</Text>)
            },
            {
                Header: 'Ads Account Id',
                accessor: 'adsAccountId',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.percentSplit?.adsId}</Text>)
            },
            {
                Header: 'account Type',
                accessor: 'accountType',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => {
                    // console.log({ original });
                    const typeCopy = original.accountType == 'cc' ? 'Credit Card' : 'Credit Line';
                    return (
                        <Text fontSize={'14px'}>{typeCopy}</Text>
                    )

                }
            },

            {
                Header: 'Top up',
                accessor: '',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'} textAlign={'right'}>{Number(original.percentSplit?.topup)?.toFixed(2)}</Text>)
            },
            {
                Header: 'Commission',
                accessor: '',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'} textAlign={'right'}>{Number(original.percentSplit?.commission)?.toFixed(2)}</Text>)
            },
            {
                Header: 'currency',
                accessor: 'currency',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.currency}</Text>)
            },

            // {
            //     Header: 'transferred From',
            //     accessor: 'transferredFrom',
            //     Filter: '',
            //     filter: '',
            //     Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.transferredFrom}</Text>)
            // },
            // {
            //     Header: 'transferred To',
            //     accessor: 'transferredTo',
            //     Filter: '',
            //     filter: '',
            //     Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.transferredFrom}</Text>)
            // },
            {
                Header: 'payment Date',
                accessor: 'paymentDate',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{moment(original.paymentDate).format("DD-MM-YYYY hh:mm A")}</Text>)
            },
            // {
            //     Header: 'topup Date',
            //     accessor: 'topupDate',
            //     Filter: '',
            //     filter: '',
            //     Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{moment(original.topupDate).format("DD-MM-YYYY hh:mm A")}</Text>)
            // },

        ],
        []
    )

    //console.log({ topUpsData });
    //  console.log(overallDebits);



    return (
        <SidebarWithHeader>

            {(isOverallDebitsLoading || isTopUpsDataLoading) ? (
                <Spinner mt={'20px'} />
            ) : (
                <>
                    {topUpsData?.topups?.length > 0 ? (
                        <>
                            <Box mb={'20px'}>
                                <Heading as={'h4'} size='md' mb={'10px'}>Ad Account: {overallDebits?.adsAccountName}</Heading>
                                <Text>Client: <Link href={`/dashboard/clients/${overallDebits?.clientId?.id}`} textDecoration={'underline'} _hover={{ color: '#ea7869' }}> {overallDebits?.clientId?.name} </Link></Text>
                            </Box>
                            <Flex >Total Topup:&nbsp; <Text fontWeight={'bold'}>{Number(topUpsData?.totalTopup)?.toFixed(2)}</Text> </Flex>
                            <Flex >Total Commission:&nbsp; <Text fontWeight={'bold'}>{topUpsData?.totalCommission?.toFixed(2)}</Text> </Flex>

                            <SimpleGrid columns={{ base: 1, md: 4 }} spacing={{ base: 5, lg: 8 }} mt={'20px'}>
                                {/* <StatsCard title={'Total Commission'} stat={topUpsData?.totalCommission?.toFixed(2)} />
                                <StatsCard title={'Total Topup'} stat={Number(topUpsData?.totalTopup)?.toFixed(2)} /> */}
                                <StatsCard title={'Current Spend Cap'} stat={Number(overallDebits?.adsAccountData?.spend_cap * 0.01)?.toFixed(2)} />
                                <StatsCard title={'Current Spent'} stat={Number(overallDebits?.adsAccountData?.amountSpent * 0.01)?.toFixed(2)} />
                                {topUpsData?.totalTopup && overallDebits?.adsAccountData?.amountSpent ? (
                                    <StatsCard title={'Current Balance'} stat={(Number(overallDebits?.adsAccountData?.spend_cap * 0.01)?.toFixed(2) - Number(overallDebits?.adsAccountData?.amountSpent * 0.01))?.toFixed(2)} />
                                ) : null}
                            </SimpleGrid>
                            <Box mt={'20px'}>
                                {topUpsData?.topups?.length > 0 ? (
                                    <>
                                        <Flex gap={'20px'} align={'center'} mb={'20px'}>
                                            <Heading as='h2' size='md' flex={1}>
                                                Transaction History
                                            </Heading>
                                            {/* <Box flex={1} maxW={'240px'}>
                                                <RangeDatepicker
                                                    selectedDates={selectedDates}
                                                    onDateChange={setSelectedDates}
                                                />
                                            </Box> */}
                                        </Flex>

                                        <AppTable
                                            columns={topUpListColumns}
                                            data={topUpsData?.topups}
                                            isFetching={isTopUpsDataFetching}
                                            searchEnabled={false}
                                        />
                                    </>
                                ) : null}
                            </Box>
                        </>
                    ) : (
                        <Text>No topup yet</Text>
                    )}

                </>
            )}

        </SidebarWithHeader>
    )
}

function StatsCard(props) {
    const { title, stat } = props
    return (
        <Stat
            px={{ base: 4, md: 8 }}
            py={'5'}
            border={'1px solid'}
            borderColor={useColorModeValue('gray.800', 'gray.500')}
            rounded={'lg'}>
            <StatLabel fontWeight={'medium'} isTruncated>
                {title}
            </StatLabel>
            <StatNumber fontSize={'xl'} fontWeight={'bold'}>
                {stat}
            </StatNumber>
        </Stat>
    )
}

export default AdAccountWiseDashboard