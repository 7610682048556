import {
    Flex,
    Tab,
    TabIndicator,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useColorModeValue
} from '@chakra-ui/react';

import React from 'react';
import SidebarWithHeader from 'components/SidebarWithHeader';

function ClientProfile() {
    const contentBorderColor = useColorModeValue('gray.100', 'gray.700');

    return (
        <SidebarWithHeader >
            <Flex p={'20px'} flexDirection={'column'}>
                <Tabs position="relative" variant="unstyled">
                    <TabList>
                        <Tab>One</Tab>
                        <Tab>Two</Tab>
                    </TabList>
                    <TabIndicator
                        mt="-1.5px"
                        height="2px"
                        bg="gray.500"
                        borderRadius="1px"
                    />
                    <TabPanels>
                        <TabPanel>
                            <p>one!</p>
                        </TabPanel>
                        <TabPanel>
                            <p>two!</p>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Flex>
        </SidebarWithHeader>
    )
}

export default ClientProfile