import {
    Button,
    FormControl,
    Flex,
    Heading,
    Input,
    Stack,
    Text,
    useColorModeValue,
    Spinner,
    useToast,
    InputGroup,
    InputRightElement,
} from '@chakra-ui/react';
import { Formik } from "formik";
import * as Yup from 'yup';
import { useForgotPasswordMutation, useForgotPasswordChangeMutation } from "store/auth/authApi";
import {
    useSearchParams,
    useNavigate
} from "react-router-dom"
import React, { useState } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';


export default function ForgotPasswordForm() {

    const navigate = useNavigate()
    const [queryParameters] = useSearchParams()
    const verificationCode = queryParameters.get("code");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);


    const toast = useToast()
    const emailValidationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
    });

    const passwordValidationSchema = Yup.object().shape({
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
    });

    const [forgotPassword, { data: forgotResponse, isLoading: forgotResponseIsLoading }] = useForgotPasswordMutation();
    const [forgotPasswordChange, { data: verifyResponse, isLoading: verifyResponseIsLoading }] = useForgotPasswordChangeMutation();


    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('white', '#231f20')}>
            <Stack
                spacing={4}
                w={'full'}
                maxW={'md'}
                bg={useColorModeValue('white', 'gray.700')}
                rounded={'xl'}
                boxShadow={'lg'}
                p={6}
                my={12}
                gap={'10px'}
            >
                <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
                    Forgot password?
                </Heading>
                {verificationCode?.length > 0 ? (
                    <>
                        <Formik
                            validationSchema={passwordValidationSchema}
                            initialValues={{
                                password: '',
                                confirmPassword: '',
                            }}
                            onSubmit={async (values) => {
                                const { password } = values;
                                const payload = {
                                    code: verificationCode,
                                    password: password
                                }
                                // //console.log(payload);
                                if (payload) {
                                    await forgotPasswordChange(payload)
                                        .unwrap()
                                        .then((response) => {
                                            const { status } = response;
                                            if (status == 'success') {
                                                toast({
                                                    position: "top-right",
                                                    title: "Success",
                                                    description: 'Your Password has been changed',
                                                    status: "success",
                                                    duration: 4000,
                                                    isClosable: true,
                                                });
                                                setTimeout(() => {
                                                    navigate('/signin')
                                                }, 1000);
                                            }
                                        })
                                        .catch((error) => {
                                            toast({
                                                position: "top-right",
                                                title: "Error",
                                                status: "error",
                                                duration: 4000,
                                                isClosable: true,
                                            });
                                        })
                                }
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isValid
                            }) => (
                                <>
                                    <FormControl>
                                        <FormControl id="password_" isRequired mb={'20px'}>
                                            <InputGroup>
                                                <Input
                                                    type={showPassword ? 'text' : 'password'}
                                                    id="password"
                                                    borderRadius='5px'
                                                    fontSize='sm'
                                                    size='lg'
                                                    placeholder='New password'
                                                    name="password"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.password}
                                                    isInvalid={touched.password && errors.password ? true : false}
                                                    isDisabled={verifyResponseIsLoading}
                                                />
                                                <InputRightElement h={'full'}>
                                                    <Button
                                                        variant={'ghost'}
                                                        onClick={() =>
                                                            setShowPassword((showPassword) => !showPassword)
                                                        }>
                                                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                                    </Button>
                                                </InputRightElement>
                                            </InputGroup>
                                        </FormControl>

                                        <FormControl id="confirmPassword_" isRequired mb={'20px'}>
                                            <InputGroup>
                                                <Input
                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                    id="confirmPassword"
                                                    borderRadius='5px'
                                                    fontSize='sm'
                                                    size='lg'
                                                    placeholder='Confirm Password'
                                                    name="confirmPassword"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.confirmPassword}
                                                    isInvalid={touched.confirmPassword && errors.confirmPassword ? true : false}
                                                    isDisabled={verifyResponseIsLoading}
                                                />
                                                <InputRightElement h={'full'}>
                                                    <Button
                                                        variant={'ghost'}
                                                        onClick={() =>
                                                            setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword)
                                                        }>
                                                        {showConfirmPassword ? <ViewIcon /> : <ViewOffIcon />}
                                                    </Button>
                                                </InputRightElement>
                                            </InputGroup>
                                        </FormControl>

                                        <Button
                                            type='submit'
                                            fontSize='16px'
                                            color='white'
                                            fontWeight='bold'
                                            w='100%'
                                            h='45'
                                            mb='24px'
                                            colorScheme="brand"
                                            _hover={{
                                                bg: 'brand.300'
                                            }}
                                            bg={'brand.200'}
                                            onClick={handleSubmit}
                                            isDisabled={!isValid}
                                        >
                                            Confirm {verifyResponseIsLoading ? (<Spinner />) : null}
                                        </Button>
                                    </FormControl>
                                </>
                            )}
                        </Formik>
                    </>

                ) : (
                    <>
                        <Text
                            fontSize={{ base: 'sm', sm: 'md' }}>
                            You'll get an email with a reset link
                        </Text>
                        <Formik
                            validationSchema={emailValidationSchema}
                            initialValues={{ email: '' }}
                            onSubmit={async (values) => {
                                //alert(JSON.stringify(values));
                                if (values) {
                                    await forgotPassword(values)
                                        .unwrap()
                                        .then((payload) => {
                                            const { status } = payload;
                                            if (status == 'success') {
                                                toast({
                                                    position: "top-right",
                                                    title: "Email sent",
                                                    status: "success",
                                                    duration: 4000,
                                                    isClosable: true,
                                                });
                                                setTimeout(() => {
                                                    navigate('/signin')
                                                }, 1000);
                                            }
                                        })
                                        .catch((error) => {
                                            toast({
                                                position: "top-right",
                                                title: "Error",
                                                status: "error",
                                                duration: 4000,
                                                isClosable: true,
                                            });
                                        })
                                }
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isValid
                            }) => (
                                <>
                                    <FormControl>
                                        <Input
                                            id="email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.email && errors.email ? true : false}
                                            fontSize='sm'
                                            ms='4px'
                                            borderRadius='5px'
                                            type='email'
                                            placeholder='Email ID'
                                            mb='24px'
                                            size='lg'
                                        />
                                        <Button
                                            type='submit'
                                            fontSize='16px'
                                            color='white'
                                            fontWeight='bold'
                                            w='100%'
                                            h='45'
                                            mb='24px'
                                            colorScheme="brand"
                                            _hover={{
                                                bg: 'brand.300'
                                            }}
                                            bg={'brand.200'}
                                            onClick={handleSubmit}
                                            isDisabled={!isValid}
                                        >
                                            Request Reset {forgotResponseIsLoading ? (<Spinner />) : null}
                                        </Button>
                                    </FormControl>
                                </>
                            )}
                        </Formik>
                    </>
                )}

            </Stack>
        </Flex>
    );
}