import { Box, Heading, Text } from '@chakra-ui/react';

function OfflinePage() {
    return (
        <Box textAlign="center" py={10} px={6} w={'100%'} h={'100vh'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Box>
                <Heading
                    display="inline-block"
                    as="h2"
                    size="4xl"
                    bgGradient="linear(to-r, orange.400, orange.600)"
                    backgroundClip="text">
                    OOPS!
                </Heading>
                <Text fontSize="28px" mt={3} mb={2}>
                    You are currently offline
                </Text>
                <Text color={'gray.500'} mb={6}>
                    Please check your internet connection and try again.
                </Text>

                {/* <Button
            colorScheme="orange"
            variant="solid"
            onClick={() => navigate('/')}
          >
            Retry
          </Button> */}
            </Box>
        </Box>
    )
}

export default OfflinePage