import { Navigate, Route, Routes } from "react-router-dom";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import AccountListForAdmin from "pages/AccountListForAdmin";
import AccountListForClient from "pages/AccountListForClient";
import ClientList from "pages/ClientList";
import ClientProfile from "pages/ClientProfile";
import ClientProfileForAdmin from "pages/ClientProfileForAdmin";
import CompaniesForAdmin from "pages/CompaniesForAdmin";
import CompaniesForClient from "pages/CompaniesForClient";
import CompanyPageForAdmin from "pages/CompanyPageForAdmin";
import CompanyPageForClient from "pages/CompanyPageForClient";
import EditCompanyForAdmin from "pages/EditCompanyForAdmin";
import EditCompanyForClient from "pages/EditCompanyForClient";
import Layout from "components/layout/Layout";
import NotFound from "pages/NotFound";
import RequestsForAdmin from "pages/RequestsForAdmin";
import RequestsForClient from "pages/RequestsForClient";
import RequireAuth from "utils/RequireAuth";
import SignIn from "pages/SignIn";
import SignUp from "pages/SignUp";
import TopUpsForClient from "pages/TopUpsForClient";
import VerifyCompanyForAdmin from "pages/VerifyCompanyForAdmin";
import { setUser } from "store/auth/authSlice";
import TopUpsForAdmin from "pages/TopUpsForAdmin";
import ForgotPassword from "pages/ForgotPassword";
import ResetPassword from "pages/ResetPassword";
import ChangePassword from "pages/ChangePassword";
import PrivacyPolicy from "pages/PrivacyPolicy";
import VerifyEmail from "pages/VerifyEmail";
import TermsConditions from "pages/TermsConditions";
import LandingPage from "pages/LandingPage";
import Dashboard from "pages/Dashboard";
import LogsForAdmin from "pages/LogsForAdmin";
import ClientWiseDashboard from "pages/ClientWiseDashboard";
import AdAccountWiseDashboard from "pages/AdAccountWiseDashboard";
import { Online, Offline } from 'react-detect-offline';
import OfflinePage from "pages/OfflinePage";

function App() {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth?.userInfo);

  useEffect(() => {
    const localUserInfo = JSON.parse(localStorage.getItem("forexUser"));
    if (localUserInfo) dispatch(setUser(localUserInfo));
  }, []);

  return (
    <>
      <Online>
        <Routes>
          <Route path='/' element={<Layout />}>

            {/* PUBLIC */}

            <Route path='signIn' element={<SignIn />} />
            <Route path='signUp' element={<SignUp />} />
            <Route path='/forgot-password-change' element={<ForgotPassword />} />
            <Route path='/reset-password' element={<ResetPassword />} />
            <Route path='/change-password' element={<ChangePassword />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/verify-email' element={<VerifyEmail />} />
            <Route path='/terms-conditions' element={<TermsConditions />} />
            <Route path='/home' element={<LandingPage />} />

            {/* PROTECTED */}

            {!userInfo && (
              <Route path='/' element={<Navigate to='/signIn' replace />} />
            )}

            {userInfo && (userInfo?.role == "admin" || userInfo?.role == "media" || userInfo?.role == "finance") && (
              <Route element={<RequireAuth allowedRoles={["admin"]} />}>
                <Route path='/' element={<Navigate to='/clients' replace />} />
                <Route path='/clients' element={<ClientList />} />
                <Route path='/clients/:id' element={<ClientProfileForAdmin />} />
                <Route path='/requests' element={<RequestsForAdmin />} />
                <Route path='/accounts' element={<AccountListForAdmin />} />
                <Route path='/companies' element={<CompaniesForAdmin />} />
                <Route path='/companies/:id' element={<CompanyPageForAdmin />} />
                <Route path='/companies/:id/verify' element={<VerifyCompanyForAdmin />} />
                <Route path='/companies/:id/edit' element={<EditCompanyForAdmin />} />
                <Route path='/topup' element={<TopUpsForAdmin />} />
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/dashboard/clients/:id' element={<ClientWiseDashboard />} />
                <Route path='/dashboard/ads-accounts/:id' element={<AdAccountWiseDashboard />} />
                <Route path='/logs' element={<LogsForAdmin />} />
              </Route>
            )}

            {userInfo && userInfo?.role == "client" && (
              <Route element={<RequireAuth allowedRoles={["client"]} />}>
                <Route path='/' element={<Navigate to='/companies' replace />} />
                <Route path='/profile' element={<ClientProfile />} />
                <Route path='/accounts' element={<AccountListForClient />} />
                <Route path='/requests' element={<RequestsForClient />} />
                <Route path='/companies' element={<CompaniesForClient />} />
                <Route path='/companies/:id' element={<CompanyPageForClient />} />
                <Route path='/companies/:id/edit' element={<EditCompanyForClient />} />
                <Route path='/topup' element={<TopUpsForClient />} />
              </Route>
            )}

            {/* CATCH ALL */}

            <Route path='*' element={<NotFound />} />
          </Route>
        </Routes>
      </Online>

      {/* OFFLINE */}

      <Offline>
        <Routes>
          <Route
            path='*'
            element={
              <OfflinePage />
            }
          />
        </Routes>
      </Offline>
    </>
  );
}

export default App;
