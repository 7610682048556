import { Badge, Box, Button, Link, Divider, Tooltip, Flex, Heading, Tab, TabList, TabPanel, TabPanels, Table, TableContainer, Tabs, Tbody, Td, Text, Tr } from "@chakra-ui/react";

import LightBox from "components/LightBox";
import SidebarWithHeader from "components/SidebarWithHeader";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCompanyQuery } from "store/companies/companiesApi";
import { hasDecimal } from "utils/functions";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";

function CompanyPageForAdmin() {
  const { id } = useParams();
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.auth?.userInfo?.role);

  /* QUERIES */

  const { data: companyData, error: getCompanyError, isLoading: isCompanyDataLoading, refetch } = useGetCompanyQuery(id);


  const {
    bank,
    legal,
    commission,
    nickName,
    companyName,
    companyType,
    correspondenceAddress,
    entity,
    ownerName,
    ownerPhone,
    registerAddress,
    representativeDesignation,
    representativeEmail,
    representativeName,
    representativePhone,
    serviceDescription,
    typeOfBussiness,
    websiteUrl,
    status
  } = companyData || {};


  const {
    panCard,
    gstCertificate,
    bussinessTradeLicense,
    additionalDocument,
    bankDetails,
    itDeclaration,
    msmeCertificate,
    registrationCertificate,
    selfDeclaration,
    vatCertificate,
    cancelledCheque,
    taxResidencyCertificate,
    formTenF,
    noPeCertificate,
    bankDeclaration,
    taxId,
    panNumber,
    gstNumber
  } = legal || {};

  useEffect(() => {
    refetch();
  }, [refetch])

  //console.log(legal && panNumber);

  const { accountName, accountNumber, bankName, accountAddress, branchName, ifscCode, bankingPartner, country, iban, swift, branchAddress } = bank || {};

  const { cc, cl } = commission || {};
  const totalComissionCCValue = Number(cc?.retroComission) + Number(cc?.firstRetroPartnerCommission) + Number(cc?.secondRetroPartnerCommission)
  const totalComissionCLValue = Number(cl?.retroComission) + Number(cl?.firstRetroPartnerCommission) + Number(cl?.secondRetroPartnerCommission)


  const bankList = bank?.map((item, index) => {

    const { _id, accountName, accountNumber, bankName, accountAddress, branchName, ifscCode, bankingPartner, country, iban, swift, branchAddress } = item || {};

    return (
      <TableContainer mb={'40px'} key={_id}>
        <Table variant='simple' size={"sm"}>
          <Tbody>
            {accountName ? (
              <Tr>
                <Td w={"240px"} color='gray.500'>
                  Account Name
                </Td>
                <Td>{accountName}</Td>
              </Tr>
            ) : null}
            {accountAddress ? (
              <Tr>
                <Td w={"240px"} color='gray.500'>
                  Account Address
                </Td>
                <Td>{accountAddress}</Td>
              </Tr>
            ) : null}

            {accountNumber ? (
              <Tr>
                <Td w={"240px"} color='gray.500'>
                  Account Number
                </Td>
                <Td>{accountNumber}</Td>
              </Tr>
            ) : null}


            {bankName ? (
              <Tr>
                <Td w={"240px"} color='gray.500'>
                  Bank Name
                </Td>
                <Td>{bankName}</Td>
              </Tr>
            ) : null}

            {bankingPartner ?
              (<Tr>
                <Td w={"240px"} color='gray.500'>
                  Banking Partner
                </Td>
                <Td>{bankingPartner}</Td>
              </Tr>) : null
            }
            {country ?
              (<Tr>
                <Td w={"240px"} color='gray.500'>
                  Country
                </Td>
                <Td>{country}</Td>
              </Tr>) : null
            }
            {iban && iban.value ?
              (<Tr>
                <Td w={"240px"} color='gray.500'>
                  IBAN
                </Td>
                <Td>{iban}</Td>
              </Tr>) : null
            }
            {swift && swift.value ?
              (<Tr>
                <Td w={"240px"} color='gray.500'>
                  Swift
                </Td>
                <Td>{swift}</Td>
              </Tr>) : null
            }

            {branchName ? (
              <Tr>
                <Td w={"240px"} color='gray.500'>
                  Branch Name
                </Td>
                <Td>{branchName}</Td>
              </Tr>
            ) : null}

            {branchAddress ? (
              <Tr>
                <Td w={"240px"} color='gray.500'>
                  Branch Address
                </Td>
                <Td>{branchAddress}</Td>
              </Tr>
            ) : null}

            {ifscCode ? (
              <Tr>
                <Td w={"240px"} color='gray.500'>
                  IFSC Code
                </Td>
                <Td>{ifscCode}</Td>
              </Tr>
            ) : null}

          </Tbody>
        </Table>
      </TableContainer>
    )

  })

  return (
    <SidebarWithHeader>
      <Flex mb={"20px"}>
        <Heading as='h1' size='xl'>
          Company Details
        </Heading>
      </Flex>
      <Flex mb={'20px'} gap={'10px'} align={'baseline'} justify={'space-between'}>
        <Flex gap={'10px'} align={'baseline'}>
          <Heading as={'h4'} size={'sm'}>{companyName}</Heading>
          <Box>
            <Badge fontSize={'10px'} colorScheme="gray"> {companyType} </Badge>
          </Box>
          <Box>
            <Badge fontSize={'10px'} colorScheme="gray"> {entity} </Badge>
          </Box>
        </Flex>

        <Flex gap={'10px'} align={'baseline'}>
          {userRole == 'media' || userRole == 'admin' ? (
            <Button size={'sm'} onClick={() => navigate(`/companies/${id}`)} colorScheme='orange'>View</Button>
          ) : null}

          {userRole == 'media' || userRole == 'admin' || userRole == 'finance' ? (
            <Button size={'sm'} onClick={() => navigate(`/companies/${id}/edit`)}>Edit</Button>
          ) : null}

          {userRole == 'media' || userRole == 'admin' ? (
            <>
              {!status ? (
                <Button size={'sm'} onClick={() => navigate(`/companies/${id}/verify`)}>Verify</Button>
              ) : null}
            </>
          ) : null}


        </Flex>


      </Flex>
      <Flex >
        <Tabs
          position='relative'
          variant='unstyled'
          sx={{
            display: "flex",
            position: "relative",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <TabList gap={"10px"}>
            <Tab borderWidth={1} borderRadius={5} _selected={{ color: "white", bg: "brand.200" }}>
              General
            </Tab>
            <Tab borderWidth={1} borderRadius={5} _selected={{ color: "white", bg: "brand.200" }}>
              KYC
            </Tab>
            <Tab borderWidth={1} borderRadius={5} _selected={{ color: "white", bg: "brand.200" }}>
              Bank
            </Tab>
            <Tab borderWidth={1} borderRadius={5} _selected={{ color: "white", bg: "brand.200" }}>
              Commission
            </Tab>
            {/* <Tab borderWidth={1} borderRadius={5} _selected={{ color: "white", bg: "brand.200" }}>
              Contract
            </Tab> */}
          </TabList>
          <Divider mt={4} />
          <TabPanels py={"40px"}>
            <TabPanel p={0}>
              <TableContainer>
                <Table variant='simple' size={"sm"}>
                  <Tbody>
                    <Tr>
                      <Td w={"240px"} color='gray.500'>
                        Company Name
                      </Td>
                      <Td>{companyName}</Td>
                    </Tr>
                    <Tr>
                      <Td w={"240px"} color='gray.500'>
                        Nick Name
                      </Td>
                      <Td>{nickName}</Td>
                    </Tr>
                    <Tr>
                      <Td w={"240px"} color='gray.500'>
                        Company Type
                      </Td>
                      <Td>{companyType && companyType}</Td>
                    </Tr>
                    <Tr>
                      <Td w={"240px"} color='gray.500'>
                        Entity
                      </Td>
                      <Td>{entity}</Td>
                    </Tr>
                    <Tr>
                      <Td w={"240px"} color='gray.500'>
                        Type of Bussiness
                      </Td>
                      <Td>{typeOfBussiness && typeOfBussiness}</Td>
                    </Tr>
                    <Tr>
                      <Td w={"240px"} color='gray.500'>
                        Registered Address
                      </Td>
                      <Td>{registerAddress && registerAddress}</Td>
                    </Tr>
                    <Tr>
                      <Td w={"240px"} color='gray.500'>
                        Correspondence Address
                      </Td>
                      <Td>{correspondenceAddress && correspondenceAddress}</Td>
                    </Tr>
                    <Tr>
                      <Td w={"240px"} color='gray.500'>
                        Representative Name
                      </Td>
                      <Td>{representativeName && representativeName}</Td>
                    </Tr>
                    <Tr>
                      <Td w={"240px"} color='gray.500'>
                        Representative Email ID
                      </Td>
                      <Td>{representativeEmail && representativeEmail}</Td>
                    </Tr>
                    <Tr>
                      <Td w={"240px"} color='gray.500'>
                        Representative Phone
                      </Td>
                      <Td>{representativePhone && representativePhone}</Td>
                    </Tr>
                    <Tr>
                      <Td w={"240px"} color='gray.500'>
                        Representative Designation
                      </Td>
                      <Td>{representativeDesignation && representativeDesignation}</Td>
                    </Tr>
                    <Tr>
                      <Td w={"240px"} color='gray.500'>
                        Authorised Signatory Name
                      </Td>
                      <Td>{ownerName && ownerName}</Td>
                    </Tr>
                    <Tr>
                      <Td w={"240px"} color='gray.500'>
                        Authorised Signatory Phone
                      </Td>
                      <Td>{ownerPhone && ownerPhone}</Td>
                    </Tr>
                    <Tr>
                      <Td w={"240px"} color='gray.500'>
                        Details on Services
                      </Td>
                      <Td>{serviceDescription && serviceDescription}</Td>
                    </Tr>
                    <Tr>
                      <Td w={"240px"} color='gray.500'>
                        Website URL
                      </Td>
                      <Td>{websiteUrl && websiteUrl}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel p={0}>
              <>
                {legal && Object.keys(legal).length > 0 ? (
                  <TableContainer>
                    <Table variant='simple' size={"sm"}>
                      <Tbody>
                        {entity == 'indian' ? (
                          <>
                            {gstCertificate && (
                              <Tr>
                                <Td w={"180px"} color='gray.500'>
                                  GST Certificate
                                </Td>
                                <Td>
                                  <LightBox fileSource={gstCertificate?.value} />
                                  <Link isExternal href={gstCertificate?.value} download>
                                    <Button variant="solid" size={'xs'}>
                                      Download
                                    </Button>
                                  </Link>
                                </Td>
                                <Td>
                                  {gstCertificate?.approved ? (
                                    <Badge size={"xs"} colorScheme='green'>
                                      Approved
                                    </Badge>
                                  ) : (
                                    <Badge size={"xs"} colorScheme='red'>
                                      Not Approved
                                    </Badge>
                                  )}
                                </Td>
                              </Tr>
                            )}
                            {bankDetails && (
                              <Tr>
                                <Td w={"180px"} color='gray.500'>
                                  Bank Details
                                </Td>
                                <Td>
                                  <LightBox fileSource={bankDetails?.value} />
                                  <Link isExternal href={bankDetails?.value} download>
                                    <Button variant="solid" size={'xs'}>
                                      Download
                                    </Button>
                                  </Link>
                                </Td>
                                <Td>
                                  {bankDetails.approved ? (
                                    <Badge size={"xs"} colorScheme='green'>
                                      Approved
                                    </Badge>
                                  ) : (
                                    <Badge size={"xs"} colorScheme='red'>
                                      Not Approved
                                    </Badge>
                                  )}
                                </Td>
                              </Tr>
                            )}

                            {bussinessTradeLicense && (
                              <Tr>
                                <Td w={"180px"} color='gray.500'>
                                  Bussiness Trade License
                                </Td>
                                <Td>
                                  <LightBox fileSource={bussinessTradeLicense?.value} />
                                  <Link isExternal href={bussinessTradeLicense?.value} download>
                                    <Button variant="solid" size={'xs'}>
                                      Download
                                    </Button>
                                  </Link>
                                </Td>
                                <Td>
                                  {bussinessTradeLicense.approved ? (
                                    <Badge size={"xs"} colorScheme='green'>
                                      Approved
                                    </Badge>
                                  ) : (
                                    <Badge size={"xs"} colorScheme='red'>
                                      Not Approved
                                    </Badge>
                                  )}
                                </Td>
                              </Tr>
                            )}

                            {itDeclaration && (
                              <Tr>
                                <Td w={"180px"} color='gray.500'>
                                  IT Declaration
                                </Td>
                                <Td>
                                  <LightBox fileSource={itDeclaration?.value} />
                                  <Link isExternal href={itDeclaration?.value} download>
                                    <Button variant="solid" size={'xs'}>
                                      Download
                                    </Button>
                                  </Link>
                                </Td>
                                <Td>
                                  {itDeclaration.approved ? (
                                    <Badge size={"xs"} colorScheme='green'>
                                      Approved
                                    </Badge>
                                  ) : (
                                    <Badge size={"xs"} colorScheme='red'>
                                      Not Approved
                                    </Badge>
                                  )}
                                </Td>
                              </Tr>
                            )}
                            {formTenF && (
                              <Tr>
                                <Td w={"180px"} color='gray.500'>
                                  IT Declaration
                                </Td>
                                <Td>
                                  <LightBox fileSource={formTenF?.value} />
                                  <Link isExternal href={formTenF?.value} download>
                                    <Button variant="solid" size={'xs'}>
                                      Download
                                    </Button>
                                  </Link>
                                </Td>
                                <Td>
                                  {formTenF.approved ? (
                                    <Badge size={"xs"} colorScheme='green'>
                                      Approved
                                    </Badge>
                                  ) : (
                                    <Badge size={"xs"} colorScheme='red'>
                                      Not Approved
                                    </Badge>
                                  )}
                                </Td>
                              </Tr>
                            )}
                            {vatCertificate && (
                              <Tr>
                                <Td w={"180px"} color='gray.500'>
                                  VAT Certificate
                                </Td>
                                <Td>
                                  <LightBox fileSource={vatCertificate?.value} />
                                  <Link isExternal href={vatCertificate?.value} download>
                                    <Button variant="solid" size={'xs'}>
                                      Download
                                    </Button>
                                  </Link>
                                </Td>
                                <Td>
                                  {vatCertificate.approved ? (
                                    <Badge size={"xs"} colorScheme='green'>
                                      Approved
                                    </Badge>
                                  ) : (
                                    <Badge size={"xs"} colorScheme='red'>
                                      Not Approved
                                    </Badge>
                                  )}
                                </Td>
                              </Tr>
                            )}
                            {selfDeclaration && (
                              <Tr>
                                <Td w={"180px"} color='gray.500'>
                                  Self Declaration
                                </Td>
                                <Td>
                                  <LightBox fileSource={selfDeclaration?.value} />
                                  <Link isExternal href={selfDeclaration?.value} download>
                                    <Button variant="solid" size={'xs'}>
                                      Download
                                    </Button>
                                  </Link>
                                </Td>
                                <Td>
                                  {selfDeclaration.approved ? (
                                    <Badge size={"xs"} colorScheme='green'>
                                      Approved
                                    </Badge>
                                  ) : (
                                    <Badge size={"xs"} colorScheme='red'>
                                      Not Approved
                                    </Badge>
                                  )}
                                </Td>
                              </Tr>
                            )}
                            {registrationCertificate && (
                              <Tr>
                                <Td w={"180px"} color='gray.500'>
                                  Registration Certificate
                                </Td>
                                <Td>
                                  <LightBox fileSource={registrationCertificate?.value} />
                                  <Link isExternal href={registrationCertificate?.value} download>
                                    <Button variant="solid" size={'xs'}>
                                      Download
                                    </Button>
                                  </Link>
                                </Td>
                                <Td>
                                  {registrationCertificate.approved ? (
                                    <Badge size={"xs"} colorScheme='green'>
                                      Approved
                                    </Badge>
                                  ) : (
                                    <Badge size={"xs"} colorScheme='red'>
                                      Not Approved
                                    </Badge>
                                  )}
                                </Td>
                              </Tr>
                            )}

                            {additionalDocument && (
                              <Tr>
                                <Td w={"180px"} color='gray.500'>
                                  Additional Document
                                </Td>
                                <Td>
                                  <LightBox fileSource={additionalDocument?.value} />
                                  <Link isExternal href={additionalDocument?.value} download>
                                    <Button variant="solid" size={'xs'}>
                                      Download
                                    </Button>
                                  </Link>
                                </Td>
                                <Td>
                                  {additionalDocument.approved ? (
                                    <Badge size={"xs"} colorScheme='green'>
                                      Approved
                                    </Badge>
                                  ) : (
                                    <Badge size={"xs"} colorScheme='red'>
                                      Not Approved
                                    </Badge>
                                  )}
                                </Td>
                              </Tr>
                            )}
                            {panCard && (
                              <Tr>
                                <Td w={"180px"} color='gray.500'>
                                  PAN Card
                                </Td>
                                <Td>
                                  <LightBox fileSource={panCard?.value} />
                                  <Link isExternal href={panCard?.value} download>
                                    <Button variant="solid" size={'xs'}>
                                      Download
                                    </Button>
                                  </Link>
                                </Td>
                                <Td>
                                  {panCard.approved ? (
                                    <Badge size={"xs"} colorScheme='green'>
                                      Approved
                                    </Badge>
                                  ) : (
                                    <Badge size={"xs"} colorScheme='red'>
                                      Not Approved
                                    </Badge>
                                  )}
                                </Td>
                              </Tr>
                            )}
                            {cancelledCheque && (
                              <Tr>
                                <Td w={"180px"} color='gray.500'>
                                  Cancelled Cheque
                                </Td>
                                <Td>
                                  <LightBox fileSource={cancelledCheque?.value} />
                                  <Link isExternal href={cancelledCheque?.value} download>
                                    <Button variant="solid" size={'xs'}>
                                      Download
                                    </Button>
                                  </Link>
                                </Td>
                                <Td>
                                  {cancelledCheque.approved ? (
                                    <Badge size={"xs"} colorScheme='green'>
                                      Approved
                                    </Badge>
                                  ) : (
                                    <Badge size={"xs"} colorScheme='red'>
                                      Not Approved
                                    </Badge>
                                  )}
                                </Td>
                              </Tr>
                            )}
                            {msmeCertificate && (
                              <Tr>
                                <Td w={"180px"} color='gray.500'>
                                  MSME Certificate
                                </Td>
                                <Td>
                                  <LightBox fileSource={msmeCertificate?.value} />
                                  <Link isExternal href={msmeCertificate?.value} download>
                                    <Button variant="solid" size={'xs'}>
                                      Download
                                    </Button>
                                  </Link>
                                </Td>
                                <Td>
                                  {msmeCertificate.approved ? (
                                    <Badge size={"xs"} colorScheme='green'>
                                      Approved
                                    </Badge>
                                  ) : (
                                    <Badge size={"xs"} colorScheme='red'>
                                      Not Approved
                                    </Badge>
                                  )}
                                </Td>
                              </Tr>
                            )}

                            {gstNumber ? (
                              <Tr>
                                <Td w={"180px"} color='gray.500'>
                                  GST Number
                                </Td>
                                <Td>
                                  {gstNumber.value}
                                </Td>
                                <Td>
                                  {gstNumber?.approved ? (
                                    <Badge size={"xs"} colorScheme='green'>
                                      Approved
                                    </Badge>
                                  ) : (
                                    <Badge size={"xs"} colorScheme='red'>
                                      Not Approved
                                    </Badge>
                                  )}
                                </Td>
                              </Tr>
                            ) : null}

                            {panNumber ? (
                              <Tr>
                                <Td w={"180px"} color='gray.500'>
                                  PAN Number
                                </Td>
                                <Td>
                                  {panNumber.value}
                                </Td>
                                <Td>
                                  {panNumber?.approved ? (
                                    <Badge size={"xs"} colorScheme='green'>
                                      Approved
                                    </Badge>
                                  ) : (
                                    <Badge size={"xs"} colorScheme='red'>
                                      Not Approved
                                    </Badge>
                                  )}
                                </Td>
                              </Tr>
                            ) : null}


                          </>
                        ) : <>
                          {taxResidencyCertificate ? (
                            <Tr>
                              <Td w={"180px"} color='gray.500'>
                                Tax Residency Certificate
                              </Td>
                              <Td>
                                <LightBox fileSource={taxResidencyCertificate?.value} />
                                <Link isExternal href={taxResidencyCertificate?.value} download>
                                  <Button variant="solid" size={'xs'}>
                                    Download
                                  </Button>
                                </Link>
                              </Td>
                              <Td>
                                {taxResidencyCertificate?.approved ? (
                                  <Badge size={"xs"} colorScheme='green'>
                                    Approved
                                  </Badge>
                                ) : (
                                  <Badge size={"xs"} colorScheme='red'>
                                    Not Approved
                                  </Badge>
                                )}
                              </Td>
                            </Tr>
                          ) : null}

                          {registrationCertificate ? (
                            <Tr>
                              <Td w={"180px"} color='gray.500'>
                                Registration Certificate
                              </Td>
                              <Td>
                                <LightBox fileSource={registrationCertificate?.value} />
                                <Link isExternal href={registrationCertificate?.value} download>
                                  <Button variant="solid" size={'xs'}>
                                    Download
                                  </Button>
                                </Link>
                              </Td>
                              <Td>
                                {registrationCertificate?.approved ? (
                                  <Badge size={"xs"} colorScheme='green'>
                                    Approved
                                  </Badge>
                                ) : (
                                  <Badge size={"xs"} colorScheme='red'>
                                    Not Approved
                                  </Badge>
                                )}
                              </Td>
                            </Tr>
                          ) : null}

                          {noPeCertificate ? (
                            <Tr>
                              <Td w={"180px"} color='gray.500'>
                                No PE Certificate
                              </Td>
                              <Td>
                                <LightBox fileSource={noPeCertificate?.value} />
                                <Link isExternal href={noPeCertificate?.value} download>
                                  <Button variant="solid" size={'xs'}>
                                    Download
                                  </Button>
                                </Link>
                              </Td>
                              <Td>
                                {noPeCertificate?.approved ? (
                                  <Badge size={"xs"} colorScheme='green'>
                                    Approved
                                  </Badge>
                                ) : (
                                  <Badge size={"xs"} colorScheme='red'>
                                    Not Approved
                                  </Badge>
                                )}
                              </Td>
                            </Tr>
                          ) : null}

                          {formTenF ? (
                            <Tr>
                              <Td w={"180px"} color='gray.500'>
                                Form 10F
                              </Td>
                              <Td>
                                <LightBox fileSource={formTenF?.value} />
                                <Link isExternal href={formTenF?.value} download>
                                  <Button variant="solid" size={'xs'}>
                                    Download
                                  </Button>
                                </Link>
                              </Td>
                              <Td>
                                {formTenF?.approved ? (
                                  <Badge size={"xs"} colorScheme='green'>
                                    Approved
                                  </Badge>
                                ) : (
                                  <Badge size={"xs"} colorScheme='red'>
                                    Not Approved
                                  </Badge>
                                )}
                              </Td>
                            </Tr>
                          ) : null}

                          {bankDeclaration ? (
                            <Tr>
                              <Td w={"180px"} color='gray.500'>
                                Bank Declaration
                              </Td>
                              <Td>
                                <LightBox fileSource={bankDeclaration?.value} />
                                <Link isExternal href={bankDeclaration?.value} download>
                                  <Button variant="solid" size={'xs'}>
                                    Download
                                  </Button>
                                </Link>
                              </Td>
                              <Td>
                                {bankDeclaration?.approved ? (
                                  <Badge size={"xs"} colorScheme='green'>
                                    Approved
                                  </Badge>
                                ) : (
                                  <Badge size={"xs"} colorScheme='red'>
                                    Not Approved
                                  </Badge>
                                )}
                              </Td>
                            </Tr>
                          ) : null}

                          {additionalDocument ? (
                            <Tr>
                              <Td w={"180px"} color='gray.500'>
                                Additional Document
                              </Td>
                              <Td>
                                <LightBox fileSource={additionalDocument?.value} />
                                <Link isExternal href={additionalDocument?.value} download>
                                  <Button variant="solid" size={'xs'}>
                                    Download
                                  </Button>
                                </Link>
                              </Td>
                              <Td>
                                {additionalDocument?.approved ? (
                                  <Badge size={"xs"} colorScheme='green'>
                                    Approved
                                  </Badge>
                                ) : (
                                  <Badge size={"xs"} colorScheme='red'>
                                    Not Approved
                                  </Badge>
                                )}
                              </Td>
                            </Tr>
                          ) : null}

                          {taxId ? (
                            <Tr>
                              <Td w={"180px"} color='gray.500'>
                                Tax ID
                              </Td>
                              <Td>
                                {taxId.value}
                              </Td>
                              <Td>
                                {taxId?.approved ? (
                                  <Badge size={"xs"} colorScheme='green'>
                                    Approved
                                  </Badge>
                                ) : (
                                  <Badge size={"xs"} colorScheme='red'>
                                    Not Approved
                                  </Badge>
                                )}
                              </Td>
                            </Tr>
                          ) : null}


                        </>}


                      </Tbody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Text>No data available</Text>
                )}
              </>
            </TabPanel>
            <TabPanel p={0}>
              {bank?.length == 0 ? (
                <Text>No data available</Text>
              ) : null}
              {bankList}
            </TabPanel>
            <TabPanel p={0}>
              {commission?.cc && Object.keys(cc).length > 0 ? (
                <Box mb={'40px'}>
                  <Text fontWeight={'bold'}>Credit Card</Text>
                  <TableContainer my={'10px'}>
                    <Table variant='simple' size={"sm"}>
                      <Tbody>

                        <Tr>
                          <Td w={"240px"} color='gray.500'>
                            Blink's Commission
                          </Td>
                          <Td>{cc?.retroComission}</Td>
                        </Tr>

                        {cc?.firstRetroPartnerCommission ? (
                          <>
                            <Tr>
                              <Td w={"240px"} color='gray.500'>
                                Retro Partner 1 Name
                              </Td>
                              <Td>{cc?.firstRetroPartnerName}</Td>
                            </Tr>
                            <Tr>
                              <Td w={"240px"} color='gray.500'>
                                Retro Partner 1 Commission (%)
                              </Td>
                              <Td>{cc?.firstRetroPartnerCommission}</Td>
                            </Tr>
                          </>
                        )
                          : null}

                        {cc?.secondRetroPartnerCommission ? (
                          <>
                            <Tr>
                              <Td w={"240px"} color='gray.500'>
                                Retro Partner 2 Name
                              </Td>
                              <Td>{cc?.secondRetroPartnerName}</Td>
                            </Tr>
                            <Tr>
                              <Td w={"240px"} color='gray.500'>
                                Retro Partner 2 Commission (%)
                              </Td>
                              <Td>{cc?.secondRetroPartnerCommission}</Td>
                            </Tr>
                          </>
                        ) : null}



                        <Tr>
                          <Td w={"240px"} color='gray.500'>
                            Total Commission (%)
                          </Td>
                          <Td>
                            {hasDecimal(totalComissionCCValue) ? totalComissionCCValue.toFixed(2) : totalComissionCCValue}
                            <Tooltip
                              fontSize='md'
                              borderRadius={5}
                              label={
                                <Box fontSize={"sm"} p={"5px"}>
                                  <Text>Blink's Commission : {cc?.retroComission} %</Text>
                                  <Text>Retro Partner 1 Commission: {cc?.firstRetroPartnerCommission} %</Text>
                                  {cc?.secondRetroPartnerCommission ? (
                                    <Text>Retro Partner 2 Commission: {cc?.secondRetroPartnerCommission} %</Text>
                                  ) : null}
                                </Box>
                              }
                            >
                              <InfoOutlineIcon ml={2} mb={2} color={'brand.200'} />
                            </Tooltip>
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              ) : (
                null
              )}
              {commission?.cl && Object.keys(cl).length > 0 ? (
                <Box>
                  <Text fontWeight={'bold'} >Credit Line</Text>
                  <TableContainer my={'10px'}>
                    <Table variant='simple' size={"sm"}>
                      <Tbody>

                        <Tr>
                          <Td w={"240px"} color='gray.500'>
                            Blink's Commission
                          </Td>
                          <Td>{cl?.retroComission}</Td>
                        </Tr>

                        {
                          cl?.firstRetroPartnerCommission ? (
                            <>
                              <Tr>
                                <Td w={"240px"} color='gray.500'>
                                  Retro Partner 1 Name
                                </Td>
                                <Td>{cl?.firstRetroPartnerName}</Td>
                              </Tr>
                              <Tr>
                                <Td w={"240px"} color='gray.500'>
                                  Retro Partner 1 Commission (%)
                                </Td>
                                <Td>{cl?.firstRetroPartnerCommission}</Td>
                              </Tr>
                            </>
                          ) : null
                        }

                        {cl?.secondRetroPartnerCommission ? (
                          <>
                            <Tr>
                              <Td w={"240px"} color='gray.500'>
                                Retro Partner 2 Name
                              </Td>
                              <Td>{cl?.secondRetroPartnerName}</Td>
                            </Tr>
                            <Tr>
                              <Td w={"240px"} color='gray.500'>
                                Retro Partner 2 Commission (%)
                              </Td>
                              <Td>{cl?.secondRetroPartnerCommission}</Td>
                            </Tr>
                          </>
                        ) : null}
                        <Tr>
                          <Td w={"240px"} color='gray.500'>
                            Total Commission (%)
                          </Td>
                          <Td>
                            {hasDecimal(totalComissionCLValue) ? totalComissionCLValue.toFixed(2) : totalComissionCLValue}
                            <Tooltip
                              fontSize='md'
                              borderRadius={5}
                              label={
                                <Box fontSize={"sm"} p={"5px"}>
                                  <Text>Blink's Commission : {cl?.retroComission} %</Text>
                                  <Text>Retro Partner 1 Commission: {cl?.firstRetroPartnerCommission} %</Text>
                                  {cl?.secondRetroPartnerCommission ? (
                                    <Text>Retro Partner 2 Commission: {cl?.secondRetroPartnerCommission} %</Text>
                                  ) : null}
                                </Box>
                              }
                            >
                              <InfoOutlineIcon ml={2} mb={2} color={'brand.200'} />
                            </Tooltip>
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              ) : (
                null
              )}

              {!commission?.cc && !commission?.cl ? (
                <Text>No data available</Text>
              ) : null}

            </TabPanel>
            {/* <TabPanel p={0}>
              <Text>You'll be able to see contract details once approved.</Text>
            </TabPanel> */}
          </TabPanels>
        </Tabs>
      </Flex>
    </SidebarWithHeader>
  );
}

export default CompanyPageForAdmin;
