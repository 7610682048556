import { api } from "../api";

export const companiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addCommissionDetails: builder.mutation({
      query: ({ companyId, payload }) => ({
        url: `/companies/commission/${companyId}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Company", "Companies"],
    }),
  }),
});

export const { useAddCommissionDetailsMutation } = companiesApi;
