import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import {
    Avatar,
    Box,
    CloseButton,
    Drawer,
    DrawerContent,
    Flex,
    HStack,
    Icon,
    IconButton,
    Image,
    Link,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Text,
    VStack,
    useColorMode,
    useColorModeValue,
    useDisclosure
} from '@chakra-ui/react';
import LogoNavWhite from "assets/images/logo-main.png";
import LogoNav from "assets/images/logo_black.png";
import { useEffect } from 'react';
import {
    FiBriefcase,
    FiChevronDown,
    FiCreditCard,
    FiFacebook,
    FiHelpCircle,
    FiMenu,
    FiUser
} from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate, } from "react-router-dom";
import { useGetActiveAccountsQuery } from 'store/adAccounts/adAccountsApi';
import { logout } from 'store/auth/authSlice';
import { useGetAllCompaniesQuery } from 'store/companies/companiesApi';

const adminLinkItems = [
    // { name: 'Home', icon: FiHome, path: '' },
    { name: "Users", icon: FiUser, path: "clients" },
    { name: "Companies", icon: FiBriefcase, path: "companies" },
    { name: "Ad Accounts", icon: FiFacebook, path: "accounts" },
    { name: "Top Ups", icon: FiCreditCard, path: "topup" },
    { name: "Tickets", icon: FiHelpCircle, path: "requests" },
    { name: "Dashboard", icon: FiBriefcase, path: "dashboard" },
];

const clientLinkItems = [
    // { name: 'Home', icon: FiHome, path: '' },
    { name: "Companies", icon: FiBriefcase, path: "companies" },
    { name: "Ad Accounts", icon: FiFacebook, path: "accounts" },
    { name: "Top Ups", icon: FiCreditCard, path: "topup" },
    { name: "Tickets", icon: FiHelpCircle, path: "requests" },
];

export default function SidebarWithHeader({
    children,
}) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { colorMode, toggleColorMode } = useColorMode();
    const userInfo = useSelector(state => state.auth?.userInfo)

    const {
        refetch: refetchCompanies
    } = useGetAllCompaniesQuery('getCompanies', {
        skip: userInfo?.role != "client"
    });

    const {
        refetch: refetchAdaccounts
    } = useGetActiveAccountsQuery({ currentPage: 1, searchValue: '' }, {
        skip: userInfo?.role != "client"
    });

    useEffect(() => {
        if (userInfo?.role == 'client') {
            refetchCompanies();
            refetchAdaccounts();
        }
    }, [userInfo])



    return (
        <Box
            minH="100vh"
            bg={useColorModeValue('white', '#231f20')}
        >
            <SidebarContent
                onClose={() => onClose}
                display={{ base: 'none', md: 'block' }}
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent onClose={onClose} />
                </DrawerContent>
            </Drawer>
            {/* MOBILE NAV */}
            <MobileNav onOpen={onOpen} />
            <Box ml={{ base: 0, md: 60 }} p={{ base: '20px', md: '40px' }}>
                {children}
            </Box>
            <IconButton position={'fixed'} bottom={[0, '10px']} left={[0, '20px']} w={3} mx={'auto'} mt={8} mb={2} icon={colorMode == 'dark' ? <SunIcon /> : <MoonIcon />} onClick={toggleColorMode} />
        </Box>
    );
}


const SidebarContent = ({ onClose, ...rest }) => {
    const location = useLocation();
    const slug = location.pathname.replace(/\//g, "");
    const isOnboarded = useSelector((state) => state.auth?.isUserOnBoarded)
    const hasActiveAdAccount = useSelector((state) => state.auth?.hasActiveAdAccount)
    const userInfo = useSelector(state => state.auth?.userInfo)
    const token = useSelector((state) => state.auth?.userInfo?.token)
    //console.log(hasActiveAdAccount);
    const { colorMode, toggleColorMode } = useColorMode();

    return (
        <Box
            transition="0.3s ease"
            bg={useColorModeValue('white', '#231f20')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            {...rest}>
            <Flex h="20" alignItems="center" mx="8" mb={'20px'} justifyContent="space-between">
                <Link href='/'>
                    <Image w='200px' src={colorMode == 'dark' ? LogoNavWhite : LogoNav} alt='midas logo' />
                </Link>
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>
            {userInfo && (
                <>
                    {(userInfo?.role == "admin" || userInfo?.role == "media" || userInfo?.role == "finance") && adminLinkItems.map(({ name, icon, path }) => (
                        <NavItem key={name} icon={icon} active={path == slug} path={path}>
                            {name}
                        </NavItem>
                    ))}
                    {userInfo?.role == 'client' ? (
                        <>
                            {
                                isOnboarded ? (
                                    <>
                                        {hasActiveAdAccount ? (
                                            <>
                                                {clientLinkItems.map(({ name, icon, path }) => (
                                                    <NavItem key={name} icon={icon} active={path == slug} path={path}>
                                                        {name}
                                                    </NavItem>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                {clientLinkItems.filter(({ name }, index) => name != 'Top Ups').map(({ name, icon, path }) => (
                                                    <NavItem key={name} icon={icon} active={path == slug} path={path}>
                                                        {name}
                                                    </NavItem>
                                                ))}
                                            </>
                                        )}

                                    </>
                                ) : (
                                    <NavItem key={'companies'} icon={FiBriefcase} active={true} path={'companies'}>
                                        Companies
                                    </NavItem>
                                )

                            }
                        </>
                    ) : null}
                </>
            )}

        </Box>
    );
};


const NavItem = ({ icon, path, active, children, ...rest }) => {
    const hoverBackground = useColorModeValue('gray.50', 'gray.800')
    const hoverIconColor = useColorModeValue('gray.800', 'gray.50')
    return (
        <Link as={RouterLink} to={`/${path}`} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                bg={active ? 'brand.200' : ''}
                color={active ? 'white' : hoverIconColor}
                _hover={{
                    bg: !active ? hoverBackground : '',
                }}
                {...rest}>
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: !active ? hoverIconColor : '',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Link>
    );
};


const MobileNav = ({ onOpen, ...rest }) => {
    const navigate = useNavigate();
    const userInfo = useSelector(state => state?.auth?.userInfo)
    const dispatch = useDispatch();

    const { colorMode, toggleColorMode } = useColorMode();

    //console.log(userInfo);
    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', '#231f20')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent={{ base: 'space-between', md: 'flex-end' }}
            {...rest}>
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />

            <Link href='/' display={{ base: 'flex', md: 'none' }}>
                <Image w='200px' src={colorMode == 'dark' ? LogoNavWhite : LogoNav} alt='midas logo' />
            </Link>

            <HStack spacing={{ base: '0', md: '6' }}>
                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton
                            py={2}
                            transition="all 0.3s"
                            _focus={{ boxShadow: 'none' }}>
                            <HStack>
                                <Avatar
                                    name={`${userInfo?.firstName} ${userInfo?.lastName} `}
                                    size={'sm'}
                                />
                                <VStack
                                    display={{ base: 'none', md: 'flex' }}
                                    alignItems="flex-start"
                                    spacing="1px"
                                    ml="2">
                                    <Text fontSize="sm" textTransform={'capitalize'}>{userInfo?.firstName} {userInfo?.lastName}</Text>
                                    <Text fontSize="xs" color={useColorModeValue('gray.500', 'gray.400')} textTransform={'capitalize'}>
                                        {userInfo?.role}
                                    </Text>
                                </VStack>
                                <Box display={{ base: 'none', md: 'flex' }}>
                                    <FiChevronDown />
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList>
                            {userInfo?.role == 'admin' ? (
                                <MenuItem onClick={() => { navigate('/logs') }}>Logs</MenuItem>
                            ) : null}
                            <MenuItem onClick={() => { navigate('/change-password') }}>Change password</MenuItem>
                            <MenuDivider />
                            <MenuItem onClick={() => { dispatch(logout()); navigate('/signIn') }}>Sign out</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
        </Flex>
    );
};