import { Box, Button, Flex, FormControl, FormLabel, Icon, Input, Radio, RadioGroup, Select, Spacer, Stack, Text, Textarea } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { bankDetailsIndianFormSchema, bankDetailsNonIndianFormSchema } from "utils/schema";

import { useFormik } from "formik";

function BankDetailsForm({ entity, onBankDetailsFormSubmit, details }) {
  const { bankName, accountName, accountNumber, branchName, ifscCode, bankingPartner, accountAddress, branchAddress, country, iban, swift } = details || {};

  const [indianInitialValues, setindianInitialValues] = useState({})
  const [nonIndianInitialValues, setnonIndianInitialValues] = useState({})

  //console.log(details && details);


  const bankProviderOptions = ['Wise', 'Payoneer', 'Mercury']

  useEffect(() => {

    setindianInitialValues({
      accountName: accountName || "",
      accountNumber: accountNumber || "",
      branchName: branchName || "",
      bankName: bankName || "",
      ifscCode: ifscCode || "",
      branchAddress: branchAddress || "",
    })

    let otherBankingProviderOption = "";
    let bankProviderOption = "";
    if (bankingPartner) {
      bankProviderOption = bankProviderOptions.includes(bankingPartner) ? bankingPartner : "Others"
      otherBankingProviderOption = bankProviderOptions.includes(bankingPartner) ? " " : bankingPartner
    }
    setnonIndianInitialValues({
      bankingPartner: bankProviderOption || "",
      accountName: accountName || "",
      accountNumber: accountNumber || "",
      accountAddress: accountAddress || "",
      iban: iban || "",
      swift: swift || "",
      bankName: bankName || "",
      branchAddress: branchAddress || "",
      country: country || "",
      isUsingOnlineBankingPartner: bankingPartner ? true : false,
      otherOnlineBankingPartner: otherBankingProviderOption || "",
      accountType: iban ? "iban" : "swift" || "iban",
    })
  }, [details])



  const bankDetailsIndianForm = useFormik({
    initialValues: indianInitialValues,
    enableReinitialize: true,
    validationSchema: bankDetailsIndianFormSchema,
    onSubmit: (values) => {
      // console.log(JSON.stringify(values, null, 2));
      if (bankDetailsIndianForm.values == bankDetailsIndianForm.initialValues) {
        return;
      }
      onBankDetailsFormSubmit(values);
      bankDetailsIndianForm.resetForm();
    },
  });



  const bankDetailsNonIndianForm = useFormik({
    initialValues: nonIndianInitialValues,
    enableReinitialize: true,
    validationSchema: bankDetailsNonIndianFormSchema,
    onSubmit: (values) => {
      //console.log(JSON.stringify(values, null, 2));
      const { bankingPartner, accountName, accountNumber, otherOnlineBankingPartner, accountAddress, iban, swift, bankName, branchAddress, country } = values;
      const updatedBankingPartner = bankingPartner == "Others" ? otherOnlineBankingPartner : bankingPartner;

      if (bankDetailsNonIndianForm.values == bankDetailsNonIndianForm.initialValues) {
        return;
      }
      const payload = { bankingPartner: updatedBankingPartner, accountName, accountNumber, accountAddress, ...(iban && { iban }), ...(swift && { swift }), bankName, branchAddress, country }
      //console.log({ payload });
      onBankDetailsFormSubmit(payload);
      bankDetailsNonIndianForm.resetForm();
    },
  });



  return (
    <>
      <FormControl style={{ display: "flex", flexDirection: "column", gap: 10 }} maxW={["320px", "640px", "inherit"]} m={["0 auto", "auto"]}>
        {entity == "indian" && (
          <Flex flexDirection={"column"} gap={"20px"}>
            <Flex gap='20px'>
              <Box w={"full"}>
                <FormLabel fontSize={"sm"} mb='10px'>
                  Account Name <Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>

                </FormLabel>
                <Input
                  name='accountName'
                  placeholder={"Account Name"}
                  onChange={bankDetailsIndianForm.handleChange}
                  value={bankDetailsIndianForm?.values?.accountName}
                  isInvalid={bankDetailsIndianForm?.touched?.accountName && bankDetailsIndianForm?.errors?.accountName ? true : false}
                />
                {bankDetailsIndianForm?.touched?.accountName && bankDetailsIndianForm.errors.accountName ? (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {bankDetailsIndianForm?.errors?.accountName}
                  </Text>
                ) : null}
                {!!accountName && !accountName?.approved && accountName?.rejectReasons?.length > 0 ? (


                  <Flex gap={'5px'} mt={'5px'}>
                    <Icon
                      as={FiAlertCircle}
                      boxSize={4}
                      color={"red.600"}
                    />
                    <Box>
                      <Text display={'flex'} fontSize={"12px"} color={"red.600"}>
                        <Text fontWeight={'bold'} whiteSpace={'nowrap'}>Rejected : &nbsp;</Text> {accountName?.rejectReasons[accountName?.rejectReasons?.length - 1]?.notes}
                      </Text>
                    </Box>
                  </Flex>
                ) : null}
              </Box>
              <Box w={"full"}>
                <FormLabel fontSize={"sm"} mb='10px'>
                  Account Number <Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>
                </FormLabel>
                <Input
                  name='accountNumber'
                  placeholder={"Account Number"}
                  onChange={bankDetailsIndianForm.handleChange}
                  value={bankDetailsIndianForm.values.accountNumber}
                  isInvalid={bankDetailsIndianForm.touched.accountNumber && bankDetailsIndianForm.errors.accountNumber ? true : false}
                />
                {bankDetailsIndianForm.touched.accountNumber && bankDetailsIndianForm.errors.accountNumber && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {bankDetailsIndianForm.errors.accountNumber}
                  </Text>
                )}

                {!!accountNumber && !accountNumber?.approved && accountNumber?.rejectReasons?.length > 0 ? (


                  <Flex gap={'5px'} mt={'5px'}>
                    <Icon
                      as={FiAlertCircle}
                      boxSize={4}
                      color={"red.600"}
                    />
                    <Box>
                      <Text display={'flex'} fontSize={"12px"} color={"red.600"}>
                        <Text fontWeight={'bold'} whiteSpace={'nowrap'}>Rejected : &nbsp;</Text> {accountNumber?.rejectReasons[accountNumber?.rejectReasons?.length - 1]?.notes}
                      </Text>
                    </Box>
                  </Flex>
                ) : null}
              </Box>

            </Flex>

            <Flex gap='20px'>
              <Box w={"full"}>
                <FormLabel fontSize={"sm"} mb='10px'>
                  Bank Name <Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>
                </FormLabel>
                <Input
                  name='bankName'
                  placeholder={"Bank Name"}
                  onChange={bankDetailsIndianForm.handleChange}
                  value={bankDetailsIndianForm.values.bankName}
                  isInvalid={bankDetailsIndianForm.touched.bankName && bankDetailsIndianForm.errors.bankName ? true : false}
                />
                {bankDetailsIndianForm.touched.bankName && bankDetailsIndianForm.errors.bankName && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {bankDetailsIndianForm.errors.bankName}
                  </Text>
                )}
                {!!bankName && !bankName?.approved && bankName?.rejectReasons?.length > 0 ? (


                  <Flex gap={'5px'} mt={'5px'}>
                    <Icon
                      as={FiAlertCircle}
                      boxSize={4}
                      color={"red.600"}
                    />
                    <Box>
                      <Text display={'flex'} fontSize={"12px"} color={"red.600"}>
                        <Text fontWeight={'bold'} whiteSpace={'nowrap'}>Rejected : &nbsp;</Text> {bankName?.rejectReasons[bankName?.rejectReasons?.length - 1]?.reason}
                      </Text>
                      <Text display={'flex'} fontSize={"12px"} color={"red.600"}>
                        <span fontWeight={'bold'}>Notes : &nbsp;</span> {bankName?.rejectReasons[bankName?.rejectReasons?.length - 1]?.notes}
                      </Text>
                    </Box>
                  </Flex>
                ) : null}
              </Box>

              <Box w={"full"}>
                <FormLabel fontSize={"sm"} mb='10px'>
                  IFSC Code <Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>
                </FormLabel>
                <Input
                  name='ifscCode'
                  placeholder={"IFSC Code"}
                  onChange={bankDetailsIndianForm.handleChange}
                  value={bankDetailsIndianForm.values.ifscCode}
                  isInvalid={bankDetailsIndianForm.touched.ifscCode && bankDetailsIndianForm.errors.ifscCode ? true : false}
                />
                {bankDetailsIndianForm.touched.ifscCode && bankDetailsIndianForm.errors.ifscCode && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {bankDetailsIndianForm.errors.ifscCode}
                  </Text>
                )}
                {!!ifscCode && !ifscCode?.approved && ifscCode?.rejectReasons?.length > 0 ? (


                  <Flex gap={'5px'} mt={'5px'}>
                    <Icon
                      as={FiAlertCircle}
                      boxSize={4}
                      color={"red.600"}
                    />
                    <Box>
                      <Text display={'flex'} fontSize={"12px"} color={"red.600"}>
                        <Text fontWeight={'bold'} whiteSpace={'nowrap'}>Rejected : &nbsp;</Text> {ifscCode?.rejectReasons[ifscCode?.rejectReasons?.length - 1]?.notes}
                      </Text>
                    </Box>
                  </Flex>
                ) : null}
              </Box>
            </Flex>
            <Flex gap='20px'>
              <Box w={"full"}>
                <FormLabel fontSize={"sm"} mb='10px'>
                  Branch Name
                </FormLabel>
                <Input
                  name='branchName'
                  placeholder={"Branch Name "}
                  onChange={bankDetailsIndianForm.handleChange}
                  value={bankDetailsIndianForm.values.branchName}
                  isInvalid={bankDetailsIndianForm.touched.branchName && bankDetailsIndianForm.errors.branchName ? true : false}
                />
                {bankDetailsIndianForm.touched.branchName && bankDetailsIndianForm.errors.branchName && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {bankDetailsIndianForm.errors.branchName}
                  </Text>
                )}
                {!!branchName && !branchName?.approved && branchName?.rejectReasons?.length > 0 ? (

                  <Flex gap={'5px'} mt={'5px'}>
                    <Icon
                      as={FiAlertCircle}
                      boxSize={4}
                      color={"red.600"}
                    />
                    <Box>
                      <Text display={'flex'} fontSize={"12px"} color={"red.600"}>
                        <Text fontWeight={'bold'} whiteSpace={'nowrap'}>Rejected : &nbsp;</Text> {branchName?.rejectReasons[branchName?.rejectReasons?.length - 1]?.notes}
                      </Text>
                    </Box>
                  </Flex>
                ) : null}
              </Box>
            </Flex>
            <Box>
              <FormLabel fontSize={"sm"} mb='10px'>
                Bank Address <Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>
              </FormLabel>
              <Textarea
                name='branchAddress'
                placeholder={"Bank Address"}
                onChange={bankDetailsIndianForm.handleChange}
                value={bankDetailsIndianForm.values.branchAddress}
                isInvalid={bankDetailsIndianForm.touched.branchAddress && bankDetailsIndianForm.errors.branchAddress ? true : false}
              />
              {bankDetailsIndianForm.touched.branchAddress && bankDetailsIndianForm.errors.branchAddress && (
                <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                  {bankDetailsIndianForm.errors.branchAddress}
                </Text>
              )}
              {!!branchAddress && !branchAddress?.approved && branchAddress?.rejectReasons?.length > 0 ? (
                <Flex gap={'5px'} mt={'5px'}>
                  <Icon
                    as={FiAlertCircle}
                    boxSize={4}
                    color={"red.600"}
                  />
                  <Box>
                    <Text display={'flex'} fontSize={"12px"} color={"red.600"}>
                      <Text fontWeight={'bold'} whiteSpace={'nowrap'}>Rejected : &nbsp;</Text> {branchAddress?.rejectReasons[branchAddress?.rejectReasons?.length - 1]?.notes}
                    </Text>
                  </Box>
                </Flex>
              ) : null}
            </Box>
            <Button mt={4} mb={4} onClick={bankDetailsIndianForm.handleSubmit}>
              Save
            </Button>
          </Flex>
        )}

        {entity == "non-indian" && (
          <Flex flexDirection={"column"} gap={"20px"}>
            <Flex>
              <Box w={"full"}>
                <Box>
                  {/* <Checkbox
                    colorScheme='orange'
                    fontSize='sm'
                    name='isUsingOnlineBankingPartner'
                    isChecked={bankDetailsNonIndianForm.values.isUsingOnlineBankingPartner}
                    onChange={bankDetailsNonIndianForm.handleChange}
                  >
                    Are you using an online banking partner?
                  </Checkbox> */}
                  <FormLabel fontSize={"sm"} >
                    Bank/Online Banking Provider
                  </FormLabel>
                  {bankDetailsNonIndianForm.touched.isUsingOnlineBankingPartner && bankDetailsNonIndianForm.errors.isUsingOnlineBankingPartner && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                      {bankDetailsNonIndianForm.errors.isUsingOnlineBankingPartner}
                    </Text>
                  )}
                </Box>
                <Box>
                  <Spacer mt={"10px"} />
                  <Select
                    w={"100%"}
                    name='bankingPartner'
                    placeholder='Bank/Online Banking Provider'
                    onChange={bankDetailsNonIndianForm.handleChange}
                    value={bankDetailsNonIndianForm.values.bankingPartner}
                    isInvalid={bankDetailsNonIndianForm.touched.bankingPartner && bankDetailsNonIndianForm.errors.bankingPartner ? true : false}
                  >
                    <option value='Wise'>Wise</option>
                    <option value='Payoneer'>Payoneer</option>
                    <option value='Mercury'>Mercury</option>
                    <option value='Others'>Others </option>
                  </Select>
                  {bankDetailsNonIndianForm.touched.bankingPartner && bankDetailsNonIndianForm.errors.bankingPartner ? (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                      {bankDetailsNonIndianForm.errors.bankingPartner}
                    </Text>
                  ) : null}
                  {!!bankingPartner && !bankingPartner?.approved && bankingPartner?.rejectReasons?.length > 0 ? (

                    <Flex gap={'5px'} mt={'5px'}>
                      <Icon
                        as={FiAlertCircle}
                        boxSize={4}
                        color={"red.600"}
                      />
                      <Box>
                        <Text display={'flex'} fontSize={"12px"} color={"red.600"}>
                          <Text fontWeight={'bold'} whiteSpace={'nowrap'}>Rejected : &nbsp;</Text> {bankingPartner?.rejectReasons[bankingPartner?.rejectReasons?.length - 1]?.notes}
                        </Text>
                      </Box>
                    </Flex>
                  ) : null}

                  {bankDetailsNonIndianForm.values.bankingPartner == "Others" ? (
                    <Box>
                      <Spacer mt={"10px"} />
                      <FormLabel fontSize={"sm"} >
                        Other Online Banking Partner
                      </FormLabel>
                      <Textarea
                        name='otherOnlineBankingPartner'
                        placeholder='Please specify other online banking partner details'
                        onChange={bankDetailsNonIndianForm.handleChange}
                        onBlur={bankDetailsNonIndianForm.onBlur}
                        value={bankDetailsNonIndianForm.values.otherOnlineBankingPartner}
                        isInvalid={bankDetailsNonIndianForm.touched.otherOnlineBankingPartner && bankDetailsNonIndianForm.errors.otherOnlineBankingPartner ? true : false}
                      />
                      {bankDetailsNonIndianForm.touched.otherOnlineBankingPartner && bankDetailsNonIndianForm.errors.otherOnlineBankingPartner && (
                        <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                          {bankDetailsNonIndianForm.errors.otherOnlineBankingPartner}
                        </Text>
                      )}
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </Flex>
            <Flex gap='20px'>
              <Box w={"full"}>
                <FormLabel ms='4px' fontSize='sm'>
                  Beneficiary Name <Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>
                </FormLabel>
                <Input
                  name='accountName'
                  placeholder={"Beneficiary Name"}
                  onChange={bankDetailsNonIndianForm.handleChange}
                  onBlur={bankDetailsNonIndianForm.handleBlur}
                  value={bankDetailsNonIndianForm.values.accountName}
                  isInvalid={bankDetailsNonIndianForm.touched.accountName && bankDetailsNonIndianForm.errors.accountName ? true : false}
                />
                {bankDetailsNonIndianForm.touched.accountName && bankDetailsNonIndianForm.errors.accountName && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {bankDetailsNonIndianForm.errors.accountName}
                  </Text>
                )}
                {!!accountName && !accountName?.approved && accountName?.rejectReasons?.length > 0 ? (

                  <Flex gap={'5px'} mt={'5px'}>
                    <Icon
                      as={FiAlertCircle}
                      boxSize={4}
                      color={"red.600"}
                    />
                    <Box>
                      <Text display={'flex'} fontSize={"12px"} color={"red.600"}>
                        <Text fontWeight={'bold'} whiteSpace={'nowrap'}>Rejected : &nbsp;</Text> {accountName?.rejectReasons[accountName?.rejectReasons?.length - 1]?.notes}
                      </Text>
                    </Box>
                  </Flex>
                ) : null}
              </Box>
              <Box w={"full"}>
                <FormLabel ms='4px' fontSize='sm'>
                  Beneficiary Account Number <Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>
                </FormLabel>
                <Input
                  name='accountNumber'
                  placeholder={"Beneficiary Account Number"}
                  onChange={bankDetailsNonIndianForm.handleChange}
                  onBlur={bankDetailsNonIndianForm.handleBlur}
                  value={bankDetailsNonIndianForm.values.accountNumber}
                  isInvalid={bankDetailsNonIndianForm.touched.accountNumber && bankDetailsNonIndianForm.errors.accountNumber ? true : false}
                />
                {bankDetailsNonIndianForm.touched.accountNumber && bankDetailsNonIndianForm.errors.accountNumber && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {bankDetailsNonIndianForm.errors.accountNumber}
                  </Text>
                )}
                {!!accountNumber && !accountNumber?.approved && accountNumber?.rejectReasons?.length > 0 ? (


                  <Flex gap={'5px'} mt={'5px'}>
                    <Icon
                      as={FiAlertCircle}
                      boxSize={4}
                      color={"red.600"}
                    />
                    <Box>
                      <Text display={'flex'} fontSize={"12px"} color={"red.600"}>
                        <Text fontWeight={'bold'} whiteSpace={'nowrap'}>Rejected : &nbsp;</Text> {accountNumber?.rejectReasons[accountNumber?.rejectReasons?.length - 1]?.notes}
                      </Text>
                    </Box>
                  </Flex>
                ) : null}
              </Box>
            </Flex>
            <Box w={"full"}>
              <FormLabel ms='4px' fontSize='sm'>
                Beneficiary Address <Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>
              </FormLabel>
              <Textarea
                name='accountAddress'
                placeholder={"Beneficiary Address"}
                onChange={bankDetailsNonIndianForm.handleChange}
                onBlur={bankDetailsNonIndianForm.handleBlur}
                value={bankDetailsNonIndianForm.values.accountAddress}
                isInvalid={bankDetailsNonIndianForm.touched.accountAddress && bankDetailsNonIndianForm.errors.accountAddress ? true : false}
              />
              {bankDetailsNonIndianForm.touched.accountAddress && bankDetailsNonIndianForm.errors.accountAddress && (
                <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                  {bankDetailsNonIndianForm.errors.accountAddress}
                </Text>
              )}
              {!!accountAddress && !accountAddress?.approved && accountAddress?.rejectReasons?.length > 0 ? (

                <Flex gap={'5px'} mt={'5px'}>
                  <Icon
                    as={FiAlertCircle}
                    boxSize={4}
                    color={"red.600"}
                  />
                  <Box>
                    <Text display={'flex'} fontSize={"12px"} color={"red.600"}>
                      <Text fontWeight={'bold'} whiteSpace={'nowrap'}>Rejected : &nbsp;</Text> {accountAddress?.rejectReasons[accountAddress?.rejectReasons?.length - 1]?.notes}
                    </Text>
                  </Box>
                </Flex>
              ) : null}
            </Box>

            <Box>
              <FormLabel ms='4px' fontSize='sm'>
                International Account Number <Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>
              </FormLabel>
              <Flex gap='20px' alignItems={"center"}>

                <RadioGroup name='accountType' onChange={(val) => bankDetailsNonIndianForm.setFieldValue("accountType", val)} value={bankDetailsNonIndianForm.values.accountType}>
                  <Stack direction='row'>
                    <Radio value='iban'>IBAN</Radio>
                    <Radio value='swift'>SWIFT/BIC</Radio>
                  </Stack>
                </RadioGroup>

                {bankDetailsNonIndianForm.values.accountType == "iban" ? (
                  <Box w={"full"}>
                    <Input
                      name='iban'
                      placeholder={"IBAN"}
                      onChange={bankDetailsNonIndianForm.handleChange}
                      onBlur={bankDetailsNonIndianForm.handleBlur}
                      value={bankDetailsNonIndianForm.values.iban}
                      isInvalid={bankDetailsNonIndianForm.touched.iban && bankDetailsNonIndianForm.errors.iban ? true : false}
                    />
                    {bankDetailsNonIndianForm.touched.iban && bankDetailsNonIndianForm.errors.iban && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {bankDetailsNonIndianForm.errors.iban}
                      </Text>
                    )}
                    {!!iban && !iban?.approved && iban?.rejectReasons?.length > 0 ? (


                      <Flex gap={'5px'} mt={'5px'}>
                        <Icon
                          as={FiAlertCircle}
                          boxSize={4}
                          color={"red.600"}
                        />
                        <Box>
                          <Text display={'flex'} fontSize={"12px"} color={"red.600"}>
                            <Text fontWeight={'bold'} whiteSpace={'nowrap'}>Rejected : &nbsp;</Text> {iban?.rejectReasons[iban?.rejectReasons?.length - 1]?.notes}
                          </Text>
                        </Box>
                      </Flex>
                    ) : null}
                  </Box>
                ) : (
                  <Box w={"full"}>
                    <Input
                      name={"swift"}
                      placeholder='SWIFT/BIC'
                      onChange={bankDetailsNonIndianForm.handleChange}
                      onBlur={bankDetailsNonIndianForm.handleBlur}
                      value={bankDetailsNonIndianForm.values.swift}
                      isInvalid={bankDetailsNonIndianForm.touched.swift && bankDetailsNonIndianForm.errors.swift ? true : false}
                    />
                    {bankDetailsNonIndianForm.touched.swift && bankDetailsNonIndianForm.errors.swift && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {bankDetailsNonIndianForm.errors.swift}
                      </Text>
                    )}
                    {!!swift && !swift?.approved && swift?.rejectReasons?.length > 0 ? (


                      <Flex gap={'5px'} mt={'5px'}>
                        <Icon
                          as={FiAlertCircle}
                          boxSize={4}
                          color={"red.600"}
                        />
                        <Box>
                          <Text display={'flex'} fontSize={"12px"} color={"red.600"}>
                            <Text fontWeight={'bold'} whiteSpace={'nowrap'}>Rejected : &nbsp;</Text> {swift?.rejectReasons[swift?.rejectReasons?.length - 1]?.notes}
                          </Text>
                        </Box>
                      </Flex>
                    ) : null}
                  </Box>
                )}
              </Flex>
            </Box>

            <Flex gap='20px'>
              <Box w={"full"}>
                <FormLabel ms='4px' fontSize='sm'>
                  Bank Name <Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>
                </FormLabel>
                <Input
                  name='bankName'
                  placeholder={"Bank Name"}
                  onChange={bankDetailsNonIndianForm.handleChange}
                  onBlur={bankDetailsNonIndianForm.handleBlur}
                  value={bankDetailsNonIndianForm.values.bankName}
                  isInvalid={bankDetailsNonIndianForm.touched.bankName && bankDetailsNonIndianForm.errors.bankName ? true : false}
                />
                {bankDetailsNonIndianForm.touched.bankName && bankDetailsNonIndianForm.errors.bankName && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {bankDetailsNonIndianForm.errors.bankName}
                  </Text>
                )}
                {!!bankName && !bankName?.approved && bankName?.rejectReasons?.length > 0 ? (


                  <Flex gap={'5px'} mt={'5px'}>
                    <Icon
                      as={FiAlertCircle}
                      boxSize={4}
                      color={"red.600"}
                    />
                    <Box>
                      <Text display={'flex'} fontSize={"12px"} color={"red.600"}>
                        <Text fontWeight={'bold'} whiteSpace={'nowrap'}>Rejected : &nbsp;</Text> {bankName?.rejectReasons[bankName?.rejectReasons?.length - 1]?.notes}
                      </Text>
                    </Box>
                  </Flex>
                ) : null}
              </Box>
              <Box w={"full"}>
                <FormLabel ms='4px' fontSize='sm'>
                  Country <Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>
                </FormLabel>
                <Select
                  w={"100%"}
                  name='country'
                  placeholder={"Country"}
                  onChange={bankDetailsNonIndianForm.handleChange}
                  value={bankDetailsNonIndianForm.values.country}
                  isInvalid={bankDetailsNonIndianForm.touched.country && bankDetailsNonIndianForm.errors.country ? true : false}
                >
                  <option value='Afghanistan'>Afghanistan</option>
                  <option value='Albania'>Albania</option>
                  <option value='Algeria'>Algeria</option>
                  <option value='American Samoa'>American Samoa</option>
                  <option value='Andorra'>Andorra</option>
                  <option value='Angola'>Angola</option>
                  <option value='Anguilla'>Anguilla</option>
                  <option value='Antarctica'>Antarctica</option>
                  <option value='Antigua and Barbuda'>Antigua and Barbuda</option>
                  <option value='Argentina'>Argentina</option>
                  <option value='Armenia'>Armenia</option>
                  <option value='Aruba'>Aruba</option>
                  <option value='Australia'>Australia</option>
                  <option value='Austria'>Austria</option>
                  <option value='Azerbaijan'>Azerbaijan</option>
                  <option value='Bahamas'>Bahamas</option>
                  <option value='Bahrain'>Bahrain</option>
                  <option value='Bangladesh'>Bangladesh</option>
                  <option value='Barbados'>Barbados</option>
                  <option value='Belarus'>Belarus</option>
                  <option value='Belgium'>Belgium</option>
                  <option value='Belize'>Belize</option>
                  <option value='Benin'>Benin</option>
                  <option value='Bermuda'>Bermuda</option>
                  <option value='Bhutan'>Bhutan</option>
                  <option value='Bolivia'>Bolivia</option>
                  <option value='Bosnia and Herzegovina'>Bosnia and Herzegovina</option>
                  <option value='Botswana'>Botswana</option>
                  <option value='Bouvet Island'>Bouvet Island</option>
                  <option value='Brazil'>Brazil</option>
                  <option value='British Indian Ocean Territory'>British Indian Ocean Territory</option>
                  <option value='Brunei Darussalam'>Brunei Darussalam</option>
                  <option value='Bulgaria'>Bulgaria</option>
                  <option value='Burkina Faso'>Burkina Faso</option>
                  <option value='Burundi'>Burundi</option>
                  <option value='Cambodia'>Cambodia</option>
                  <option value='Cameroon'>Cameroon</option>
                  <option value='Canada'>Canada</option>
                  <option value='Cape Verde'>Cape Verde</option>
                  <option value='Cayman Islands'>Cayman Islands</option>
                  <option value='Central African Republic'>Central African Republic</option>
                  <option value='Chad'>Chad</option>
                  <option value='Chile'>Chile</option>
                  <option value='China'>China</option>
                  <option value='Christmas Island'>Christmas Island</option>
                  <option value='Cocos (Keeling) Islands'>Cocos (Keeling) Islands</option>
                  <option value='Colombia'>Colombia</option>
                  <option value='Comoros'>Comoros</option>
                  <option value='Congo'>Congo</option>
                  <option value='Congo, The Democratic Republic of The'>Congo, The Democratic Republic of The</option>
                  <option value='Cook Islands'>Cook Islands</option>
                  <option value='Costa Rica'>Costa Rica</option>
                  <option value="Cote D'ivoire">Cote D'ivoire</option>
                  <option value='Croatia'>Croatia</option>
                  <option value='Cuba'>Cuba</option>
                  <option value='Cyprus'>Cyprus</option>
                  <option value='Czech Republic'>Czech Republic</option>
                  <option value='Denmark'>Denmark</option>
                  <option value='Djibouti'>Djibouti</option>
                  <option value='Dominica'>Dominica</option>
                  <option value='Dominican Republic'>Dominican Republic</option>
                  <option value='Ecuador'>Ecuador</option>
                  <option value='Egypt'>Egypt</option>
                  <option value='El Salvador'>El Salvador</option>
                  <option value='Equatorial Guinea'>Equatorial Guinea</option>
                  <option value='Eritrea'>Eritrea</option>
                  <option value='Estonia'>Estonia</option>
                  <option value='Ethiopia'>Ethiopia</option>
                  <option value='Falkland Islands (Malvinas)'>Falkland Islands (Malvinas)</option>
                  <option value='Faroe Islands'>Faroe Islands</option>
                  <option value='Fiji'>Fiji</option>
                  <option value='Finland'>Finland</option>
                  <option value='France'>France</option>
                  <option value='French Guiana'>French Guiana</option>
                  <option value='French Polynesia'>French Polynesia</option>
                  <option value='French Southern Territories'>French Southern Territories</option>
                  <option value='Gabon'>Gabon</option>
                  <option value='Gambia'>Gambia</option>
                  <option value='Georgia'>Georgia</option>
                  <option value='Germany'>Germany</option>
                  <option value='Ghana'>Ghana</option>
                  <option value='Gibraltar'>Gibraltar</option>
                  <option value='Greece'>Greece</option>
                  <option value='Greenland'>Greenland</option>
                  <option value='Grenada'>Grenada</option>
                  <option value='Guadeloupe'>Guadeloupe</option>
                  <option value='Guam'>Guam</option>
                  <option value='Guatemala'>Guatemala</option>
                  <option value='Guinea'>Guinea</option>
                  <option value='Guinea-bissau'>Guinea-bissau</option>
                  <option value='Guyana'>Guyana</option>
                  <option value='Haiti'>Haiti</option>
                  <option value='Heard Island and Mcdonald Islands'>Heard Island and Mcdonald Islands</option>
                  <option value='Holy See (Vatican City State)'>Holy See (Vatican City State)</option>
                  <option value='Honduras'>Honduras</option>
                  <option value='Hong Kong'>Hong Kong</option>
                  <option value='Hungary'>Hungary</option>
                  <option value='Iceland'>Iceland</option>
                  <option value='India'>India</option>
                  <option value='Indonesia'>Indonesia</option>
                  <option value='Iran, Islamic Republic of'>Iran, Islamic Republic of</option>
                  <option value='Iraq'>Iraq</option>
                  <option value='Ireland'>Ireland</option>
                  <option value='Israel'>Israel</option>
                  <option value='Italy'>Italy</option>
                  <option value='Jamaica'>Jamaica</option>
                  <option value='Japan'>Japan</option>
                  <option value='Jordan'>Jordan</option>
                  <option value='Kazakhstan'>Kazakhstan</option>
                  <option value='Kenya'>Kenya</option>
                  <option value='Kiribati'>Kiribati</option>
                  <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                  <option value='Korea, Republic of'>Korea, Republic of</option>
                  <option value='Kuwait'>Kuwait</option>
                  <option value='Kyrgyzstan'>Kyrgyzstan</option>
                  <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                  <option value='Latvia'>Latvia</option>
                  <option value='Lebanon'>Lebanon</option>
                  <option value='Lesotho'>Lesotho</option>
                  <option value='Liberia'>Liberia</option>
                  <option value='Libyan Arab Jamahiriya'>Libyan Arab Jamahiriya</option>
                  <option value='Liechtenstein'>Liechtenstein</option>
                  <option value='Lithuania'>Lithuania</option>
                  <option value='Luxembourg'>Luxembourg</option>
                  <option value='Macao'>Macao</option>
                  <option value='North Macedonia'>North Macedonia</option>
                  <option value='Madagascar'>Madagascar</option>
                  <option value='Malawi'>Malawi</option>
                  <option value='Malaysia'>Malaysia</option>
                  <option value='Maldives'>Maldives</option>
                  <option value='Mali'>Mali</option>
                  <option value='Malta'>Malta</option>
                  <option value='Marshall Islands'>Marshall Islands</option>
                  <option value='Martinique'>Martinique</option>
                  <option value='Mauritania'>Mauritania</option>
                  <option value='Mauritius'>Mauritius</option>
                  <option value='Mayotte'>Mayotte</option>
                  <option value='Mexico'>Mexico</option>
                  <option value='Micronesia, Federated States of'>Micronesia, Federated States of</option>
                  <option value='Moldova, Republic of'>Moldova, Republic of</option>
                  <option value='Monaco'>Monaco</option>
                  <option value='Mongolia'>Mongolia</option>
                  <option value='Montserrat'>Montserrat</option>
                  <option value='Morocco'>Morocco</option>
                  <option value='Mozambique'>Mozambique</option>
                  <option value='Myanmar'>Myanmar</option>
                  <option value='Namibia'>Namibia</option>
                  <option value='Nauru'>Nauru</option>
                  <option value='Nepal'>Nepal</option>
                  <option value='Netherlands'>Netherlands</option>
                  <option value='Netherlands Antilles'>Netherlands Antilles</option>
                  <option value='New Caledonia'>New Caledonia</option>
                  <option value='New Zealand'>New Zealand</option>
                  <option value='Nicaragua'>Nicaragua</option>
                  <option value='Niger'>Niger</option>
                  <option value='Nigeria'>Nigeria</option>
                  <option value='Niue'>Niue</option>
                  <option value='Norfolk Island'>Norfolk Island</option>
                  <option value='Northern Mariana Islands'>Northern Mariana Islands</option>
                  <option value='Norway'>Norway</option>
                  <option value='Oman'>Oman</option>
                  <option value='Pakistan'>Pakistan</option>
                  <option value='Palau'>Palau</option>
                  <option value='Palestinian Territory, Occupied'>Palestinian Territory, Occupied</option>
                  <option value='Panama'>Panama</option>
                  <option value='Papua New Guinea'>Papua New Guinea</option>
                  <option value='Paraguay'>Paraguay</option>
                  <option value='Peru'>Peru</option>
                  <option value='Philippines'>Philippines</option>
                  <option value='Pitcairn'>Pitcairn</option>
                  <option value='Poland'>Poland</option>
                  <option value='Portugal'>Portugal</option>
                  <option value='Puerto Rico'>Puerto Rico</option>
                  <option value='Qatar'>Qatar</option>
                  <option value='Reunion'>Reunion</option>
                  <option value='Romania'>Romania</option>
                  <option value='Russian Federation'>Russian Federation</option>
                  <option value='Rwanda'>Rwanda</option>
                  <option value='Saint Helena'>Saint Helena</option>
                  <option value='Saint Kitts and Nevis'>Saint Kitts and Nevis</option>
                  <option value='Saint Lucia'>Saint Lucia</option>
                  <option value='Saint Pierre and Miquelon'>Saint Pierre and Miquelon</option>
                  <option value='Saint Vincent and The Grenadines'>Saint Vincent and The Grenadines</option>
                  <option value='Samoa'>Samoa</option>
                  <option value='San Marino'>San Marino</option>
                  <option value='Sao Tome and Principe'>Sao Tome and Principe</option>
                  <option value='Saudi Arabia'>Saudi Arabia</option>
                  <option value='Senegal'>Senegal</option>
                  <option value='Serbia and Montenegro'>Serbia and Montenegro</option>
                  <option value='Seychelles'>Seychelles</option>
                  <option value='Sierra Leone'>Sierra Leone</option>
                  <option value='Singapore'>Singapore</option>
                  <option value='Slovakia'>Slovakia</option>
                  <option value='Slovenia'>Slovenia</option>
                  <option value='Solomon Islands'>Solomon Islands</option>
                  <option value='Somalia'>Somalia</option>
                  <option value='South Africa'>South Africa</option>
                  <option value='South Georgia and The South Sandwich Islands'>South Georgia and The South Sandwich Islands</option>
                  <option value='Spain'>Spain</option>
                  <option value='Sri Lanka'>Sri Lanka</option>
                  <option value='Sudan'>Sudan</option>
                  <option value='Suriname'>Suriname</option>
                  <option value='Svalbard and Jan Mayen'>Svalbard and Jan Mayen</option>
                  <option value='Swaziland'>Swaziland</option>
                  <option value='Sweden'>Sweden</option>
                  <option value='Switzerland'>Switzerland</option>
                  <option value='Syrian Arab Republic'>Syrian Arab Republic</option>
                  <option value='Taiwan, Province of China'>Taiwan, Province of China</option>
                  <option value='Tajikistan'>Tajikistan</option>
                  <option value='Tanzania, United Republic of'>Tanzania, United Republic of</option>
                  <option value='Thailand'>Thailand</option>
                  <option value='Timor-leste'>Timor-leste</option>
                  <option value='Togo'>Togo</option>
                  <option value='Tokelau'>Tokelau</option>
                  <option value='Tonga'>Tonga</option>
                  <option value='Trinidad and Tobago'>Trinidad and Tobago</option>
                  <option value='Tunisia'>Tunisia</option>
                  <option value='Turkey'>Turkey</option>
                  <option value='Turkmenistan'>Turkmenistan</option>
                  <option value='Turks and Caicos Islands'>Turks and Caicos Islands</option>
                  <option value='Tuvalu'>Tuvalu</option>
                  <option value='Uganda'>Uganda</option>
                  <option value='Ukraine'>Ukraine</option>
                  <option value='United Arab Emirates'>United Arab Emirates</option>
                  <option value='United Kingdom'>United Kingdom</option>
                  <option value='United States'>United States</option>
                  <option value='United States Minor Outlying Islands'>United States Minor Outlying Islands</option>
                  <option value='Uruguay'>Uruguay</option>
                  <option value='Uzbekistan'>Uzbekistan</option>
                  <option value='Vanuatu'>Vanuatu</option>
                  <option value='Venezuela'>Venezuela</option>
                  <option value='Viet Nam'>Viet Nam</option>
                  <option value='Virgin Islands, British'>Virgin Islands, British</option>
                  <option value='Virgin Islands, U.S.'>Virgin Islands, U.S.</option>
                  <option value='Wallis and Futuna'>Wallis and Futuna</option>
                  <option value='Western Sahara'>Western Sahara</option>
                  <option value='Yemen'>Yemen</option>
                  <option value='Zambia'>Zambia</option>
                  <option value='Zimbabwe'>Zimbabwe</option>
                </Select>
                {bankDetailsNonIndianForm.touched.country && bankDetailsNonIndianForm.errors.country && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {bankDetailsNonIndianForm.errors.country}
                  </Text>
                )}
                {!!country && !country?.approved && country?.rejectReasons?.length > 0 ? (

                  <Flex gap={'5px'} mt={'5px'}>
                    <Icon
                      as={FiAlertCircle}
                      boxSize={4}
                      color={"red.600"}
                    />
                    <Box>
                      <Text display={'flex'} fontSize={"12px"} color={"red.600"}>
                        <Text fontWeight={'bold'} whiteSpace={'nowrap'}>Rejected : &nbsp;</Text> {country?.rejectReasons[country?.rejectReasons?.length - 1]?.notes}
                      </Text>
                    </Box>
                  </Flex>
                ) : null}
              </Box>
            </Flex>
            <Box w={"full"}>
              <FormLabel ms='4px' fontSize='sm'>
                Bank Address <Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>
              </FormLabel>
              <Textarea
                name='branchAddress'
                placeholder={"Bank Address"}
                onChange={bankDetailsNonIndianForm.handleChange}
                onBlur={bankDetailsNonIndianForm.handleBlur}
                value={bankDetailsNonIndianForm.values.branchAddress}
                isInvalid={bankDetailsNonIndianForm.touched.branchAddress && bankDetailsNonIndianForm.errors.branchAddress ? true : false}
              />
              {bankDetailsNonIndianForm.touched.branchAddress && bankDetailsNonIndianForm.errors.branchAddress && (
                <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                  {bankDetailsNonIndianForm.errors.branchAddress}
                </Text>
              )}
              {!!branchAddress && !branchAddress?.approved && branchAddress?.rejectReasons?.length > 0 ? (

                <Flex gap={'5px'} mt={'5px'}>
                  <Icon
                    as={FiAlertCircle}
                    boxSize={4}
                    color={"red.600"}
                  />
                  <Box>
                    <Text display={'flex'} fontSize={"12px"} color={"red.600"}>
                      <Text fontWeight={'bold'} whiteSpace={'nowrap'}>Rejected : &nbsp;</Text> {branchAddress?.rejectReasons[branchAddress?.rejectReasons?.length - 1]?.notes}
                    </Text>
                  </Box>
                </Flex>
              ) : null}
            </Box>
            <Button mt={4} mb={4} onClick={bankDetailsNonIndianForm.handleSubmit}>
              Save
            </Button>
          </Flex>
        )}
      </FormControl>
    </>
  );
}

export default BankDetailsForm;
