import { AddIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Spacer,
  Spinner,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import AppTableWithPagination from "components/AppTableWithPagination";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import SidebarWithHeader from "components/SidebarWithHeader";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { FiDelete, FiEye } from 'react-icons/fi';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAddNewCompanyMutation, useGetCompaniesQuery, useRemoveCompanyMutation } from "store/companies/companiesApi";
import { truncateString } from "utils/functions";
import { newCompanySchema } from "utils/schema";
import { isLegalDetailsApproved } from "utils/verifyFormDetails";

function CompaniesForAdmin() {
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useDisclosure();

  const [companyToDelete, setcompanyToDelete] = useState(null);

  const userRole = useSelector((state) => state.auth?.userInfo?.role);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setsearchValue] = useState('');

  /* QUERIES */

  const { data: companies, isLoading: isCompaniesDataLoading, isFetching, refetch } = useGetCompaniesQuery({ currentPage, searchValue }, {
    pollingInterval: 10000,
    skipPollingIfUnfocused: true,
  });

  const [addNewCompany, result] = useAddNewCompanyMutation();
  const [removeCompany] = useRemoveCompanyMutation();

  useEffect(() => {
    if (searchValue != '') {
      setCurrentPage(1);
    }
  }, [searchValue])


  useEffect(() => {
    //console.log(result);
    if (result.status == "rejected") {
      const {
        data: { message },
      } = result.error;
      toast({
        position: "top-right",
        title: "Error",
        description: message,
        status: "error",
        duration: 8000,
        isClosable: true,
      });
    }
    if (result?.data) {
      const { companyName } = result.data;
      toast({
        position: "top-right",
        title: "Added New Company",
        description: companyName,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [result]);






  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "companyName",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {
          return (
            <Text fontSize='sm' >{truncateString(original.companyName, 20)}</Text>
          )
        }
      },
      {
        Header: "Group name",
        accessor: "nickName",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {
          return (
            <Text fontSize='sm' >{original.nickName}</Text>
          )
        }
      },
      {
        Header: "Type",
        accessor: "companyType",
        Filter: "",
        filter: "",
      },
      {
        Header: "Entity",
        accessor: "entity",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {
          return (
            <Text fontSize='sm' style={{ textTransform: 'capitalize' }}>{original.entity}</Text>
          )
        }
      },
      {
        Header: "Status",
        accessor: "verify",
        Filter: '',
        filter: '',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {


          const isLegalFormApproved = original && isLegalDetailsApproved(original?.entity, original?.legal)

          const isBankDetailsAdded = original.bank?.length > 0;

          return (
            <>
              {original.status ? (
                <Badge fontSize={'10px'} colorScheme={'green'}>active</Badge>
              ) :
                <>
                  {original.verify ? (
                    <Badge fontSize={'10px'} colorScheme='yellow'>{original.verify ? 'Under verification' : ''}</Badge>
                  ) : <Badge fontSize={'10px'} colorScheme={'gray'}>inactive</Badge>}
                  {userRole == 'finance' || userRole == 'media' || userRole == 'admin' ? (
                    <Flex mt={'5px'} gap={'5px'}>
                      {isLegalFormApproved ? (
                        <Badge fontSize={'10px'} colorScheme={'green'}>KYC</Badge>
                      ) :
                        <Badge fontSize={'10px'} colorScheme={'gray'}>KYC</Badge>}

                      {isBankDetailsAdded ? (
                        <Badge fontSize={'10px'} colorScheme={'green'}>Bank</Badge>
                      ) :
                        <Badge fontSize={'10px'} colorScheme={'gray'}>Bank</Badge>}
                    </Flex>
                  ) : null}


                </>
              }
            </>
          )
        }
      },
      {
        Header: "Client Name",
        accessor: "",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {
          const { name, email } = original?.clientId;

          return (
            <Text fontSize='sm' style={{ textTransform: 'capitalize' }}>{name ?? ''}</Text>
          )
        }
      },
      {
        Header: "Client Email",
        accessor: "",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {
          const { name, email } = original?.clientId;

          return (
            <Text fontSize='sm' >{email ?? ''}</Text>
          )
        }
      },

      {
        Header: 'created',
        accessor: 'createdDate',
        Filter: '',
        filter: '',
        Cell: ({ row: { original } }) => (
          <Text>{moment(original.createdDate).format("DD-MM-YYYY hh:mm A")}</Text>
        )
      },
      {
        Header: 'updated',
        accessor: 'updatedDate',
        Filter: '',
        filter: '',
        Cell: ({ row: { original } }) => (
          <Text>{moment(original.updatedDate).format("DD-MM-YYYY hh:mm A")}</Text>
        )
      },
      {
        Header: "Actions",
        accessor: "action",
        Filter: "",
        filter: "",
        //sticky: 'right',
        disableSortBy: true,
        Cell: ({ row: { original } }) => (
          <Box>
            <Flex gap={'10px'} justify={'center'}>
              <Tooltip label='View' fontSize='xs' >
                <Box>
                  <Icon
                    _hover={{ color: "gray.500" }}
                    as={FiEye}
                    cursor={"pointer"}
                    onClick={() => { navigate(`/companies/${original._id}`) }}
                  />
                </Box>
              </Tooltip>
              {userRole == 'admin' ? (
                <Tooltip label='Delete' fontSize='xs' >
                  <Box>
                    <Icon
                      color={'red.500'}
                      _hover={{ color: "red.300" }}
                      as={FiDelete}
                      cursor={"pointer"}
                      onClick={async () => {
                        onDeleteModalOpen();
                        setcompanyToDelete(original._id);
                      }}
                    />
                  </Box>
                </Tooltip>
              ) : null}

            </Flex>
          </Box>
        ),
      },
    ],
    []
  );

  const initialTableState = {
    sortBy: [
      {
        id: "updatedDate",
        desc: true
      }
    ],
  };

  const handleOnDeleteCancel = () => {
    onDeleteModalClose();
    setcompanyToDelete(null);
  };

  const handleOnDeleteConfirm = async () => {
    onDeleteModalClose();
    await removeCompany(companyToDelete);
  };

  const formik = useFormik({
    initialValues: {
      companyName: "",
      nickName: "",
      typeOfBussiness: "",
      companyType: "",
      registerAddress: "",
      correspondenceAddress: "",
      entity: "indian",
      typeOfBussiness: "",
      otherTypeOfBussiness: "",
      othercompanyType: "",
      websiteUrl: "",
      serviceDescription: "",
      representativeName: "",
      representativePhone: "",
      representativeEmail: "",
      representativeDesignation: "",
      ownerName: "",
      ownerPhone: "",
      isOwnerSame: true,
    },
    validationSchema: newCompanySchema,
    onSubmit: async (values) => {
      // alert(JSON.stringify(values, null, 2));
      // //console.log(values);
      if (values) {
        const {
          companyName,
          nickName,
          typeOfBussiness,
          registerAddress,
          entity,
          otherTypeOfBussiness,
          companyType,
          othercompanyType,
          websiteUrl,
          serviceDescription,
          representativeName,
          representativePhone,
          representativeEmail,
          representativeDesignation,
          ownerName,
          ownerPhone,
          isOwnerSame,
          correspondenceAddress,
        } = values;

        const updatedTypeOfBussiness = typeOfBussiness == "Other" ? otherTypeOfBussiness : typeOfBussiness;
        const updatedcompanyType = companyType == "Other" ? othercompanyType : companyType;
        const updatedOwnerName = isOwnerSame ? representativeName : ownerName;
        const updatedOwnerPhone = isOwnerSame ? representativePhone : ownerPhone;

        // //console.log(values);
        await addNewCompany({
          companyName,
          nickName,
          companyType: updatedcompanyType,
          registerAddress,
          correspondenceAddress,
          entity,
          typeOfBussiness: updatedTypeOfBussiness,
          websiteUrl,
          serviceDescription,
          representativeName,
          representativePhone,
          representativeEmail,
          representativeDesignation,
          ownerName: updatedOwnerName,
          ownerPhone: updatedOwnerPhone,
        });
      }
      formik.resetForm();
      onClose();
    },
  });



  return (
    <>

      {/** ADD NEW COMPANY MODAL */}
      <Modal
        size={"2xl"}
        isCentered={true}
        isOpen={isOpen}
        scrollBehavior={"inside"}
        onClose={() => {
          onClose();
          formik.resetForm();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New Company</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl style={{ display: "flex", flexDirection: "column", gap: 20 }}>
              <Flex flex={1}>
                <RadioGroup onChange={(value) => formik.setFieldValue("entity", value)} value={formik.values.entity}>
                  <Stack spacing={5} direction='row' justify={"center"}>
                    <Radio colorScheme='orange' value='indian'>
                      Indian Entity
                    </Radio>
                    <Radio colorScheme='orange' value='non-indian'>
                      Non-Indian Entity
                    </Radio>
                  </Stack>
                </RadioGroup>
                {formik.touched.entity && formik.errors.entity && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {formik.errors.entity}
                  </Text>
                )}
              </Flex>
              <Flex gap={'20px'}>
                <Box flex={1}>
                  <Input
                    name='companyName'
                    placeholder='Company Legal Name'
                    onChange={formik.handleChange}
                    value={formik.values.companyName}
                    isInvalid={formik.touched.companyName && formik.errors.companyName ? true : false}
                  />
                  {formik.touched.companyName && formik.errors.companyName && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                      {formik.errors.companyName}
                    </Text>
                  )}
                </Box>
                <Box flex={1}>
                  <Input
                    name='nickName'
                    placeholder='Nick Name'
                    onChange={formik.handleChange}
                    value={formik.values.nickName}
                    isInvalid={formik.touched.nickName && formik.errors.nickName ? true : false}
                  />
                  {formik.touched.nickName && formik.errors.nickName && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                      {formik.errors.nickName}
                    </Text>
                  )}
                </Box>
              </Flex>
              <Box flex={1}>
                <Select
                  flex={1}
                  name='companyType'
                  placeholder='Legal Structure'
                  onChange={formik.handleChange}
                  value={formik.values.companyType}
                  isInvalid={formik.touched.companyType && formik.errors.companyType ? true : false}
                >
                  <option value='Sole Proprietor'>Sole Proprietor</option>
                  <option value='Partnership'>Partnership</option>
                  <option value='Corporation'>Corporation</option>
                  <option value='Joint Venture'>Joint Venture</option>
                  <option value='Franchise'>Franchise</option>
                  <option value='Non-profit'>Non-profit</option>
                  <option value='Other'>Other (please specify)</option>
                </Select>
                {formik.touched.companyType && formik.errors.companyType && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {formik.errors.companyType}
                  </Text>
                )}
                {formik.values.companyType == "Other" ? (
                  <>
                    <Spacer mt={"20px"} />
                    <Textarea
                      name='othercompanyType'
                      placeholder='Specify Other Legal Structure'
                      onChange={formik.handleChange}
                      value={formik.values.othercompanyType}
                      isInvalid={formik.touched.othercompanyType && formik.errors.othercompanyType ? true : false}
                      minLength={10}
                    />
                    {formik.touched.othercompanyType && formik.errors.othercompanyType && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {formik.errors.othercompanyType}
                      </Text>
                    )}
                  </>
                ) : null}

              </Box>
              <Box flex={1}>
                <Select
                  flex={1}
                  name='typeOfBussiness'
                  placeholder='Type of Business'
                  onChange={formik.handleChange}
                  value={formik.values.typeOfBussiness}
                  isInvalid={formik.touched.typeOfBussiness && formik.errors.typeOfBussiness ? true : false}
                >
                  <option value='Distributor/Dealer'>Distributor/Dealer</option>
                  <option value='Publication/Broadcaster'>Publication/Broadcaster</option>
                  <option value='Professional Service'>Professional Service</option>
                  <option value='Service Provider'>Service Provider</option>
                  <option value='Manufacturer'>Manufacturer</option>
                  <option value='Consultant'>Consultant</option>
                  <option value='Freight/Transporation'>Freight/Transporation</option>
                  <option value='Wholesaler'>Wholesaler</option>
                  <option value='E-Commerce Store'>E-Commerce Store</option>
                  <option value='Other'>Other (please specify)</option>
                </Select>
                {formik.touched.typeOfBussiness && formik.errors.typeOfBussiness && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {formik.errors.typeOfBussiness}
                  </Text>
                )}
                {formik.values.typeOfBussiness == "Other" ? (
                  <>
                    <Spacer mt={"20px"} />
                    <Textarea
                      name='otherTypeOfBussiness'
                      placeholder='Specify Other Business Type'
                      onChange={formik.handleChange}
                      value={formik.values.otherTypeOfBussiness}
                      isInvalid={formik.touched.otherTypeOfBussiness && formik.errors.otherTypeOfBussiness ? true : false}
                      minLength={10}
                    />
                    {formik.touched.otherTypeOfBussiness && formik.errors.otherTypeOfBussiness && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {formik.errors.otherTypeOfBussiness}
                      </Text>
                    )}
                  </>
                ) : null}
              </Box>
              <Box flex={1}>
                <Input
                  name='websiteUrl'
                  placeholder='Website URL'
                  onChange={formik.handleChange}
                  value={formik.values.websiteUrl}
                  isInvalid={formik.touched.websiteUrl && formik.errors.websiteUrl ? true : false}
                  minLength={10}
                />
                {formik.touched.websiteUrl && formik.errors.websiteUrl && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {formik.errors.websiteUrl}
                  </Text>
                )}
              </Box>

              <Flex gap={"20px"}>
                <Box flex={1}>
                  <Textarea
                    name='registerAddress'
                    placeholder='Registered Office Address'
                    onChange={formik.handleChange}
                    value={formik.values.registerAddress}
                    isInvalid={formik.touched.registerAddress && formik.errors.registerAddress ? true : false}
                    minLength={10}
                  />
                  {formik.touched.registerAddress && formik.errors.registerAddress && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                      {formik.errors.registerAddress}
                    </Text>
                  )}
                </Box>

                <Box flex={1}>
                  <Textarea
                    name='correspondenceAddress'
                    placeholder='Correspondence Address'
                    onChange={formik.handleChange}
                    value={formik.values.correspondenceAddress}
                    isInvalid={formik.touched.correspondenceAddress && formik.errors.correspondenceAddress ? true : false}
                    minLength={10}
                  />
                  {formik.touched.correspondenceAddress && formik.errors.correspondenceAddress && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                      {formik.errors.correspondenceAddress}
                    </Text>
                  )}
                </Box>
              </Flex>

              <Box flex={1}>
                <Textarea
                  name='serviceDescription'
                  placeholder='Details on Services/Goods your company provides'
                  onChange={formik.handleChange}
                  value={formik.values.serviceDescription}
                  isInvalid={formik.touched.serviceDescription && formik.errors.serviceDescription ? true : false}
                  minLength={10}
                />
                {formik.touched.serviceDescription && formik.errors.serviceDescription && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {formik.errors.serviceDescription}
                  </Text>
                )}
              </Box>
              <Flex gap={"20px"}>
                <Box flex={1}>
                  <Input
                    name='representativeName'
                    placeholder='Representative Name'
                    onChange={formik.handleChange}
                    value={formik.values.representativeName}
                    isInvalid={formik.touched.representativeName && formik.errors.representativeName ? true : false}
                    minLength={10}
                  />
                  {formik.touched.representativeName && formik.errors.representativeName && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                      {formik.errors.representativeName}
                    </Text>
                  )}
                </Box>
                <Box flex={1}>
                  <Input
                    name='representativePhone'
                    placeholder='Representative Phone Number'
                    onChange={formik.handleChange}
                    value={formik.values.representativePhone}
                    isInvalid={formik.touched.representativePhone && formik.errors.representativePhone ? true : false}
                    minLength={10}
                  />
                  {formik.touched.representativePhone && formik.errors.representativePhone && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                      {formik.errors.representativePhone}
                    </Text>
                  )}
                </Box>
              </Flex>
              <Flex gap={"20px"}>
                <Box flex={1}>
                  <Input
                    name='representativeEmail'
                    placeholder='Representative Email ID'
                    onChange={formik.handleChange}
                    value={formik.values.representativeEmail}
                    isInvalid={formik.touched.representativeEmail && formik.errors.representativeEmail ? true : false}
                    minLength={10}
                  />
                  {formik.touched.representativeEmail && formik.errors.representativeEmail && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                      {formik.errors.representativeEmail}
                    </Text>
                  )}
                </Box>
                <Box flex={1}>
                  <Input
                    name='representativeDesignation'
                    placeholder='Representative Designation'
                    onChange={formik.handleChange}
                    value={formik.values.representativeDesignation}
                    isInvalid={formik.touched.representativeDesignation && formik.errors.representativeDesignation ? true : false}
                    minLength={10}
                  />
                  {formik.touched.representativeDesignation && formik.errors.representativeDesignation && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                      {formik.errors.representativeDesignation}
                    </Text>
                  )}
                </Box>
              </Flex>
              <Box flex={1}>
                <FormLabel fontSize={"sm"} mb='10px'>
                  Authorised Signatory Details
                </FormLabel>
                <Checkbox colorScheme={"orange"} isChecked={formik.values.isOwnerSame} onChange={(e) => formik.setFieldValue("isOwnerSame", e.target.checked)}>
                  Same as Representative's
                </Checkbox>
              </Box>

              {!formik.values.isOwnerSame ? (
                <Flex gap={"20px"}>
                  <Box flex={1}>
                    <Input
                      name='ownerName'
                      placeholder='Authorised Signatory Name'
                      onChange={formik.handleChange}
                      value={formik.values.ownerName}
                      isInvalid={formik.touched.ownerName && formik.errors.ownerName ? true : false}
                      minLength={10}
                    />
                    {formik.touched.ownerName && formik.errors.ownerName && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {formik.errors.ownerName}
                      </Text>
                    )}
                  </Box>
                  <Box flex={1}>
                    <Input
                      name='ownerPhone'
                      placeholder='Authorised Signatory Phone'
                      onChange={formik.handleChange}
                      value={formik.values.ownerPhone}
                      isInvalid={formik.touched.ownerPhone && formik.errors.ownerPhone ? true : false}
                      minLength={10}
                    />
                    {formik.touched.ownerPhone && formik.errors.ownerPhone && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {formik.errors.ownerPhone}
                      </Text>
                    )}
                  </Box>
                </Flex>
              ) : null}

              <Button mt={4} mb={4} onClick={formik.handleSubmit} colorScheme='orange'>
                Submit
              </Button>
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>

      <ConfirmDeleteModal isModalOpen={isDeleteModalOpen} copy={"Are you sure you want to delete the company?"} onCancel={handleOnDeleteCancel} onConfirm={handleOnDeleteConfirm} />

      <SidebarWithHeader>
        <Flex mb={"20px"}>
          <Heading as='h1' size='xl'>
            Companies <Badge verticalAlign={'super'} variant='subtle' fontSize='18px'>{companies?.meta?.totalItems}</Badge>
          </Heading>
          <Spacer />

          {(userRole == 'admin') ? (
            <Button
              color='white'
              colorScheme="brand"
              _hover={{
                bg: 'brand.300'
              }}
              bg={'brand.200'}
              onClick={onOpen}>
              <AddIcon boxSize={3} />
              &nbsp;Add
            </Button>
          ) : null}


        </Flex>
        <Flex flexDirection={"column"} flex={"1"}>
          {isCompaniesDataLoading ? (
            <Spinner />
          ) : (
            <>
              <AppTableWithPagination
                columns={columns}
                data={companies?.data}
                searchTerm={searchValue}
                paginationMeta={companies?.meta}
                onPageChange={(index) => setCurrentPage(index)}
                onSearchInputChange={(searchInput) => setsearchValue(searchInput)}
                searchEnabled={true}
                isFetching={isFetching}
              />
            </>
          )}
        </Flex>
      </SidebarWithHeader>
    </>
  );
}

export default CompaniesForAdmin;
