import { ChakraProvider, ColorModeProvider, ColorModeScript } from '@chakra-ui/react';
import React from "react";

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import ErrorBoundary from './components/ErrorBoundary';
import { store } from './store/store';
import Fonts from './styles/Fonts';
import theme from './styles/theme';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <Fonts />
      <ColorModeProvider>
        <ErrorBoundary>
          <BrowserRouter>
            <ColorModeScript initialColorMode={theme.config.initialColorMode} />
            <App />
          </BrowserRouter>
        </ErrorBoundary>
      </ColorModeProvider>
    </ChakraProvider>
  </Provider>);



