import {
    Badge,
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Select,
    Spacer,
    Spinner,
    Text,
    Tooltip,
    useToast,
    useDisclosure
} from '@chakra-ui/react';
import { Formik, useFormik } from 'formik';
import { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { FiDelete, FiEdit2, FiEye } from 'react-icons/fi';
import { useAddNewClientMutation, useEditClientMutation, useGetClientsQuery, useRemoveClientMutation, usePrefetch } from 'store/clients/clientsApi';

import { AddIcon } from '@chakra-ui/icons';
import AppTable from 'components/AppTable';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import SidebarWithHeader from 'components/SidebarWithHeader';
import { useNavigate } from "react-router-dom";
import { newClientSchema } from 'utils/schema';
import { useSelector } from 'react-redux';
import AppTableWithPagination from 'components/AppTableWithPagination'
function ClientList() {
    const toast = useToast();
    let navigate = useNavigate();
    const editFormRef = useRef();
    const userRole = useSelector((state) => state.auth?.userInfo?.role);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useDisclosure();
    const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: onEditModalClose } = useDisclosure();
    const [clientToDelete, setclientToDelete] = useState(null)
    const [placeHolderData, setplaceHolderData] = useState({})
    const [activeClient, setactiveClient] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setsearchValue] = useState('')

    /* QUERIES */

    const { data: clientList, isLoading, refetch, isFetching } = useGetClientsQuery({ currentPage, searchValue }, {
        pollingInterval: 10000,
        skipPollingIfUnfocused: true,
    })

    const [addNewClient, addResponse] = useAddNewClientMutation();
    const [removeClient, removeResponse] = useRemoveClientMutation()
    const [editClient, editResponse] = useEditClientMutation()



    //console.log(clientList.meta);

    useEffect(() => {
        if (searchValue != '') {
            setCurrentPage(1);
            // refetch()
        }
    }, [searchValue])


    useEffect(() => {
        if (editResponse?.status == "rejected") {
            toast({
                position: "top-right",
                title: "Error",
                status: "error",
                duration: 8000,
                isClosable: true,
            });
        }
        if (editResponse?.isSuccess) {
            toast({
                position: "top-right",
                title: "Updated user details",
                status: "success",
                duration: 4000,
                isClosable: true,
            });
        }
    }, [editResponse]);

    useEffect(() => {
        if (removeResponse?.status == "rejected") {
            toast({
                position: "top-right",
                title: "Error",
                status: "error",
                duration: 8000,
                isClosable: true,
            });
        }
        if (removeResponse?.isSuccess) {
            toast({
                position: "top-right",
                title: "User removed",
                status: "success",
                duration: 4000,
                isClosable: true,
            });
        }
    }, [removeResponse]);

    useEffect(() => {
        if (addResponse?.status == "rejected") {
            toast({
                position: "top-right",
                title: "Error",
                status: "error",
                duration: 8000,
                isClosable: true,
            });
        }
        if (addResponse?.isSuccess) {
            toast({
                position: "top-right",
                title: "New user added",
                status: "success",
                duration: 4000,
                isClosable: true,
            });
        }
    }, [addResponse]);


    const columns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: '_id',
                Filter: '',
                filter: ''
            },
            {
                Header: 'Name',
                accessor: 'firstName',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => <Text>{original.firstName + ' ' + original.lastName}</Text>
            },
            {
                Header: 'Is verified',
                accessor: 'verified',
                Filter: '',
                filter: '',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    //console.log(original);
                    return (<Badge fontSize={'10px'} colorScheme={original.verified ? 'green' : 'gray'}>{original.verified ? 'verified' : 'not verified'}</Badge>)
                }
            },
            {
                Header: 'Role',
                accessor: 'role',
                Filter: '',
                filter: ''
            },
            {
                Header: 'Email',
                accessor: 'email',
                Filter: '',
                filter: ''
            },
            {
                Header: '',
                accessor: 'action',
                Filter: '',
                filter: '',
                disableSortBy: true,
                Cell: ({ row: { original } }) => (
                    <>
                        {userRole == 'admin' ? (
                            <Flex justifyContent={'end'} alignItems={'center'} gap={'20px'}>

                                <Tooltip label='Edit' fontSize='xs'>
                                    <Box>
                                        <Icon _hover={{ color: 'gray.500' }} as={FiEdit2} cursor={'pointer'} onClick={() => { handleEdit(original); setactiveClient(original._id) }} />
                                    </Box>
                                </Tooltip>
                                <Tooltip label='Delete' fontSize='xs'>
                                    <Box>
                                        <Icon _hover={{ color: 'red.500' }} color={'red.300'} as={FiDelete} cursor={'pointer'} onClick={() => { onDeleteModalOpen(); setclientToDelete(original._id) }} />
                                    </Box>
                                </Tooltip>
                            </Flex>
                        ) : null}
                    </>
                ),
            },
        ],
        []
    )


    const handleEdit = (values) => {
        const { firstName, lastName, email, role, phoneNumber } = values;
        setplaceHolderData({ firstName, lastName, email, role, phoneNumber, password: '' })
        onEditModalOpen()
    }

    const handleDeleteCancel = () => {
        onDeleteModalClose()
        setclientToDelete(null)
        addNewForm.resetForm();
    }

    const handleDeleteConfirm = async () => {
        onDeleteModalClose()
        await removeClient(clientToDelete);
        addNewForm.resetForm();
    }

    const addNewForm = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            phoneNumber: '',
            role: ''
        },
        validationSchema: newClientSchema,
        onSubmit: async (values) => {
            //alert(JSON.stringify(values, null, 2));
            if (values) {
                await addNewClient(values)
            }
            addNewForm.resetForm();
            onClose();
        },
    });

    return (
        <>
            {/* DELETE CLIENT MODAL */}

            <ConfirmDeleteModal
                copy='Are you sure you want to delete the client'
                isModalOpen={isDeleteModalOpen}
                onCancel={handleDeleteCancel}
                onConfirm={handleDeleteConfirm}
            />

            {/* EDIT CLIENT MODAL */}

            <Modal scrollBehavior={'inside'} size={'md'} isCentered={true} isOpen={isEditModalOpen} onClose={() => { onEditModalClose(); editFormRef.current?.resetForm() }}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text fontSize={'2xl'} textAlign={'center'} mb={'20px'}>Edit </Text>

                        <Formik
                            innerRef={editFormRef}
                            validationSchema={newClientSchema}
                            initialValues={placeHolderData}
                            enableReinitialize
                            onSubmit={async (values, { resetForm }) => {
                                //alert(JSON.stringify(values));

                                if (values) {
                                    const payload = {
                                        ...values,
                                        verified: true
                                    }
                                    //console.log({ payload });
                                    await editClient({ accountId: activeClient, payload })
                                }
                                resetForm();
                                onEditModalClose();
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isValid
                            }) => (
                                <Flex gap={'20px'} flexDirection={'column'}>
                                    <Box flex={1}>
                                        <FormLabel ms='4px' fontSize='sm' fontWeight='bold'>
                                            First Name
                                        </FormLabel>
                                        <Input
                                            id='firstName'
                                            name="firstName"
                                            placeholder='First Name'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.firstName}
                                            isInvalid={touched.firstName && errors.firstName ? true : false}
                                        />
                                        {touched.firstName && errors.firstName && (
                                            <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{errors.firstName}</Text>
                                        )}
                                    </Box>
                                    <Box flex={1}>
                                        <FormLabel ms='4px' fontSize='sm' fontWeight='bold'>
                                            Last Name
                                        </FormLabel>
                                        <Input
                                            id='lastName'
                                            flex={1}
                                            name="lastName"
                                            placeholder='Last Name'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.lastName}
                                            isInvalid={touched.lastName && errors.lastName ? true : false}
                                        />
                                        {touched.lastName && errors.lastName && (
                                            <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{errors.lastName}</Text>
                                        )}
                                    </Box>
                                    <Box flex={1}>
                                        <FormLabel ms='4px' fontSize='sm' fontWeight='bold'>
                                            Email ID
                                        </FormLabel>
                                        <Input
                                            id='email'
                                            name="email"
                                            placeholder='Email ID'
                                            type='email'
                                            onChange={handleChange}
                                            value={values.email}
                                            onBlur={handleBlur}
                                            isInvalid={touched.email && errors.email ? true : false}
                                            minLength={10}
                                        />
                                        {touched.email && errors.email && (
                                            <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{errors.email}</Text>
                                        )}
                                    </Box>
                                    <Box flex={1}>
                                        <FormLabel ms='4px' fontSize='sm' fontWeight='bold'>
                                            Phone Number
                                        </FormLabel>
                                        <Input
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            value={values.phoneNumber}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.phoneNumber && errors.phoneNumber ? true : false}
                                            fontSize='sm'
                                            borderRadius='5px'
                                            type='text'
                                            placeholder='Phone Number'
                                        />
                                    </Box>
                                    <Box flex={1}>
                                        <FormLabel ms='4px' fontSize='sm' fontWeight='bold'>
                                            Password
                                        </FormLabel>
                                        <Input
                                            id='password'
                                            name="password"
                                            placeholder='Password'
                                            type='password'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}
                                            isInvalid={touched.password && errors.password ? true : false}
                                            minLength={10}
                                        />
                                        {touched.password && errors.password && (
                                            <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{errors.password}</Text>
                                        )}
                                    </Box>
                                    <Box flex={1}>
                                        <FormLabel ms='4px' fontSize='sm' fontWeight='bold'>
                                            Role
                                        </FormLabel>
                                        <Select
                                            id='role'
                                            name="role"
                                            placeholder='Role'
                                            type='text'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.role}
                                            isInvalid={touched.role && errors.role ? true : false}
                                        >
                                            <option value='admin'>Admin</option>
                                            <option value='client'>Client</option>
                                            <option value='finance'>Finance</option>
                                            <option value='media'>Media</option>
                                        </Select>
                                        {touched.role && errors.role && (
                                            <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{errors.role}</Text>
                                        )}
                                    </Box>

                                    <Button w={'full'} mt={4} mb={4} onClick={handleSubmit} isDisabled={!isValid}>
                                        Submit
                                    </Button>
                                </Flex>
                            )}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* ADD NEW CLIENT MODAL */}

            <Modal scrollBehavior={'inside'} size={'md'} isCentered={true} isOpen={isOpen} onClose={() => { onClose(); addNewForm.resetForm() }}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text fontSize={'2xl'} textAlign={'center'} mb={'20px'}>Add New Client</Text>
                        <FormControl style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                            <Box flex={1}>
                                <Input
                                    id='firstName'
                                    name="firstName"
                                    placeholder='First Name'
                                    onChange={addNewForm.handleChange}
                                    onBlur={addNewForm.handleBlur}
                                    value={addNewForm.values.firstName}
                                    isInvalid={addNewForm.touched.firstName && addNewForm.errors.firstName ? true : false}
                                />
                                {addNewForm.touched.firstName && addNewForm.errors.firstName && (
                                    <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{addNewForm.errors.firstName}</Text>
                                )}
                            </Box>
                            <Box flex={1}>
                                <Input
                                    id='lastName'
                                    flex={1}
                                    name="lastName"
                                    placeholder='Last Name'
                                    onChange={addNewForm.handleChange}
                                    onBlur={addNewForm.handleBlur}
                                    value={addNewForm.values.lastName}
                                    isInvalid={addNewForm.touched.lastName && addNewForm.errors.lastName ? true : false}
                                />
                                {addNewForm.touched.lastName && addNewForm.errors.lastName && (
                                    <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{addNewForm.errors.lastName}</Text>
                                )}
                            </Box>
                            <Box flex={1}>
                                <Input
                                    id='email'
                                    name="email"
                                    placeholder='Email ID'
                                    type='email'
                                    onChange={addNewForm.handleChange}
                                    value={addNewForm.values.email}
                                    onBlur={addNewForm.handleBlur}
                                    isInvalid={addNewForm.touched.email && addNewForm.errors.email ? true : false}
                                    minLength={10}
                                />
                                {addNewForm.touched.email && addNewForm.errors.email && (
                                    <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{addNewForm.errors.email}</Text>
                                )}
                            </Box>
                            <Box flex={1}>
                                <Input
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={addNewForm.values.phoneNumber}
                                    onChange={addNewForm.handleChange}
                                    onBlur={addNewForm.handleBlur}
                                    isInvalid={addNewForm.touched.phoneNumber && addNewForm.errors.phoneNumber ? true : false}
                                    fontSize='sm'
                                    borderRadius='5px'
                                    type='text'
                                    placeholder='Phone Number'
                                />
                            </Box>
                            <Box flex={1}>
                                <Input
                                    id='password'
                                    name="password"
                                    placeholder='Password'
                                    type='password'
                                    onChange={addNewForm.handleChange}
                                    onBlur={addNewForm.handleBlur}
                                    value={addNewForm.values.password}
                                    isInvalid={addNewForm.touched.password && addNewForm.errors.password ? true : false}
                                    minLength={10}
                                />
                                {addNewForm.touched.password && addNewForm.errors.password && (
                                    <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{addNewForm.errors.password}</Text>
                                )}
                            </Box>
                            <Box flex={1}>
                                <Select
                                    id='role'
                                    name="role"
                                    placeholder='Role'
                                    type='text'
                                    onChange={addNewForm.handleChange}
                                    onBlur={addNewForm.handleBlur}
                                    value={addNewForm.values.role}
                                    isInvalid={addNewForm.touched.role && addNewForm.errors.role ? true : false}
                                >
                                    <option value='admin'>Admin</option>
                                    <option value='client'>Client</option>
                                    <option value='finance'>Finance</option>
                                    <option value='media'>Media</option>
                                </Select>
                                {addNewForm.touched.role && addNewForm.errors.role && (
                                    <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{addNewForm.errors.role}</Text>
                                )}
                            </Box>

                            <Button mt={4} mb={4} onClick={addNewForm.handleSubmit} isDisabled={!addNewForm.isValid}>
                                Submit
                            </Button>
                        </FormControl>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <SidebarWithHeader>
                <Flex mb={'20px'} flexDirection={'row'} >
                    <Heading as='h1' size='xl'>Users <Badge verticalAlign={'super'} variant='subtle' fontSize='18px'>{clientList?.meta?.totalItems}</Badge></Heading>
                    <Spacer />
                    {userRole == 'admin' ? (
                        <Button
                            color='white'
                            colorScheme="brand"
                            _hover={{
                                bg: 'brand.300'
                            }}
                            bg={'brand.200'}
                            onClick={onOpen}>
                            <AddIcon boxSize={3} />&nbsp;Add
                        </Button>
                    ) : null}
                </Flex>
                <Flex flexDirection={'column'} flex={'1'} >
                    {isLoading ?
                        <Spinner /> :
                        (
                            <AppTableWithPagination
                                columns={columns}
                                data={clientList?.data}
                                searchTerm={searchValue}
                                paginationMeta={clientList?.meta}
                                onPageChange={(index) => setCurrentPage(index)}
                                onSearchInputChange={(searchInput) => setsearchValue(searchInput)}
                                searchEnabled={true}

                                isFetching={isFetching}

                            />
                        )}
                </Flex>
            </SidebarWithHeader>
        </>
    )
}

export default ClientList