import { api } from "../api";

export const verifyCompanyApi = api.injectEndpoints({
    endpoints: (builder) => ({
        verifyCompany: builder.mutation({
            query: (companyId) => ({
                url: `/companies/${companyId}/verify`,
                method: "PUT",
            }),
            invalidatesTags: ["Company", "Companies"],
        }),
        sendFormFeedback: builder.mutation({
            query: (companyId) => ({
                url: `/companies/${companyId}/feedback`,
                method: 'PUT',
            }),
            invalidatesTags: ["Company", "Companies"],
        }),
        activateCompany: builder.mutation({
            query: ({ companyId, data }) => ({
                url: `/companies/${companyId}/activate`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ["Company", "Companies"],
        }),
    }),
});

export const { useVerifyCompanyMutation, useSendFormFeedbackMutation, useActivateCompanyMutation } = verifyCompanyApi;
