import { api } from "../api";

export const logsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getLogs: builder.query({
            query: ({ currentPage, filterValue }) => `/logs?page=${currentPage}&filter=${filterValue}`,
            transformResponse: (response, meta, arg) => response.data,
            //providesTags: ['Companies'],
        }),
    }),
});

export const {
    useGetLogsQuery
} = logsApi;
