import { Badge, Box, Button, Divider, Flex, FormControl, FormLabel, Select, Spinner, Switch, Text, Textarea, useColorModeValue, useToast } from "@chakra-ui/react";

import { getIn, useFormik } from "formik";
import { useEffect, useState } from "react";
import { useApproveBankFieldMutation, useApproveLegalFieldMutation } from "store/companies/approveDocumentApi";
import { boolean, object, string } from "yup";
import LightBox from "./LightBox";

function DocumentApprovalCard({ title, fieldData, for_, canVerify }) {
  const headingColor = useColorModeValue("gray.600", "gray.400");
  const toast = useToast()

  const { name, approved, value, _id, company, rejectReasons, checked } = fieldData || {}

  const isRejected = !approved && rejectReasons?.length > 0;

  let borderColor;
  if (isRejected) {
    borderColor = 'red.400'
  } else if (approved) {
    borderColor = 'green.400'
  } else {
    borderColor = 'gray.400'
  }

  //console.log({ canVerify, title, fieldData });

  const [approveBankField, bankApprovalResponse] = useApproveBankFieldMutation()
  const [approveLegalField, legalApprovalResponse] = useApproveLegalFieldMutation()

  useEffect(() => {
    if (bankApprovalResponse?.status == "rejected") {
      const {
        data: { message },
      } = bankApprovalResponse.error;
      toast({
        position: "top-right",
        title: "Error",
        description: message,
        status: "error",
        duration: 8000,
        isClosable: true,
      });
    }
    if (bankApprovalResponse?.isSuccess) {
      const { companyName } = bankApprovalResponse.data;
      toast({
        position: "top-right",
        title: "Saved",
        description: companyName,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [bankApprovalResponse]);

  useEffect(() => {
    if (legalApprovalResponse?.status == "rejected") {
      toast({
        position: "top-right",
        title: "Error",
        status: "error",
        duration: 8000,
        isClosable: true,
      });
    }
    if (legalApprovalResponse?.isSuccess) {
      const { companyName } = legalApprovalResponse.data;
      toast({
        position: "top-right",
        title: "Saved",
        description: companyName,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [legalApprovalResponse]);

  const [intialValues, setinitialValues] = useState({
    [name]: {
      reason: "",
      isApproved: false,
      notes: "",
    }
  })

  useEffect(() => {
    let reason = ""
    let notes = ""

    if (isRejected) {
      reason = rejectReasons[rejectReasons?.length - 1].reason;
      notes = rejectReasons[rejectReasons?.length - 1].notes;
    }
    setinitialValues({
      [name]: {
        reason: reason,
        isApproved: approved,
        notes: notes,
      }
    })
  }, [fieldData])

  const approvalSchema = object({
    [name]: object({
      isApproved: boolean(),
      reason: string().when("isApproved", {
        is: false,
        then: () => string().required("Required"),
      }),
      notes: string()
    }),
  });



  const formik = useFormik({
    initialValues: intialValues,
    validationSchema: approvalSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      //console.log(JSON.stringify(values, null, 2));
      if (values) {
        const { reason, isApproved, notes } = formik.getFieldProps(`${name}`).value
        const updatedNotes = isApproved ? '' : notes;
        const updatedReason = isApproved ? '' : reason;
        const payload = { id: _id, name, reason: updatedReason, value, approved: isApproved, notes: updatedNotes, checked: true }

        if (for_ == 'bank') {
          await approveBankField({ id: company, payload })
        } else {
          await approveLegalField({ id: company, payload })
        }
        formik.resetForm()
      }
    },
  });

  let fileSource_;

  if (canVerify) {
    fileSource_ = value;
  } else if (isRejected) {
    fileSource_ = rejectReasons[rejectReasons?.length - 1].value
  }

  return (
    <Box w={"full"} pos={'relative'} borderWidth={1} borderColor={borderColor} borderRadius={5}>
      {legalApprovalResponse?.isLoading || bankApprovalResponse?.isLoading ? (
        <Flex pos={'absolute'} zIndex={1} background={'#00000038'} left={0} top={0} width={'100%'} h={'100%'} alignItems={'center'} justifyContent={'center'}>
          <Spinner />
        </Flex>
      ) : null}
      <Flex gap={'5px'} pos={'absolute'} top={'10px'} right={'10px'}>
        {checked ? (
          <Badge fontSize={'10px'} colorScheme="orange">checked</Badge>
        ) : null}
        {approved ? (
          <Badge fontSize={'10px'} colorScheme="green">approved</Badge>
        ) : null}
        {isRejected ? (
          <Badge fontSize={'10px'} colorScheme="red">rejected</Badge>
        ) : null}
      </Flex>
      <Box borderWidth={1} borderRadius={5} flex={1} padding={"10px"}>
        <Text fontSize={"sm"} color={headingColor}>
          {title}
        </Text>
        <Flex flex={1} gap={"40px"} justifyContent={"space-between"} mt={"10px"}>
          <Box>
            <LightBox fileSource={fileSource_} />
          </Box>
          <Flex
            pointerEvents={canVerify ? "initial" : "none"}
            cursor={canVerify ? "initial" : "not-allowed"}
            opacity={canVerify ? 1 : 0.4}
            flexDirection={"column"}
            flex={1}
          >
            <Select
              size={"sm"}
              w={"100%"}
              name={`${name}.reason`}
              placeholder='Reason for rejection '
              onChange={formik.handleChange}
              value={formik.getFieldProps(`${name}.reason`).value}
            >
              <option value='Incorrect Document'>Incorrect Document</option>
              <option value='Document Unclear'>Document Unclear</option>
              <option value='Incomplete Document'>Incomplete Document</option>
              <option value='Others'>Others </option>
            </Select>
            {getIn(formik.errors, `${name}.reason`) && getIn(formik.touched, `${name}.reason`) && (
              <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                {getIn(formik.errors, `${name}.reason`)}
              </Text>
            )}
            <Textarea
              value={formik.getFieldProps(`${name}.notes`).value}
              size={"sm"}
              name={`${name}.notes`}
              placeholder='Notes'
              onChange={formik.handleChange}
              mt={'10px'}
            />
          </Flex>
        </Flex>
        {canVerify ? (
          <>
            <Divider my={"10px"} />
            <Flex flexDirection={"row"} gap={"10px"} align={"center"}>
              <FormControl display={"flex"} flexDirection={"row"}>
                <FormLabel fontSize={"sm"} mb='0'>
                  Approved
                </FormLabel>
                <Switch
                  id={name}
                  onChange={formik.handleChange}
                  name={`${name}.isApproved`}
                  colorScheme='green'
                  isChecked={formik.getFieldProps(`${name}.isApproved`).value}
                  {...formik.getFieldProps(`${name}.isApproved`)}
                />
              </FormControl>
              <Button
                size={"sm"}
                colorScheme="orange"
                onClick={formik.handleSubmit}
                isDisabled={!formik.isValid}
              >Save</Button>
            </Flex>
          </>
        ) : null}
      </Box>
    </Box>
  );
}

export default DocumentApprovalCard;
