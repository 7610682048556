import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Stack,
    useColorModeValue,
} from '@chakra-ui/react';

export default function ResetPassword() {
    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack
                spacing={8}
                w={'full'}
                maxW={'md'}
                bg={useColorModeValue('white', 'gray.700')}
                rounded={'xl'}
                boxShadow={'lg'}
                p={6}
                my={12}>
                <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
                    Enter new password
                </Heading>
                <FormControl id="email" isRequired mb={'40px'}>
                    <FormLabel>Email ID</FormLabel>
                    <Input
                        placeholder="Email ID"
                        _placeholder={{ color: 'gray.500' }}
                        type="email"
                    />
                </FormControl>
                <FormControl id="password" isRequired mb={'20px'}>
                    <FormLabel>Password</FormLabel>
                    <Input type="password" />
                </FormControl>
                <Stack spacing={6}>
                    <Button
                        colorScheme='orange'
                    >
                        Submit
                    </Button>
                </Stack>
            </Stack>
        </Flex >
    );
}