import React from "react";
import LogoNav from "assets/images/logo-main.png";

import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Spacer,
  ButtonGroup,
  Link,
  Button,
  Text,
} from "@chakra-ui/react";
import BackImage from "assets/images/bg-dots.png";

import Icon01 from "assets/images/icon_01.png";
import Icon02 from "assets/images/icon_02.png";
import Icon03 from "assets/images/icon_03.png";
import BackImages01 from "assets/images/element01.png";
import BackImages02 from "assets/images/element02.png";
import BackImages03 from "assets/images/element03.png";

function HomeBanner() {
  return (
    <Box overflow='hidden' id='section01' bg='#231f20'>
      <Box
        py={{ base: "40px", md: "60px", xl: "85px" }}
        backgroundImage={BackImage}
        backgroundPosition='center'
        backgroundRepeat='no-repeat'
        backgroundSize={"cover"}
      >
        <Container py={{ base: "40px", md: "60px", xl: "100px" }} maxW='800px'>
          {/* <SimpleGrid
              columns={{ base: 1, md: 2 }}
              alignItems='center'
              justify='space-between'
              align='center'
            >
              <Box>
                <Image src={Logo} alt='logoimage' />
              </Box>

              <Flex direction='column' alignItems='start'>
                <Text
                  bgGradient='linear(to-r, #ef9175, #e7646c,#ec7768,#915490)'
                  // bgClip='text'
                  color='white'
                  fontSize={{ base: "24px", md: "30px", lg: "40px" }}
                  textTransform='uppercase'
                  lineHeight='1.1'
                  maxW='max-content'
                  letterSpacing='2px'
                  fontWeight='medium'
                  px='10px'
                  mb='5px'
                >
                  WE PROVIDE STABLE
                </Text>
                <Text
                  bgGradient='linear(to-r, #ef9175, #e7646c,#ec7768,#915490)'
                  // bgClip='text'
                  color='white'
                  fontSize={{ base: "24px", md: "30px", lg: "40px" }}
                  textTransform='uppercase'
                  lineHeight='1.1'
                  maxW='max-content'
                  letterSpacing='2px'
                  fontWeight='medium'
                  px='10px'
                >
                  AGENCY ACCOUNTS
                </Text>
                <Text
                  w={{ base: "100%", md: "300px", xl: "400px" }}
                  my={{ base: "10px", md: "10px", xl: "20px" }}
                  color='#a1a1a1'
                  textAlign='start'
                >
                  "The quick brown fox jumps over the lazy dog" is an
                  English-language pangram—a sentence that contains all of the
                  letters of the English alphabet. Owing to its existence,
                  Chakra was created.
                </Text>
                <Link color='#ffffff' href='#' isExternal>
                  GET STARTED <LinkIcon mx='2px' />
                </Link>
              </Flex>
            </SimpleGrid> */}
          <Box pos='relative' pl='20px'>
            <Image
              pos='absolute'
              src={BackImages01}
              left={["-15%", "-15%"]}
              top={["-16%", "-32%"]}
              w={["100px", "160px"]}
            />
            <Image
              pos='absolute'
              src={BackImages02}
              right={["-25%", "-60%"]}
              top={["-23%", "-45%"]}
              w={["150px", "300px"]}
            />
            <Image
              pos='absolute'
              src={BackImages03}
              bottom={["-25%", "-25%"]}
              right='0'
              w={["100px", "250px"]}
            />
            <Box
              position='relative'
              color='white'
              lineHeight='1.35'
              fontSize={["3xl", "5xl"]}
              mb='30px'
              zIndex='10'
            >
              <Text>Rock-Solid Stability</Text>
              <Text>Limitless Scalability</Text>
              <Text>Round-the-clock Support</Text>
            </Box>
            <Flex gap='20px' position='relative' zIndex='10'>
              <Link
                cursor='pointer'
                to='https://mail.google.com/mail/u/0/#inbox/FMfcgzGtwzlvjljcXsXKxPxvnGkKFRXz'
              >
                <Image
                  width={["60px", "90px"]}
                  src={Icon01}
                  alt='icon-banner1'
                />
              </Link>

              <Link>
                <Image
                  width={["60px", "90px"]}
                  src={Icon02}
                  alt='icon2-banner1'
                />
              </Link>
              <Link>
                <Image
                  width={["60px", "90px"]}
                  src={Icon03}
                  alt='icon3-banner1'
                />
              </Link>
            </Flex>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default HomeBanner;
