import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react';

function LegendItem({ color, label }) {
    return (
        <Flex align="center" mb={2}>
            <Box w={4} h={2} bg={color} mr={2} />
            <Text fontSize={'xs'}>{label}</Text>
        </Flex>
    )
}

export default LegendItem