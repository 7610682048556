import { useEffect, useState } from 'react';
import {
    useSearchParams,
} from "react-router-dom";

import {
    Button,
    Flex,
    Spinner,
    Stack,
    Text,
    useToast
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useVerifyEmailMutation } from 'store/auth/authApi';

function VerifyEmail() {
    const toast = useToast()
    const navigate = useNavigate()
    const [queryParameters] = useSearchParams();
    const [isLoading, setisLoading] = useState(true)

    const verificationCode = queryParameters.get("key");
    const [isVerified, setisVerified] = useState(false)
    //console.log(verificationCode);

    const [verifyEmail, { data: verifyResponse, isLoading: verifyResponseIsLoading }] = useVerifyEmailMutation();

    useEffect(() => {
        if (verificationCode) {
            verifyUsingCode();
        }
    }, [verificationCode])

    const verifyUsingCode = async () => {
        const payload = {
            code: verificationCode
        }
        await verifyEmail(payload)
            .unwrap()
            .then((response) => {
                const { status } = response;
                if (status == 'success') {
                    toast({
                        position: "top-right",
                        title: "Verified",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                    // setTimeout(() => {
                    //     navigate('/signin')
                    // }, 1500);
                    setisVerified(true);
                    setisLoading(false)
                }
            })
            .catch((error) => {
                console.error('rejected', error)
                toast({
                    position: "top-right",
                    title: "Error",
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                });
                setisVerified(false);
                setisLoading(false)
            })
    }


    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={'gray.50'}>
            {isLoading ? <Spinner /> : null}
            {isVerified ? (
                <Stack
                    spacing={4}
                    w={'full'}
                    maxW={'md'}
                    bg={'gray.100'}
                    rounded={'xl'}
                    boxShadow={'lg'}
                    p={6}
                    my={12}
                    gap={'10px'}
                    align={'center'}
                >
                    <Text align={'center'}>Your Email ID is verified</Text>
                    <Button colorScheme='orange' w={'min'} size={'sm'} onClick={() => navigate('/signin')}>Sign In</Button>
                </Stack>
            ) : null}

        </Flex>
    )
}

export default VerifyEmail