import {
    Badge,
    Divider,
    Flex,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text
} from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';

import AppTable from 'components/AppTable';
import SelectColumnFilter from 'components/SelectColumnFilter';
import SidebarWithHeader from 'components/SidebarWithHeader';
import { useParams } from 'react-router-dom';
import { useGetAccountsByUserIDQuery } from 'store/adAccounts/adAccountsApi';
import { toCurrency } from 'utils/functions';

function ClientProfileForAdmin() {

    const { id } = useParams()

    /* QUERIES */

    const { data: accounts, isLoading: isAccountDataLoading, refetch } = useGetAccountsByUserIDQuery(id)

    useEffect(() => {
        refetch();
    }, [refetch])

    const columns = useMemo(
        () => [
            {
                Header: 'Account ID',
                accessor: 'adsAccountId',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.adsAccountId}</Text>)
            },
            {
                Header: 'Name',
                accessor: 'adsAccountName',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'} fontWeight={'bold'}>{original.adsAccountName}</Text>)
            },
            {
                Header: 'Platform',
                accessor: 'platform',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text>Facebook</Text>)
            },
            {
                Header: 'Currency',
                accessor: 'currency',
                Filter: '',
                filter: ''
            },
            {
                Header: 'Total spends',
                accessor: 'amountSpent',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text align={'right'}>{toCurrency(original.amountSpent, original.currency)}</Text>),
                align: "right",
            },
            {
                Header: 'Balance',
                accessor: 'balance',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text align={'right'}>{toCurrency(original.balance, original.currency)}</Text>),
                align: "right",
            },
            {
                Header: 'Status',
                accessor: 'status',
                Filter: SelectColumnFilter,
                filter: 'includes',
                disableSortBy: true,
                Cell: ({ row: { original } }) => (<Badge size={'xs'} colorScheme={original.status == '1' ? 'green' : 'gray'}>{original.status == '1' ? 'Active' : 'Inactive'}</Badge>)
            },

        ],
        []
    )

    // //console.log(accounts);

    return (
        <SidebarWithHeader >
            <Flex p={'20px'} flexDirection={'column'}>
                <Tabs position="relative" variant="unstyled">
                    <TabList gap={'10px'}>
                        <Tab borderWidth={1} borderRadius={5} _selected={{ color: 'white', bg: 'gray.500' }}>Accounts </Tab>
                        {/* <Tab borderWidth={1} borderRadius={5} _selected={{ color: 'white', bg: 'gray.500' }}>Onboarding </Tab> */}
                    </TabList>
                    <Divider my={'20px'} />
                    <TabPanels>
                        <TabPanel p={0}>
                            {accounts?.length > 0
                                ? (<AppTable columns={columns} data={accounts} searchEnabled={false} isLoading={isAccountDataLoading} />)
                                : (<Text>No data avaialable</Text>)
                            }
                        </TabPanel>
                        {/* <TabPanel>
                            <Text>First Name: John </Text>
                            <Text>Last Name: Doe</Text>
                            <Text>Account Name: lorem ipsum</Text>
                            <Text>Account Type: lorem ipsum</Text>
                        </TabPanel> */}
                    </TabPanels>
                </Tabs>
            </Flex>
        </SidebarWithHeader>
    )
}

export default ClientProfileForAdmin;