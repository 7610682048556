import {
  Box, Button, Flex, FormControl, FormLabel, Input, Text, Tooltip, Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs, Divider
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { useFormik } from "formik";
import { commisionFormSchema } from "utils/schema";
import { hasDecimal } from "utils/functions";

function CommissionForm({ onCommissionFormSubmit, details }) {

  const { cc, cl } = details || {};

  //console.log({ details });
  const [creditCardFormInitialValues, setcreditCardFormInitialValues] = useState({})
  const [creditLineFormInitialValues, setcreditLineFormInitialValues] = useState({})

  useEffect(() => {
    setcreditCardFormInitialValues({
      retroComission: cc?.retroComission || "",
      firstRetroPartnerName: cc?.firstRetroPartnerName || "",
      secondRetroPartnerName: cc?.secondRetroPartnerName || "",
      firstRetroPartnerCommission: cc?.firstRetroPartnerCommission || "",
      secondRetroPartnerCommission: cc?.secondRetroPartnerCommission || "",
    })

    setcreditLineFormInitialValues({
      retroComission: cl?.retroComission || "",
      firstRetroPartnerName: cl?.firstRetroPartnerName || "",
      secondRetroPartnerName: cl?.secondRetroPartnerName || "",
      firstRetroPartnerCommission: cl?.firstRetroPartnerCommission || "",
      secondRetroPartnerCommission: cl?.secondRetroPartnerCommission || "",
    })

  }, [details])

  const creditCardForm = useFormik({
    initialValues: creditCardFormInitialValues,
    enableReinitialize: true,
    validationSchema: commisionFormSchema,
    onSubmit: (values) => {
      //console.log(JSON.stringify(values, null, 2));
      if (creditCardForm.values == creditCardForm.initialValues) {
        return;
      }
      const payload = { ...values, commissionType: 'cc' }
      onCommissionFormSubmit(payload);
      creditCardForm.resetForm();
    },
  });

  const creditLineForm = useFormik({
    initialValues: creditLineFormInitialValues,
    enableReinitialize: true,
    validationSchema: commisionFormSchema,
    onSubmit: (values) => {
      //console.log(JSON.stringify(values, null, 2));
      if (creditLineForm.values == creditLineForm.initialValues) {
        return;
      }
      const payload = { ...values, commissionType: 'cl' }
      onCommissionFormSubmit(payload);
      creditLineForm.resetForm();
    },
  });

  const totalComissionCCValue = Number(creditCardForm.values.retroComission) + Number(creditCardForm.values.firstRetroPartnerCommission) + Number(creditCardForm.values.secondRetroPartnerCommission)
  const totalComissionCLValue = Number(creditLineForm.values.retroComission) + Number(creditLineForm.values.firstRetroPartnerCommission) + Number(creditLineForm.values.secondRetroPartnerCommission)

  return (
    <FormControl>
      <Tabs
        size='sm'
        position='relative'
        variant='unstyled'
        sx={{
          display: "flex",
          position: "relative",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <TabList gap={"10px"}>
          <Tab
            borderWidth={1}
            borderRadius={5}
            _selected={{ color: "white", bg: "brand.200" }}
          >
            Credit Card
          </Tab>
          <Tab
            borderWidth={1}
            borderRadius={5}
            _selected={{ color: "white", bg: "brand.200" }}
          >
            Credit Line
          </Tab>
        </TabList>
        <TabPanels py={"40px"}>
          <TabPanel p={0}>
            <Flex>
              <Flex flex={1} flexDirection='column' gap='20px'>
                <Box>
                  <FormLabel fontSize={"lg"} mb='10px'>
                    Total Client Commission : {hasDecimal(totalComissionCCValue) ? totalComissionCCValue.toFixed(2) : totalComissionCCValue} %
                  </FormLabel>
                </Box>
                <Flex gap='20px' flexDirection={["column", "row"]}>


                  <Box w={"full"}>
                    <FormLabel fontSize={"sm"} mb='10px'>
                      Blink's Commission (%) <Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>
                    </FormLabel>
                    <Input
                      name='retroComission'
                      placeholder={"Blink's Commission"}
                      onChange={(e) => {
                        creditCardForm.setFieldValue('retroComission', e.target.value.replace(/[^0-9.]/g, ''))
                      }}
                      value={creditCardForm.values.retroComission}
                      isInvalid={creditCardForm.touched.retroComission && creditCardForm.errors.retroComission ? true : false}
                    />
                    {creditCardForm.touched.retroComission && creditCardForm.errors.retroComission && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {creditCardForm.errors.retroComission}
                      </Text>
                    )}
                  </Box>
                </Flex>

                <Flex gap='20px' flexDirection={["column", "row"]}>
                  <Box w={"full"}>
                    <FormLabel fontSize={"sm"} mb='10px'>
                      Retro Partner 1
                    </FormLabel>
                    <Input
                      name='firstRetroPartnerName'
                      placeholder={"Retro Partner 1"}
                      onChange={creditCardForm.handleChange}
                      value={creditCardForm.values.firstRetroPartnerName}
                      isInvalid={creditCardForm.touched.firstRetroPartnerName && creditCardForm.errors.firstRetroPartnerName ? true : false}
                    />
                    {creditCardForm.touched.firstRetroPartnerName && creditCardForm.errors.firstRetroPartnerName && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {creditCardForm.errors.firstRetroPartnerName}
                      </Text>
                    )}
                  </Box>
                  <Box w={"full"}>
                    <FormLabel fontSize={"sm"} mb='10px'>
                      Retro Partner 1 Commission (%)
                    </FormLabel>
                    <Input
                      name='firstRetroPartnerCommission'
                      placeholder={"Retro Partner 1 Commission"}
                      onChange={(e) => {
                        creditCardForm.setFieldValue('firstRetroPartnerCommission', e.target.value.replace(/[^0-9.]/g, ''))
                      }}
                      value={creditCardForm.values.firstRetroPartnerCommission}
                      isInvalid={creditCardForm.touched.firstRetroPartnerCommission && creditCardForm.errors.firstRetroPartnerCommission ? true : false}
                    />
                    {creditCardForm.touched.firstRetroPartnerCommission && creditCardForm.errors.firstRetroPartnerCommission && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {creditCardForm.errors.firstRetroPartnerCommission}
                      </Text>
                    )}
                  </Box>
                </Flex>

                <Flex gap='20px' flexDirection={["column", "row"]}>
                  <Box w={"full"}>
                    <FormLabel fontSize={"sm"} mb='10px'>
                      Retro Partner 2
                    </FormLabel>
                    <Input
                      name='secondRetroPartnerName'
                      placeholder={"Retro Partner 2"}
                      onChange={creditCardForm.handleChange}
                      value={creditCardForm.values.secondRetroPartnerName}
                      isInvalid={creditCardForm.touched.secondRetroPartnerName && creditCardForm.errors.secondRetroPartnerName ? true : false}
                    />
                    {creditCardForm.touched.secondRetroPartnerName && creditCardForm.errors.secondRetroPartnerName && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {creditCardForm.errors.secondRetroPartnerName}
                      </Text>
                    )}
                  </Box>
                  <Box w={"full"}>
                    <FormLabel fontSize={"sm"} mb='10px'>
                      Retro Partner 2 Commission (%)
                    </FormLabel>
                    <Input
                      name='secondRetroPartnerCommission'
                      placeholder={"Retro Partner 2 Commission"}
                      onChange={(e) => {
                        creditCardForm.setFieldValue('secondRetroPartnerCommission', e.target.value.replace(/[^0-9.]/g, ''))
                      }}
                      value={creditCardForm.values.secondRetroPartnerCommission}
                      isInvalid={creditCardForm.touched.secondRetroPartnerCommission && creditCardForm.errors.secondRetroPartnerCommission ? true : false}
                    />
                    {creditCardForm.touched.secondRetroPartnerCommission && creditCardForm.errors.secondRetroPartnerCommission && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {creditCardForm.errors.secondRetroPartnerCommission}
                      </Text>
                    )}
                  </Box>
                </Flex>

                <Button
                  mt={4}
                  mb={4}
                  onClick={creditCardForm.handleSubmit}
                  colorScheme="orange"
                //isDisabled={!creditCardForm.isValid}
                >
                  Save
                </Button>
              </Flex>
            </Flex>
          </TabPanel>
          <TabPanel p={0}>
            <Flex>
              <Flex flex={1} flexDirection='column' gap='20px'>
                <Box>
                  <FormLabel fontSize={"lg"} mb='10px'>
                    Total Client Commission : {hasDecimal(totalComissionCLValue) ? totalComissionCLValue.toFixed(2) : totalComissionCLValue} %
                  </FormLabel>
                </Box>
                <Flex gap='20px' flexDirection={["column", "row"]}>
                  <Box w={"full"}>
                    <FormLabel fontSize={"sm"} mb='10px'>
                      Blink's Commission (%) <Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>
                    </FormLabel>
                    <Input
                      name='retroComission'
                      placeholder={"Blink's Commission"}
                      onChange={(e) => {
                        creditLineForm.setFieldValue('retroComission', e.target.value.replace(/[^0-9.]/g, ''))
                      }}
                      value={creditLineForm.values.retroComission}
                      isInvalid={creditLineForm.touched.retroComission && creditLineForm.errors.retroComission ? true : false}
                    />
                    {creditLineForm.touched.retroComission && creditLineForm.errors.retroComission && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {creditLineForm.errors.retroComission}
                      </Text>
                    )}
                  </Box>
                </Flex>

                <Flex gap='20px' flexDirection={["column", "row"]}>
                  <Box w={"full"}>
                    <FormLabel fontSize={"sm"} mb='10px'>
                      Retro Partner 1
                    </FormLabel>
                    <Input
                      name='firstRetroPartnerName'
                      placeholder={"Retro Partner 1"}
                      onChange={creditLineForm.handleChange}
                      value={creditLineForm.values.firstRetroPartnerName}
                      isInvalid={creditLineForm.touched.firstRetroPartnerName && creditLineForm.errors.firstRetroPartnerName ? true : false}
                    />
                    {creditLineForm.touched.firstRetroPartnerName && creditLineForm.errors.firstRetroPartnerName && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {creditLineForm.errors.firstRetroPartnerName}
                      </Text>
                    )}
                  </Box>
                  <Box w={"full"}>
                    <FormLabel fontSize={"sm"} mb='10px'>
                      Retro Partner 1 Commission (%)
                    </FormLabel>
                    <Input
                      name='firstRetroPartnerCommission'
                      placeholder={"Retro Partner 1 Commission"}
                      onChange={(e) => {
                        creditLineForm.setFieldValue('firstRetroPartnerCommission', e.target.value.replace(/[^0-9.]/g, ''))
                      }}
                      value={creditLineForm.values.firstRetroPartnerCommission}
                      isInvalid={creditLineForm.touched.firstRetroPartnerCommission && creditLineForm.errors.firstRetroPartnerCommission ? true : false}
                    />
                    {creditLineForm.touched.firstRetroPartnerCommission && creditLineForm.errors.firstRetroPartnerCommission && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {creditLineForm.errors.firstRetroPartnerCommission}
                      </Text>
                    )}
                  </Box>
                </Flex>

                <Flex gap='20px' flexDirection={["column", "row"]}>
                  <Box w={"full"}>
                    <FormLabel fontSize={"sm"} mb='10px'>
                      Retro Partner 2
                    </FormLabel>
                    <Input
                      name='secondRetroPartnerName'
                      placeholder={"Retro Partner 2"}
                      onChange={creditLineForm.handleChange}
                      value={creditLineForm.values.secondRetroPartnerName}
                      isInvalid={creditLineForm.touched.secondRetroPartnerName && creditLineForm.errors.secondRetroPartnerName ? true : false}
                    />
                    {creditLineForm.touched.secondRetroPartnerName && creditLineForm.errors.secondRetroPartnerName && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {creditLineForm.errors.secondRetroPartnerName}
                      </Text>
                    )}
                  </Box>
                  <Box w={"full"}>
                    <FormLabel fontSize={"sm"} mb='10px'>
                      Retro Partner 2 Commission (%)
                    </FormLabel>
                    <Input
                      name='secondRetroPartnerCommission'
                      placeholder={"Retro Partner 2 Commission"}
                      onChange={(e) => {
                        creditLineForm.setFieldValue('secondRetroPartnerCommission', e.target.value.replace(/[^0-9.]/g, ''))
                      }}
                      value={creditLineForm.values.secondRetroPartnerCommission}
                      isInvalid={creditLineForm.touched.secondRetroPartnerCommission && creditLineForm.errors.secondRetroPartnerCommission ? true : false}
                    />
                    {creditLineForm.touched.secondRetroPartnerCommission && creditLineForm.errors.secondRetroPartnerCommission && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {creditLineForm.errors.secondRetroPartnerCommission}
                      </Text>
                    )}
                  </Box>
                </Flex>

                <Button
                  mt={4}
                  mb={4}
                  onClick={creditLineForm.handleSubmit}
                  colorScheme="orange"
                //isDisabled={!creditLineForm.isValid}
                >
                  Save
                </Button>
              </Flex>
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </FormControl>
  );
}

export default CommissionForm;
