import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useToast
} from "@chakra-ui/react";
import { useEffect } from "react";
import {
  useGetCompanyQuery
} from "store/companies/companiesApi";

import { InfoOutlineIcon } from "@chakra-ui/icons";
import SidebarWithHeader from "components/SidebarWithHeader";
import BankDetailsVerificationForm from "components/verification/BankDetailsVerificationForm";
import LegalDetailsVerificationForm from "components/verification/LegalDetailsVerificationForm";
import { useNavigate, useParams } from "react-router-dom";
import { useActivateCompanyMutation, useSendFormFeedbackMutation } from "store/companies/verifyCompanyApi";
import { isBankDetailsApproved, isBankVerificationChecked, isComissionDetailsFilled, isLegalDetailsApproved, isLegalVerificationChecked } from "utils/verifyFormDetails";
import { useSelector } from "react-redux";

function VerifyCompanyForAdmin() {
  const { id } = useParams();

  const toast = useToast()
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.auth?.userInfo?.role);

  const {
    data: companyData,
    error: getCompanyError,
    isLoading: isCompanyDataLoading,
    refetch
  } = useGetCompanyQuery(id);

  const [sendFormFeedback, feedbackResponse] = useSendFormFeedbackMutation()
  const [activateCompany, activateResponse] = useActivateCompanyMutation()

  //console.log(companyData);
  const { companyName, companyType, entity } = companyData || {}

  useEffect(() => {
    refetch();
  }, [refetch])

  useEffect(() => {
    if (!(companyData?.verify)) {
      //navigate('/companies')
    }
  }, [companyData?.verify])


  useEffect(() => {
    if (feedbackResponse?.isSuccess) {
      toast({
        position: "top-right",
        title: "Feedback sent",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    if (feedbackResponse.status == "rejected") {
      const {
        data: { message },
      } = feedbackResponse.error;
      toast({
        position: "top-right",
        title: "Error",
        description: message,
        status: "error",
        duration: 8000,
        isClosable: true,
      });
    }
  }, [feedbackResponse])

  useEffect(() => {
    if (activateResponse?.isSuccess) {
      toast({
        position: "top-right",
        title: "Company Activated",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      navigate('/companies')
    }
    if (activateResponse.status == "rejected") {
      const {
        data: { message },
      } = activateResponse.error;
      toast({
        position: "top-right",
        title: "Error",
        description: message,
        status: "error",
        duration: 8000,
        isClosable: true,
      });
    }
  }, [activateResponse])

  const isLegalFormChecked = companyData && isLegalVerificationChecked(companyData?.entity, companyData?.legal)
  const isBankFormChecked = companyData && isBankVerificationChecked(companyData?.entity, companyData?.bank)
  const isLegalFormApproved = companyData && isLegalDetailsApproved(companyData?.entity, companyData?.legal)
  const isBankFormApproved = companyData && isBankDetailsApproved(companyData?.entity, companyData?.bank)
  const isComissionFormFilled = companyData?.commission && isComissionDetailsFilled(companyData?.commission)
  //console.log({ isLegalFormChecked });


  const handleSendFeedBackClick = async () => {
    await sendFormFeedback(id)
  }

  const handleActivateClick = async (status) => {
    const payload = { status: status }
    await activateCompany({ companyId: id, data: payload })
  }

  //companyData && //console.log({ isLegalFormChecked, isBankFormChecked });


  return (
    <SidebarWithHeader>
      {(userRole == 'admin' || userRole == 'media') ? (
        <>
          <Flex mb={"20px"} align={'center'} justify={'space-between'} >
            <Heading as='h1' size='xl'>
              Verify Company Details
            </Heading>
            <Flex gap={'10px'}>

              {/** all fields approved - activate, if all checked - send feedback */}

              {!companyData?.status && companyData?.verify ? (
                <>
                  {isLegalFormApproved ? (
                    <Flex gap={2}>
                      {(userRole == 'admin' || userRole == 'media') ? (
                        <>
                          <Button isDisabled={!isComissionFormFilled} onClick={() => handleActivateClick(1)} colorScheme={'orange'} size={'md'}>Activate {activateResponse?.isLoading ? <Spinner ml={2} size={'xs'} /> : ''}</Button>

                          {!isComissionFormFilled ? (
                            <Tooltip
                              borderRadius={5}
                              label={
                                <Box fontSize={"sm"} p={"5px"}>
                                  <Text> Please fill the commission form to proceed</Text>
                                </Box>
                              }
                              fontSize='md'
                            >
                              <InfoOutlineIcon pointerEvents={'all'} color={'red.400'} />
                            </Tooltip>
                          ) : null}
                        </>
                      ) : null}

                    </Flex>
                  ) : (
                    <>
                      {isLegalFormChecked ? (
                        <>
                          {(userRole == 'admin' || userRole == 'media') ? (
                            <Button onClick={handleSendFeedBackClick} colorScheme={'orange'} size={'md'}>Send Feedback{feedbackResponse?.isLoading ? <Spinner ml={2} size={'xs'} /> : ''}</Button>
                          ) : null}
                        </>
                      ) : null}
                    </>
                  )}

                </>
              ) : null}


            </Flex>
          </Flex>

          <Flex mb={'20px'} gap={'10px'} align={'baseline'} justify={'space-between'}>
            <Flex gap={'10px'} align={'baseline'}>
              <Heading as={'h4'} size={'sm'}>{companyName}</Heading>
              <Box>
                <Badge fontSize={'10px'} colorScheme="gray"> {companyType} </Badge>
              </Box>
              <Box>
                <Badge fontSize={'10px'} colorScheme="gray"> {entity} </Badge>
              </Box>
              <Box ml={'10px'}>
                {companyData?.status ? (
                  <Badge fontSize={'10px'} colorScheme="green">active</Badge>
                ) : null}
              </Box>
            </Flex>
            <Flex gap={'10px'} align={'baseline'}>
              <Button size={'sm'} onClick={() => navigate(`/companies/${id}`)}>View</Button>
              <Button size={'sm'} onClick={() => navigate(`/companies/${id}/edit`)}>Edit</Button>
              <Button size={'sm'} onClick={() => navigate(`/companies/${id}/verify`)} colorScheme='orange'>Verify</Button>
            </Flex>
          </Flex>
          <Flex>

            <Tabs
              position='relative'
              variant='unstyled'
              sx={{
                display: "flex",
                position: "relative",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <TabList gap={"10px"}>
                <Tab
                  borderWidth={1}
                  borderRadius={5}
                  _selected={{ color: "white", bg: "brand.200" }}
                >
                  KYC{" "}
                </Tab>
                {/* <Tab
                  borderWidth={1}
                  borderRadius={5}
                  _selected={{ color: "white", bg: "brand.200" }}
                >
                  Bank
                </Tab> */}
              </TabList>
              <Divider mt={4} />
              <TabPanels py={"40px"}>
                <TabPanel p={0}>
                  <LegalDetailsVerificationForm
                    entity={companyData && companyData?.entity}
                    details={companyData?.legal}
                    isLoading={isCompanyDataLoading}
                    canVerify={companyData?.verify}
                  />
                </TabPanel>
                {/* <TabPanel p={0}>
                  <BankDetailsVerificationForm
                    entity={companyData && companyData?.entity}
                    details={companyData?.bank}
                    isLoading={isCompanyDataLoading}
                    canVerify={companyData?.verify}
                  />
                </TabPanel> */}

              </TabPanels>
            </Tabs>

            {/* <>
           {!isLegalFormApproved || !isBankFormApproved ? (
             <Text>  Not available to verify</Text>
           ) : null}
 
         </> */}


          </Flex>
        </>
      ) :
        <Text>Sorry, you are not authorized to view this page</Text>
      }

    </SidebarWithHeader>
  );
}

export default VerifyCompanyForAdmin;
