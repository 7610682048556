import { Badge, Box, Button, Divider, Flex, FormControl, FormLabel, Spinner, Switch, Text, Textarea, useColorModeValue, useToast } from "@chakra-ui/react";
import { getIn, useFormik } from "formik";
import { boolean, object, string } from "yup";


import { useEffect, useState } from "react";
import { useApproveBankFieldMutation, useApproveLegalFieldMutation } from "store/companies/approveDocumentApi";
function FieldApprovalCard({ title, fieldData, for_, canVerify }) {
  const headingColor = useColorModeValue("gray.600", "gray.400");
  const toast = useToast()

  const { name, approved, value, _id, company, rejectReasons, checked } = fieldData || {}
  const isRejected = !approved && rejectReasons?.length > 0;

  //console.log({ fieldData });

  const [intialValues, setinitialValues] = useState({
    [name]: {
      reason: "",
      isApproved: false,
      notes: "",
    }
  })

  let borderColor;
  if (isRejected) {
    borderColor = 'red.400'
  } else if (approved) {
    borderColor = 'green.400'
  } else {
    borderColor = 'gray.400'
  }

  if (name == 'ifscCode') {
    //console.log(fieldData);
  }

  const [approveBankField, bankApprovalResponse] = useApproveBankFieldMutation()
  const [approveLegalField, legalApprovalResponse] = useApproveLegalFieldMutation()

  useEffect(() => {
    if (bankApprovalResponse?.status == "rejected") {

      toast({
        position: "top-right",
        title: "Error",
        //description: message,
        status: "error",
        duration: 8000,
        isClosable: true,
      });
    }
    if (bankApprovalResponse?.isSuccess) {
      const { companyName } = bankApprovalResponse.data;
      toast({
        position: "top-right",
        title: "Saved",
        description: companyName,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [bankApprovalResponse]);

  useEffect(() => {
    if (legalApprovalResponse?.status == "rejected") {
      const {
        data: { message },
      } = legalApprovalResponse.error;
      toast({
        position: "top-right",
        title: "Error",
        description: message,
        status: "error",
        duration: 8000,
        isClosable: true,
      });
    }
    if (legalApprovalResponse?.isSuccess) {
      const { companyName } = legalApprovalResponse.data;
      toast({
        position: "top-right",
        title: "Saved",
        description: companyName,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [legalApprovalResponse]);


  useEffect(() => {
    let reason = ""
    let notes = ""
    if (isRejected) {
      reason = rejectReasons[rejectReasons?.length - 1].reason;
      notes = rejectReasons[rejectReasons?.length - 1].notes;
    }
    setinitialValues({
      [name]: {
        reason: reason,
        isApproved: approved,
        notes: notes,
      }
    })
  }, [fieldData])


  const approvalSchema = object({
    [name]: object({
      isApproved: boolean(),
      notes: string().when("isApproved", {
        is: false,
        then: () => string().required("Required"),
      }),
    }),
  });

  const formik = useFormik({
    initialValues: intialValues || {},
    enableReinitialize: true,
    validationSchema: approvalSchema,
    onSubmit: async (values) => {
      //console.log(JSON.stringify(values, null, 2));
      if (values) {
        const { reason, isApproved, notes } = formik.getFieldProps(`${name}`).value
        const updatedNotes = isApproved ? '' : notes;
        const updatedReason = isApproved ? '' : reason;
        const payload = { id: _id, name, reason: updatedReason, value, approved: isApproved, notes: updatedNotes, checked: true }

        if (for_ == 'bank') {
          await approveBankField({ id: company, payload })
        } else {
          await approveLegalField({ id: company, payload })
        }
        formik.resetForm()
      }
    },
  });



  return (
    <Box w={"full"} pos={'relative'}>
      <Flex gap={'5px'} pos={'absolute'} top={'10px'} right={'10px'}>
        {checked ? (
          <Badge fontSize={'10px'} colorScheme="orange">checked</Badge>
        ) : null}
        {approved ? (
          <Badge fontSize={'10px'} colorScheme="green">approved</Badge>
        ) : null}
        {isRejected ? (
          <Badge fontSize={'10px'} colorScheme="red">rejected</Badge>
        ) : null}

      </Flex>

      <Box borderWidth={1} borderRadius={5} flex={1} padding={"10px"} pos={'relative'} borderColor={borderColor}>
        {legalApprovalResponse?.isLoading || bankApprovalResponse?.isLoading ? (
          <Flex pos={'absolute'} zIndex={1} background={'#00000038'} left={0} top={0} width={'100%'} h={'100%'} alignItems={'center'} justifyContent={'center'}>
            <Spinner />
          </Flex>
        ) : null}

        <Text fontSize={"sm"} color={headingColor} mb={"5px"}>
          {title}
        </Text>
        <Text fontWeight={"bold"}>{isRejected ? rejectReasons[rejectReasons?.length - 1].value : value}</Text>
        <Flex flex={1} gap={"40px"} justifyContent={"space-between"} mt={"10px"}>
          <Flex
            pointerEvents={canVerify ? "initial" : "none"}
            cursor={canVerify ? "initial" : "not-allowed"}
            opacity={canVerify ? 1 : 0.4}
            flexDirection={"column"}
            flex={1}
          >
            <Textarea
              value={formik.getFieldProps(`${name}.notes`).value}
              size={"sm"}
              name={`${name}.notes`}
              placeholder='Notes'
              onChange={formik.handleChange}
            />

            {getIn(formik.errors, `${name}.notes`) && getIn(formik.touched, `${name}.notes`) && (
              <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                {getIn(formik.errors, `${name}.notes`)}
              </Text>
            )}
          </Flex>
        </Flex>
        {canVerify ? (
          <>
            <Divider my={"10px"} />
            <Flex flexDirection={"row"} gap={"10px"} align={"center"}>
              <FormControl display={"flex"} flexDirection={"row"}>
                <FormLabel fontSize={"sm"} mb='0'>
                  Approved
                </FormLabel>
                <Switch
                  id={name}
                  onChange={formik.handleChange}
                  name={`${name}.isApproved`}
                  colorScheme='green'
                  isChecked={formik.getFieldProps(`${name}.isApproved`).value}
                  {...formik.getFieldProps(`${name}.isApproved`)}
                />
              </FormControl>
              <Button
                colorScheme="orange"
                size={"sm"}
                onClick={formik.handleSubmit}
                isDisabled={!formik.isValid}
              >Save</Button>
            </Flex>
          </>
        ) : null}
      </Box>
    </Box>
  );
}

export default FieldApprovalCard;
