import {
    Center,
    Flex,
    Icon,
    Input,
    Link,
    List,
    ListItem,
    Text,
    UnorderedList,
    useColorModeValue
} from "@chakra-ui/react";
import React from 'react';

import { useDropzone } from 'react-dropzone';
import { FiUpload } from 'react-icons/fi';

function DropBox({ onDrop, isInvalid, multiUpload }) {

    const backgroundColor = useColorModeValue('gray.50', 'gray.600');
    const iconColor = useColorModeValue('gray.800', 'gray.100');
    const linkColor = useColorModeValue('gray.800', 'gray.100');
    const invalidColor = useColorModeValue('red.100', 'red.400')
    const borderColor = useColorModeValue('gray.600', 'gray.100');

    const {
        getRootProps,
        getInputProps,
        acceptedFiles,
        open,
        isDragAccept,
        isFocused,
        isDragReject,
        isDragActive,
        fileRejections
    } = useDropzone({
        accept: {
            'image/png': ['.png', '.jpg', '.jpeg', '.pdf'],
        },
        onDrop,
        noClick: true,
        noKeyboard: true,
        maxFiles: multiUpload ? 5 : 1,
        maxSize: 10485760
    });

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <ListItem key={file.path} >
            <UnorderedList m={0}>
                {errors.map(e => (
                    <ListItem listStyleType={'none'} key={e.code}>
                        <Text textAlign={'center'} fontSize={'10px'} color={'red'}>{e.code == 'file-too-large' ? 'File is larger than 10 MB' : e.message}</Text>
                    </ListItem>
                ))}
            </UnorderedList>
        </ListItem>
    ));

    const files = acceptedFiles?.map(file => <ListItem key={file.path}><Text textAlign={'center'} fontSize={'10px'}>{file.path}</Text></ListItem>);


    // //console.log({ fileRejections });

    return (
        <>
            <Center
                className="dropzone"
                {...getRootProps()}
                sx={{
                    textAlign: 'center',
                    padding: '20px 0px',
                    width: '100%',
                    margin: '0 auto',
                    borderWidth: '1px',
                    borderColor: borderColor,
                    borderStyle: 'dashed',
                    background: isInvalid ? invalidColor : backgroundColor,
                    borderRadius: 5,
                }}
                borderColor={isDragActive ? 'gray.500' : 'gray.600'}
            >
                <Input
                    {...getInputProps()} />
                <Flex flexDirection={'column'} align={'center'} justify={'center'} gap={'10px'} minW={'220px'}>
                    <Icon as={FiUpload} fontSize="2xl" color={iconColor} />
                    <Text fontSize={'12px'}>Drag 'n' drop file or <Link textDecoration={'underline'} color={linkColor} onClick={open} size={'xs'}>
                        Browse
                    </Link>
                    </Text>
                    {fileRejectionItems && (
                        <List>
                            {fileRejectionItems}
                        </List>
                    )}
                    {multiUpload ? (
                        <List>{files}</List>
                    ) : null}
                </Flex>
            </Center>
            {/* <Dropzone
                onDrop={onDrop}
                accept={{
                    'image/jpeg': ['.jpg', '.jpeg'],
                    'image/png': ['.png'],
                    'application/pdf': ['.pdf']
                }}
                minSize={0}
                maxSize={maxSize}
                maxFiles={multiUpload ? 0 : 1}
            >
                {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles, validator: fileValidator, }) => {
                    const isFileTooLarge = rejectedFiles?.length > 0 && rejectedFiles[0].size > maxSize;
                    //console.log(rejectedFiles);
                    return (
                        <Box
                            {...getRootProps()}
                            borderColor={isDragActive ? 'teal.500' : 'gray.600'}
                            sx={{
                                textAlign: 'center',
                                padding: '20px 0px',
                                width: '100%',
                                margin: '0 auto',
                                borderWidth: '1px',
                                borderStyle: 'dashed',
                                background: isInvalid ? invalidColor : backgroundColor,
                                borderRadius: 5,
                            }}
                        >
                            <Icon as={FiUpload} fontSize="2xl" color={iconColor} />
                            <Input {...getInputProps()} />
                            <Text fontSize={'xs'}>
                                {!isDragActive && 'Click here or drop a file to upload!'}
                                {isDragActive && !isDragReject && "Drop it!"}
                                {isDragReject && "File type not accepted"}
                            </Text>
                            {isFileTooLarge && (
                                <Text color={'red.400'}>
                                    File size too large.
                                </Text>
                            )}
                        </Box>
                    )
                }
                }
            </Dropzone> */}
        </>
    );
}

export default DropBox