import {
    Badge,
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Link,
    SimpleGrid,
    Spinner,
    Stat,
    StatLabel,
    StatNumber,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import { RangeDatepicker } from 'chakra-dayzed-datepicker';
import AppTableWithPagination from 'components/AppTableWithPagination';
import SidebarWithHeader from 'components/SidebarWithHeader';
import { Field, Form, Formik } from "formik";
import moment from 'moment';
import { useMemo, useRef, useState } from 'react';
import { useGetAdAccountsForDashboardQuery, useGetDebitsQuery, useGetAccountsQuery } from 'store/adAccounts/adAccountsApi';
import { useGetOverallCreditsQuery, useGetTopupByAdAccountQuery } from 'store/topUp/topUpApi';
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    dateRange: Yup.array()
        .of(Yup.date().required("Required"))
        .min(2, "start and end date required"),
});

function Dashboard() {
    const formRef = useRef(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setsearchValue] = useState('');
    const [selectedDates, setSelectedDates] = useState(['', '']);

    /* QUERIES */

    const {
        data: overallCredits,
        isLoading: isOverallCreditsLoading,
        isFetching: isOverallCreditsFetching
    } = useGetOverallCreditsQuery({ startdate: selectedDates[0] ? moment(selectedDates[0]).format('YYYY-MM-DD') : '', enddate: selectedDates[1] ? moment(selectedDates[1]).format('YYYY-MM-DD') : '' }, {
        // skip: !selectedDates[0] || !selectedDates[1]
    });


    // const {
    //     data: overallAdsAccounts,
    //     isLoading: isOverallAdsAccountsLoading,
    //     isFetching
    // } = useGetAdAccountsForDashboardQuery({ currentPage, searchValue }, {
    //     pollingInterval: 30000,
    //     skipPollingIfUnfocused: true,
    // });

    const { data: overallAdsAccounts, isLoading: isOverallAdsAccountsLoading, isFetching, refetch } = useGetAccountsQuery({ currentPage, searchValue }, {
        pollingInterval: 30000,
        skipPollingIfUnfocused: true,
    });

    const { totalCommission, totalTopup } = overallCredits || {};

    const accountColumns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'adsAccountNumber',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{'#' + original.adsAccountNumber}</Text>)
            },

            {
                Header: 'Ad Account Name',
                accessor: 'adsAccountName',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Link href={`/dashboard/ads-accounts/${original._id}`} textDecoration={'underline'} _hover={{ color: '#ea7869' }}><Text fontSize={'14px'}>{original.adsAccountName}</Text></Link>)
            },

            // {
            //     Header: 'Top up',
            //     accessor: '',
            //     Filter: '',
            //     filter: '',
            //     Cell: ({ row: { original } }) => {
            //         // console.log(original);
            //         const { _id } = original;
            //         const { data: topupData } = useGetTopupByAdAccountQuery(_id);
            //         //console.log({ topupData });
            //         return (
            //             <>
            //                 {topupData?.data ? (
            //                     <Text fontSize={'14px'} textAlign={'right'}>{(Number(topupData?.data?.totalTopup))?.toFixed(2)}</Text>
            //                 ) : (
            //                     null
            //                 )}
            //             </>
            //         )
            //     }
            // },
            // {
            //     Header: 'Spend Cap',
            //     accessor: '',
            //     Filter: '',
            //     filter: '',
            //     Cell: ({ row: { original } }) => (<Text fontSize={'14px'} textAlign={'right'}>{Number(original.adsAccountData?.spend_cap * 0.01)?.toFixed(2)}</Text>)
            // },
            // {
            //     Header: 'amount Spent',
            //     accessor: 'amountSpent',
            //     Filter: '',
            //     filter: '',
            //     Cell: ({ row: { original } }) => (<Text fontSize={'14px'} textAlign={'right'}>{(original.amountSpent)?.toFixed(2)}</Text>)
            // },
            // {
            //     Header: 'Current Balance',
            //     accessor: '',
            //     Filter: '',
            //     filter: '',
            //     Cell: ({ row: { original } }) => {

            //         return (
            //             <>
            //                 <Text fontSize={'14px'} textAlign={'right'}>{(Number(original?.adsAccountData?.spend_cap * 0.01) - Number(original?.adsAccountData?.amountSpent * 0.01))?.toFixed(2)}</Text>
            //             </>
            //         )
            //     }
            // },
            {
                Header: 'Ads Account Id',
                accessor: 'adsAccountId',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.adsAccountId}</Text>)
            },
            {
                Header: 'Client',
                accessor: '',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Link href={`/dashboard/clients/${original.clientId?.id}`} textDecoration={'underline'} _hover={{ color: '#ea7869' }}><Text fontSize={'14px'}>{original.clientId?.name}</Text></Link>)
            },

            {
                Header: 'Company Name',
                accessor: 'companyName',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.company?.name}</Text>)
            },
            {
                Header: 'currency',
                accessor: 'currency',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.currency}</Text>)
            },
            {
                Header: 'Account status',
                accessor: 'accountStatus',
                Filter: '',
                filter: '',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    // console.log(original);
                    return (
                        <>
                            <Badge
                                fontSize={"10px"}
                                marginRight={2}
                                colorScheme={original.accountStatus == 1 ? 'green' : 'gray'}
                            >
                                {original.accountStatus == 1 ? 'Active' : 'In active'}
                            </Badge>
                        </>
                    )
                }
            },
        ],
        []
    )

    // console.log({ overallCredits });
    //console.log({ selectedDates });

    const areAllValuesFilled = (arr) => arr.every(value => value !== null);



    return (
        <SidebarWithHeader>
            {/* <Flex mb={"20px"} pb={0}>
                <Heading as='h1' size='xl'>
                    Dashboard
                </Heading>
            </Flex> */}
            <Flex justifyContent={'flex-end'} flex={1} mb={'20px'}>
                <Box>

                    {/* <Formik
                        innerRef={formRef}
                        initialValues={{ dateRange: [null, null] }}
                        validationSchema={validationSchema}
                        onSubmit={(values, actions) => {
                            //console.log("Selected Date Range:", values.dateRange);
                            setSelectedDates(values.dateRange);
                            actions.resetForm();

                        }}
                    >
                        {({ values, errors, touched, setFieldValue, submitForm }) => (
                            <Form>
                                <FormControl isInvalid={errors.dateRange && touched.dateRange}>
                                    <FormLabel htmlFor="dateRange" fontSize={'sm'}>Select Date Range</FormLabel>

                                    <Flex alignItems={'center'} gap={'10px'} flex={1}>
                                        <Field name="dateRange">
                                            {({ field }) => (
                                                <RangeDatepicker
                                                    selectedDates={values.dateRange}
                                                    propsConfigs={{
                                                        dateNavBtnProps: {
                                                            colorScheme: "purple",
                                                            // variant: "outline"
                                                        },
                                                        inputProps: {
                                                            size: "sm"
                                                        }
                                                    }}
                                                    onDateChange={(dates) => {
                                                        setFieldValue(field.name, dates)
                                                        // submitForm();
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <Button isDisabled={!areAllValuesFilled(values?.dateRange)} size={'xs'} type="submit" colorScheme="orange">
                                            Submit
                                        </Button>
                                    </Flex>
                                    {errors.dateRange && touched.dateRange ? (
                                        <FormErrorMessage>{errors.dateRange}</FormErrorMessage>
                                    ) : null}
                                </FormControl>

                            </Form>
                        )}
                    </Formik> */}


                    {/* <RangeDatepicker
                        selectedDates={selectedDates}
                        onDateChange={setSelectedDates}
                        configs={{
                            dateFormat: 'yyyy-MM-dd',
                        }}
                        propsConfigs={{
                            dateNavBtnProps: {
                                colorScheme: "purple",
                                variant: "outline"
                            },
                            dayOfMonthBtnProps: {
                                defaultBtnProps: {
                                    borderColor: "purple.300",
                                    _hover: {
                                        background: 'purple.100',
                                    }
                                },
                                selectedBtnProps: {
                                    background: "purple.400",
                                    color: "black",
                                },
                                todayBtnProps: {
                                    background: "orange.200",
                                }
                            },

                            inputProps: {
                                size: "md"
                            }
                        }}

                    /> */}
                </Box>
            </Flex>


            <Flex alignItems={'center'} justifyContent={'space-between'} minH={'120px'}>
                {(isOverallCreditsLoading || isOverallCreditsFetching) ? (
                    <Spinner mt={'20px'} />
                ) : (
                    <SimpleGrid columns={{ base: 2, md: 3 }} spacing={{ base: 5, lg: 8 }} >
                        <StatsCard title={'Total Commission'} stat={totalCommission ? totalCommission?.toFixed(2) : '-'} />
                        <StatsCard title={'Total Topup'} stat={totalTopup ? (totalTopup)?.toFixed(2) : '-'} />
                    </SimpleGrid>
                )}

                <Box minW={'225px'}>
                    <Formik
                        innerRef={formRef}
                        initialValues={{ dateRange: [null, null] }}
                        validationSchema={validationSchema}
                        onSubmit={(values, actions) => {
                            //console.log("Selected Date Range:", values.dateRange);
                            setSelectedDates(values.dateRange);
                            //actions.resetForm();

                        }}
                    >
                        {({ values, errors, touched, setFieldValue, submitForm, resetForm }) => (
                            <Form>
                                <FormControl isInvalid={errors.dateRange && touched.dateRange}>
                                    <FormLabel htmlFor="dateRange" fontSize={'sm'}>Select Date Range</FormLabel>

                                    <Flex direction={'column'} alignItems={'start'} gap={'10px'} flex={1}>
                                        <Field name="dateRange">
                                            {({ field }) => (
                                                <RangeDatepicker
                                                    selectedDates={values.dateRange}
                                                    propsConfigs={{
                                                        dateNavBtnProps: {
                                                            colorScheme: "purple",
                                                            // variant: "outline"
                                                        },
                                                        inputProps: {
                                                            size: "sm",
                                                        }
                                                    }}
                                                    onDateChange={(dates) => {
                                                        setFieldValue(field.name, dates)
                                                        // submitForm();
                                                    }}
                                                    style={{ background: 'red' }}
                                                />
                                            )}
                                        </Field>
                                        <Flex gap={'10px'}>
                                            <Button isDisabled={!areAllValuesFilled(values?.dateRange)} size={'xs'} type="submit" colorScheme="orange">
                                                Submit
                                            </Button>
                                            {areAllValuesFilled(values?.dateRange) ? (
                                                <Button
                                                    isDisabled={!areAllValuesFilled(values?.dateRange)}
                                                    size={'xs'}
                                                    colorScheme="orange"
                                                    onClick={() => {
                                                        resetForm();
                                                        setSelectedDates(['', ''])
                                                    }}
                                                >
                                                    Reset
                                                </Button>
                                            ) : null}

                                        </Flex>
                                    </Flex>
                                    {errors.dateRange && touched.dateRange ? (
                                        <FormErrorMessage>{errors.dateRange}</FormErrorMessage>
                                    ) : null}
                                </FormControl>

                            </Form>
                        )}
                    </Formik>
                </Box>

            </Flex>

            {
                isOverallAdsAccountsLoading ? (
                    <Flex minH={'400px'} alignItems={'center'} justifyContent={'center'}>
                        <Spinner mt={'20px'} />
                    </Flex>
                ) : (
                    <Box mt={'40px'}>
                        <>
                            <AppTableWithPagination
                                columns={accountColumns}
                                data={overallAdsAccounts?.data}
                                paginationMeta={overallAdsAccounts?.meta}
                                onPageChange={(index) => setCurrentPage(index)}
                                isFetching={isFetching}
                                onSearchInputChange={(searchInput) => setsearchValue(searchInput)}
                                searchEnabled={true}
                            />
                        </>
                    </Box>
                )
            }



        </SidebarWithHeader >
    )
}

function StatsCard(props) {
    const { title, stat } = props
    return (
        <Stat
            px={{ base: 4, md: 8 }}
            py={'5'}
            //shadow={'md'}
            border={'1px solid'}
            borderColor={useColorModeValue('gray.800', 'gray.500')}
            rounded={'lg'}>
            <StatLabel fontWeight={'medium'} isTruncated>
                {title}
            </StatLabel>
            <StatNumber fontSize={'xl'} fontWeight={'bold'}>
                {stat}
            </StatNumber>
        </Stat>
    )
}

export default Dashboard