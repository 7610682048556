import { Badge, Box, Button, Fade, Flex, Icon, Text, Spinner } from "@chakra-ui/react";

import React from "react";
import { FiAlertCircle } from "react-icons/fi";
import DropBox from "./DropBox";
import LightBox from "./LightBox";

function FormUploadBox({ fileKey, fileList, onFileUpload, onRemoveClick, isInvalid, multiUpload, fieldData, isUploading }) {
  //console.log(fieldData);

  const { _id, value: fileSource, rejectReasons, approved, checked } = fieldData || {}

  const getUploadedFile = (filename) => {
    return fileList?.find((doc) => doc.filename == filename);
  };

  if (isUploading) {
    return <Spinner size={"sm"} />;
  }

  return (
    <Flex flexDirection={"column"} gap={"10px"} >

      {fileSource ? (
        <Fade in={fieldData} >

          <Flex flexDirection={"column"} align={"flex-start"} gap={"10px"} minW={"220px"} pos='relative'>

            <LightBox fileSource={fileSource} />
            {!approved ? (
              <Box>
                <Button onClick={() => onRemoveClick(_id)} size='xs' colorScheme={'red'} variant={'outline'}>
                  Remove
                </Button>
              </Box>
            ) : null}

            {approved ? (
              <Badge fontSize={'10px'} colorScheme="green">approved</Badge>
            ) : null}

          </Flex>
        </Fade>
      ) : (
        <>
          {!approved ? (
            <Fade in={!getUploadedFile(fileKey)}>
              <DropBox onDrop={(file) => onFileUpload(fileKey, file)} isInvalid={isInvalid} multiUpload={multiUpload} />
            </Fade>
          ) : null}

          {!!fileSource && checked && rejectReasons?.length > 0 ? (
            <Flex align={'center'} gap={'5px'}>
              <Icon
                as={FiAlertCircle}
                boxSize={4}
                color={"red.600"}
              />
              <Text display={'flex'} fontSize={"12px"} color={"red.600"}>
                <span fontWeight={'bold'}>Rejected : &nbsp;</span> {rejectReasons[0].notes}
              </Text>
            </Flex>
          ) : null}
        </>
      )}


    </Flex>
  );
}

export default FormUploadBox;
