import { api } from "../api";

export const uploadReceiptApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTopUpRequests: builder.query({
      query: ({ currentPage, searchValue }) => `/topups?page=${currentPage}&filter=${searchValue}`,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ["TopUps"],
    }),
    addNewTopUpRequest: builder.mutation({
      query: (paload) => ({
        url: "/topups",
        method: "POST",
        body: paload,
      }),
      invalidatesTags: ["TopUps"],
    }),
    updateTopUpRequest: builder.mutation({
      query: ({ requestId, payload }) => ({
        url: `/topups/${requestId}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["TopUps"],
    }),
    approveTopUpRequest: builder.mutation({
      query: ({ requestId, payload }) => ({
        url: `/topups/${requestId}/approve`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["TopUps"],
    }),
    confirmTopUpRequest: builder.mutation({
      query: ({ requestId, payload }) => ({
        url: `/topups/${requestId}/confirm`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["TopUps"],
    }),
    removeTopUpRequest: builder.mutation({
      query: (requestId) => ({
        url: `/topups/${requestId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["TopUps"],
    }),
    uploadTopUpFile: builder.mutation({
      query: (payload) => ({
        url: "/topups/upload",
        method: "POST",
        body: payload,
      }),
    }),
    replyToTopUpRequest: builder.mutation({
      query: ({ requestId, payload }) => ({
        url: `/topups/${requestId}/reply`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["TopUps"],
    }),
    updateTopUpStatus: builder.mutation({
      query: ({ requestId, payload }) => ({
        url: `/topups/${requestId}/status`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["TopUps"],
    }),
    getOverallCredits: builder.query({
      query: ({ startdate, enddate }) => `/topups/dashboard/credits?startdate=${startdate}&enddate=${enddate}`,
      transformResponse: (response, meta, arg) => response.data,
    }),
    getTopupByAdAccount: builder.query({
      query: accountId => ({
        url: `/topups/adsaccounts/${accountId}`,
      }),
    }),
    transferAccountBalance: builder.mutation({
      query: (payload) => ({
        url: "/transfers",
        method: "POST",
        body: payload,
      }),
    }),
    getTransferList: builder.query({
      query: () => "/transfers",
      transformResponse: (response, meta, arg) => response.data,
    }),
    getOverallCLCreditsByClient: builder.query({
      query: ({ id }) => `/topups/client/${id}/credits?account_type=cl`,
      transformResponse: (response, meta, arg) => response.data,
    }),
    getOverallCCCreditsByClient: builder.query({
      query: ({ id }) => `/topups/client/${id}/credits?account_type=cc`,
      transformResponse: (response, meta, arg) => response.data,
    }),
    getTopupsByAdsAccount: builder.query({
      query: ({ id }) => `/topups/adsaccounts/${id}`,
      transformResponse: (response, meta, arg) => response.data,
    }),
    generateCLReportByClient: builder.query({
      query: ({ id }) => `/topups/client/${id}/report?account_type=cl`,
      transformResponse: (response, meta, arg) => response.data,
    }),
    generateCCReportByClient: builder.query({
      query: ({ id }) => `/topups/client/${id}/report?account_type=cc`,
      transformResponse: (response, meta, arg) => response.data,
    }),
  }),
});

export const {
  useGetTopUpRequestsQuery,
  useApproveTopUpRequestMutation,
  useRemoveTopUpRequestMutation,
  useUpdateTopUpRequestMutation,
  useAddNewTopUpRequestMutation,
  useConfirmTopUpRequestMutation,
  useUploadTopUpFileMutation,
  useReplyToTopUpRequestMutation,
  useUpdateTopUpStatusMutation,
  useGetOverallCreditsQuery,
  useGetTopupByAdAccountQuery,
  useTransferAccountBalanceMutation,
  useGetTransferListQuery,
  usePrefetch,
  useGetOverallCCCreditsByClientQuery,
  useGetOverallCLCreditsByClientQuery,
  useGetTopupsByAdsAccountQuery,
  useGenerateCCReportByClientQuery,
  useGenerateCLReportByClientQuery
} = uploadReceiptApi;
