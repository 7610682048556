import { Box, Button, Heading, Text } from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <Box textAlign="center" py={10} px={6} w={'100%'} h={'100vh'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <Box>
        <Heading
          display="inline-block"
          as="h2"
          size="4xl"
          bgGradient="linear(to-r, orange.400, orange.600)"
          backgroundClip="text">
          404
        </Heading>
        <Text fontSize="28px" mt={3} mb={2}>
          Page Not Found
        </Text>
        <Text color={'gray.500'} mb={6}>
          This page doesn’t exist. Try searching for something else!
        </Text>

        <Button
          colorScheme="orange"
          variant="solid"
          onClick={() => navigate('/')}
        >
          Back To Home
        </Button>
      </Box>
    </Box>
  );
}