import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Checkbox,
    Flex,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Skeleton,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue
} from "@chakra-ui/react";
import { CloseIcon, SearchIcon } from '@chakra-ui/icons'
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';
import React, { useRef, useState } from 'react';
import { useAsyncDebounce, useFilters, useExpanded, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import './appTable.css'
function AppTable({ columns, data = [], searchEnabled, isLoading, initialState }) {

    const backgroundColor = useColorModeValue('gray.50', 'gray.700');
    const borderColor = useColorModeValue('gray.100', 'gray.700');

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        canPreviousPage,
        canNextPage,
        page,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        selectedFlatRows,
        state: { pageIndex, globalFilter, pageSize, selectedRowIds, expanded },
        preGlobalFilteredRows,
        setGlobalFilter,
        visibleColumns,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 10, ...initialState },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,

        // hooks => {
        //     hooks.visibleColumns.push(columns => [
        //         {
        //             id: 'selection',
        //             Header: ({ getToggleAllPageRowsSelectedProps }) => (
        //                 <Box>
        //                     <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
        //                 </Box>
        //             ),
        //             Cell: ({ row }) => (
        //                 <Box>
        //                     <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        //                 </Box>
        //             ),
        //         },
        //         ...columns,
        //     ])
        // }
    );

    /* ROW SELECTION */

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }, ref) => {
            const defaultRef = React.useRef()
            const resolvedRef = ref || defaultRef

            React.useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate;
                //console.log(resolvedRef.current)
                // //console.log(resolvedRef.current.indeterminate);
            }, [resolvedRef, indeterminate])

            return (
                <>
                    {/* {<Checkbox type="checkbox" ref={resolvedRef} {...rest} />} */}
                    <input type="checkbox" ref={resolvedRef} {...rest} />
                </>
            )
        }
    )

    const renderRowSubComponent = React.useCallback(
        ({ row }) => {
            //console.log({ row });
            return (
                <Text key={row.id}>
                    {row.original.amount}
                </Text>
            )
        }
        ,
        []
    );


    /* SEARCH */

    const inputRef = useRef();
    const [searchValue, setsearchValue] = useState(globalFilter)
    const onSearchInputChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200);




    return (
        <>
            {searchEnabled && (
                <Box mb={'20px'}>
                    <InputGroup>
                        <InputLeftElement
                            pointerEvents='none'
                            children={<SearchIcon color='gray.300' />}
                        />
                        <Input
                            ref={inputRef}
                            type="text"
                            value={searchValue || ""}
                            onChange={e => {
                                setsearchValue(inputRef.current.value);
                                onSearchInputChange(inputRef.current.value);
                            }}
                            placeholder={`Search...`}
                        />
                        {searchValue && <InputRightElement cursor={'pointer'} children={<CloseIcon fontSize={14} _hover={{ color: 'gray.600' }} color='gray.300' />} onClick={() => { setGlobalFilter(''); setsearchValue(''); }} />}
                    </InputGroup>
                </Box>
            )}

            {/* <pre>
                <code>
                    {JSON.stringify(
                        {
                            selectedRowIds: selectedRowIds,
                            'selectedFlatRows[].original': selectedFlatRows.map(
                                d => d.original
                            ),
                        },
                        null,
                        2
                    )}
                </code>
            </pre> */}
            <Box mb={'40px'}>
                <TableContainer >
                    {isLoading && (
                        <Skeleton height='5px' />
                    )}
                    <Table
                        className={'sticky'}
                        variant={'simple'}
                        size='md'
                        {...getTableProps()}
                        borderWidth={1}
                        borderColor={borderColor}

                    >
                        <Thead bg={backgroundColor} >
                            {headerGroups.map(headerGroup => (
                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            <Flex align={'center'} gap={'10px'}>
                                                <Box as='span'> {column.render("Header")} </Box>
                                                {column.isSorted &&
                                                    (
                                                        <Box as="span">
                                                            {column.isSortedDesc
                                                                ? (<ArrowDownIcon boxSize={3} ml={2} />)
                                                                : (<ArrowUpIcon boxSize={3} ml={2} />)
                                                            }
                                                        </Box>
                                                    )
                                                }
                                                <Box ml={2} as='span'>{column?.canFilter ? column.render('Filter') : null}</Box>
                                            </Flex>
                                        </Th>
                                    ))}
                                </Tr>
                            ))}
                        </Thead>
                        <Tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                // //console.log(row);
                                return (
                                    <React.Fragment key={i + 'row'}>
                                        <Tr  {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return (
                                                    <Td {...cell.getCellProps()}
                                                        fontSize={'sm'}
                                                    >
                                                        {cell.render("Cell")}
                                                    </Td>
                                                );
                                            })}
                                        </Tr>
                                        {row.isExpanded ? (
                                            <Tr>
                                                <Td colSpan={visibleColumns.length}>
                                                    {renderRowSubComponent({ row })}
                                                </Td>
                                            </Tr>
                                        ) : null}
                                    </React.Fragment>
                                );
                            })}
                        </Tbody>
                    </Table>


                </TableContainer>
                {/* <Box mt={4}>Showing the first 20 results of {rows.length} rows</Box> */}
                {pageCount > 1 && (
                    <Flex align={'center'} justify={['start', 'end']} mt={'40px'} gap={'5px'}>
                        <Box mr={2} fontSize='sm'>
                            Page{' '}
                            <Text fontWeight={'bold'} display={'inline-flex'}>
                                {pageIndex + 1} of {pageOptions.length}
                            </Text>{' '}
                        </Box>
                        <Button size={'xs'} onClick={() => gotoPage(0)} isDisabled={!canPreviousPage}>
                            <Icon boxSize={4} as={FiChevronsLeft} />
                        </Button>{' '}
                        <Button size={'xs'} onClick={() => previousPage()} isDisabled={!canPreviousPage}>
                            <Icon boxSize={4} as={FiChevronLeft} />
                        </Button>{' '}
                        <Button size={'xs'} onClick={() => nextPage()} isDisabled={!canNextPage}>
                            <Icon boxSize={4} as={FiChevronRight} />
                        </Button>{' '}
                        <Button size={'xs'} onClick={() => gotoPage(pageCount - 1)} isDisabled={!canNextPage}>
                            <Icon boxSize={4} as={FiChevronsRight} />
                        </Button>{' '}

                    </Flex>
                )}
            </Box>
        </>
    )
}

export default AppTable