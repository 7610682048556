import { api } from "../api";

export const approveDocumentApi = api.injectEndpoints({
    endpoints: (builder) => ({
        approveLegalField: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/companies/legal/${id}/approve`,
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: ["Company"],
        }),
        approveBankField: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/companies/bank/${id}/approve`,
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: ["Company"],
        }),
    }),
});

export const { useApproveBankFieldMutation, useApproveLegalFieldMutation } = approveDocumentApi;
