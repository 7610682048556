import {
  Badge, Box, Button, Divider, Flex, Grid, Text, useDisclosure, Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent, Tooltip,
  ModalHeader,
  ModalOverlay, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, useToast, useColorModeValue
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  useAddIndianBankDetailsMutation,
  useAddIndianLegalDetailsMutation,
  useAddNonIndianBankDetailsMutation,
  useAddNonIndianLegalDetailsMutation,
  useEditCompanyMutation,
  useGetCompanyQuery,
  useEditIndianBankDetailsMutation,
  useEditNonIndianBankDetailsMutation
} from "store/companies/companiesApi";

import SidebarWithHeader from "components/SidebarWithHeader";
import BankDetailsForm from "components/onBoarding/BankDetailsForm";
import GeneralDetailsForm from "components/onBoarding/GeneralDetailsForm";
import LegalDetailsForm from "components/onBoarding/LegalDetailsForm";
import { useNavigate, useParams } from "react-router-dom";
import { useVerifyCompanyMutation } from "store/companies/verifyCompanyApi";
import { isBankFormFulfilled, isLegalFormFulfilled } from "utils/verifyFormDetails";
import { AddIcon, DeleteIcon, EditIcon, InfoOutlineIcon, ViewIcon } from "@chakra-ui/icons";

function EditCompanyForClient() {
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const addBoxBorder = useColorModeValue("gray.400", "gray.200");
  const addBoxHoverBackground = useColorModeValue("gray.50", "gray.700");
  const boxBorderColor = useColorModeValue("gray.400", "gray.200");
  const editIconColor = useColorModeValue("gray.600", "gray.400");
  const editIconHoverColor = useColorModeValue("gray.500", "gray.300");
  const deleteIconColor = useColorModeValue("red.600", "red.400");
  const deleteIconHoverColor = useColorModeValue("red.500", "red.300");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: onEditModalClose } = useDisclosure();

  const [activeBank, setactiveBank] = useState(null);
  /* QUERIES */

  const { data: companyData, error: getCompanyError, isLoading: isCompanyDataLoading, refetch } = useGetCompanyQuery(id);

  const [addIndianLegalDetails, indianLegalResposnse] = useAddIndianLegalDetailsMutation();
  const [addNonIndianLegalDetails, nonIndianLegalResponse] = useAddNonIndianLegalDetailsMutation();
  const [addIndianBankDetails, indianBankResponse] = useAddIndianBankDetailsMutation();
  const [addNonIndianBankDetails, nonIndianBankResponse] = useAddNonIndianBankDetailsMutation();
  const [editIndianBankDetails, editIndianBankResponse] = useEditIndianBankDetailsMutation();
  const [editNonIndianBankDetails, editNonIndianBankResponse] = useEditNonIndianBankDetailsMutation();

  const [editCompany, editResponse] = useEditCompanyMutation();
  const [verifyCompany, verifyResponse] = useVerifyCompanyMutation();

  useEffect(() => {
    refetch();
  }, [refetch])

  useEffect(() => {
    //console.log(result);
    if (verifyResponse.status == "rejected") {
      const {
        data: { message },
      } = verifyResponse.error;
      toast({
        position: "top-right",
        title: "Error",
        //description: message,
        status: "error",
        duration: 8000,
        isClosable: true,
      });
    }
    if (verifyResponse?.data) {
      toast({
        position: "top-right",
        title: "Sent for verification",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [verifyResponse]);


  useEffect(() => {
    //console.log(uploadResult);
    if (

      editIndianBankResponse?.isSuccess
    ) {
      toast({
        position: "top-right",
        title: "Details saved",
        status: "Details Saved",
        duration: 4000,
        isClosable: true,
      });
    }
    if (
      (editIndianBankResponse) ==
      "rejected"
    ) {
      toast({
        position: "top-right",
        title: "Error saving details",
        //description: message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [editIndianBankResponse]);

  useEffect(() => {
    //console.log(uploadResult);
    if (

      editNonIndianBankResponse?.isSuccess
    ) {
      toast({
        position: "top-right",
        title: "Details saved",
        status: "Details Saved",
        duration: 4000,
        isClosable: true,
      });
    }
    if (
      (editNonIndianBankResponse) ==
      "rejected"
    ) {
      toast({
        position: "top-right",
        title: "Error saving details",
        //description: message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [editNonIndianBankResponse]);


  useEffect(() => {
    //console.log(uploadResult);
    if (
      indianLegalResposnse?.isSuccess
    ) {
      toast({
        position: "top-right",
        title: "Details saved",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    if (
      (indianLegalResposnse.status) ==
      "rejected"
    ) {
      toast({
        position: "top-right",
        title: "Error saving details",
        //description: message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [indianLegalResposnse]);

  useEffect(() => {
    //console.log(uploadResult);
    if (
      nonIndianLegalResponse?.isSuccess
    ) {
      toast({
        position: "top-right",
        title: "Details saved",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    if (
      (nonIndianLegalResponse.status) ==
      "rejected"
    ) {
      toast({
        position: "top-right",
        title: "Error saving details",
        //description: message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [nonIndianLegalResponse]);

  useEffect(() => {
    //console.log(uploadResult);
    if (
      indianBankResponse?.isSuccess
    ) {
      toast({
        position: "top-right",
        title: "Details saved",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    if (
      (indianBankResponse) ==
      "rejected"
    ) {
      toast({
        position: "top-right",
        title: "Error saving details",
        //description: message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [indianBankResponse]);

  useEffect(() => {
    //console.log(uploadResult);
    if (

      nonIndianBankResponse?.isSuccess
    ) {
      toast({
        position: "top-right",
        title: "Details saved",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    if (
      (nonIndianBankResponse) ==
      "rejected"
    ) {
      toast({
        position: "top-right",
        title: "Error saving details",
        //description: message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [nonIndianBankResponse]);


  const isLegalFormDone = companyData && isLegalFormFulfilled(companyData?.entity, companyData?.legal);
  const isBankFormDone = companyData && isBankFormFulfilled(companyData?.entity, companyData?.bank);



  const { companyName, companyType, entity, verify, bank } = companyData || {}

  // useEffect(() => {
  //   if (verify)
  //     navigate('/companies')
  // }, [verify])

  //console.log(companyData && companyData);

  const handleLegalDetailsFormSubmit = async (values) => {
    //console.log(values);
    companyData?.entity == "indian"
      ? await addIndianLegalDetails({
        companyId: id,
        payload: values,
      })
      : await addNonIndianLegalDetails({
        companyId: id,
        payload: values,
      });
  };

  const handleBankDetailsFormSubmit = async (values) => {
    //console.log(values);
    const payload = {
      ...values,
      company: id
    }

    // console.log({ payload });
    companyData?.entity == "indian"
      ? await addIndianBankDetails({
        payload,
      })
      : await addNonIndianBankDetails({
        payload,
      });

    onClose();
  };

  const handleEditBankDetailsFormSubmit = async (values) => {
    //console.log(values);
    companyData?.entity == "indian"
      ? await editIndianBankDetails({
        id: activeBank?._id,
        payload: values,
      })
      : await editNonIndianBankDetails({
        id: activeBank?._id,
        payload: values,
      });

    onEditModalClose();

  };


  const handleProceedToVerifyClick = async (id) => {
    await verifyCompany(id);
    navigate('/companies')

  }

  const handleGeneralDetailsFormSubmit = async (values) => {
    //console.log(values);
    await editCompany({ companyId: id, data: values })
      .unwrap()
      .then((payload) => {
        const { status } = payload;
        if (status == 'success') {
          toast({
            position: "top-right",
            title: "Details saved",
            status: "success",
            duration: 4000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        toast({
          position: "top-right",
          title: "Error",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      });
  }



  const bankDetailsList = bank?.map((bankItem, index) => {

    const { _id,
      createdDate,
      updatedDate,
      company,
      accountName,
      accountNumber,
      bankName,
      branchAddress,
      bankingPartner,
      accountAddress,
      swift,
      country, } = bankItem;

    return (
      (
        <Flex
          borderWidth={1}
          borderColor={boxBorderColor}
          borderRadius={5}
          p={"20px"}
          w='100%'
          minH={"200px"}
          justifyContent={"space-between"}
          flexDirection={"column"}
          key={index}
          pos={'relative'}
        >
          <Box >
            <Heading as='h3' size={"md"} mb={2} mt={'5px'}>
              Bank: {bankName}
            </Heading>
            <Text fontSize={'16px'} >Acc Name: {accountName}</Text>
            <Text fontSize={'16px'} fontWeight={'bold'}>Acc No: {accountNumber}</Text>
            <Text fontSize={'14px'} color={'gray.600'}>Address: {branchAddress}</Text>
          </Box>

          <Flex gap={"20px"} mt={"20px"}>
            <>
              <Tooltip label='Edit' fontSize='xs' >
                <EditIcon
                  transition={"0.2s ease"}
                  color={editIconColor}
                  _hover={{ cursor: "pointer", color: editIconHoverColor }}
                  onClick={() => {
                    setactiveBank(bankItem);
                    onEditModalOpen();
                  }}
                />
              </Tooltip>
              {/* <DeleteIcon
                transition={"0.2s ease"}
                color={deleteIconColor}
                _hover={{ cursor: "pointer", color: deleteIconHoverColor }}
                onClick={() => { }}
              /> */}
            </>
          </Flex>
        </Flex>
      )
    )
  });

  return (
    <>
      {/** EDIT BANK DETAILS */}

      <Modal
        size={"2xl"}
        isCentered={true}
        isOpen={isEditModalOpen}
        scrollBehavior={"inside"}
        onClose={() => {
          onEditModalClose();
          setactiveBank(null)
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Bank Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <BankDetailsForm
              companyId={id}
              isLoading={isCompanyDataLoading}
              entity={companyData && companyData?.entity}
              onBankDetailsFormSubmit={handleEditBankDetailsFormSubmit}
              details={activeBank}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/** ADD NEW BANK DETAILS */}

      <Modal
        size={"2xl"}
        isCentered={true}
        isOpen={isOpen}
        scrollBehavior={"inside"}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Bank Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <BankDetailsForm
              companyId={id}
              isLoading={isCompanyDataLoading}
              entity={companyData && companyData?.entity}
              onBankDetailsFormSubmit={handleBankDetailsFormSubmit}
              details={companyData?.bank}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <SidebarWithHeader>
        <Flex mb={"20px"} justify={'space-between'} align={'center'}>
          <Heading as='h1' size='xl'>
            Company Details
          </Heading>
          {companyData && !companyData?.verify && isLegalFormDone ? (
            <Button colorScheme="orange" size={'sm'} onClick={() => handleProceedToVerifyClick(id)}>Submit for Verification</Button>
          ) : null}
        </Flex>
        <Flex mb={'20px'} gap={'10px'} align={'baseline'}>
          <Heading as={'h4'} size={'sm'}>{companyName}</Heading>
          <Box>
            <Badge fontSize={'10px'} colorScheme="gray"> {companyType} </Badge>
          </Box>
          <Box>
            <Badge fontSize={'10px'} colorScheme="gray"> {entity} </Badge>
          </Box>
        </Flex>
        <Flex >

          <Tabs
            position='relative'
            variant='unstyled'
            sx={{
              display: "flex",
              position: "relative",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <TabList gap={"10px"}>
              {!verify ? (
                <Tab borderWidth={1} borderRadius={5} _selected={{ color: "white", bg: "gray.500" }}>
                  General{" "}
                </Tab>
              ) : null}

              {!verify ? (
                <Tab borderWidth={1} borderRadius={5} _selected={{ color: "white", bg: "gray.500" }}>
                  KYC{" "}
                </Tab>
              ) : null}

              <Tab borderWidth={1} borderRadius={5} _selected={{ color: "white", bg: "gray.500" }}>
                Bank
              </Tab>
            </TabList>
            <Divider mt={4} />
            <TabPanels py={"40px"}>
              {!verify ? (
                <TabPanel>
                  <GeneralDetailsForm
                    companyId={id}
                    isLoading={isCompanyDataLoading}
                    entity={companyData && companyData?.entity}
                    onGeneralDetailsFormSubmit={handleGeneralDetailsFormSubmit}
                    details={companyData}
                  />
                </TabPanel>
              ) : null}

              {!verify ? (
                <TabPanel p={0}>
                  <LegalDetailsForm
                    companyId={id}
                    isLoading={isCompanyDataLoading}
                    entity={companyData && companyData?.entity}
                    onLegalDetailsFormSubmit={handleLegalDetailsFormSubmit}
                    details={companyData?.legal}
                  />
                </TabPanel>
              ) : null}

              <TabPanel p={0}>
                <Box >
                  <Grid gridTemplateColumns={"repeat( auto-fill, minmax(240px, 1fr) )"} gap={"20px"}>
                    <Flex
                      borderWidth={1}
                      borderColor={addBoxBorder}
                      borderRadius={5}
                      p={"20px"}
                      w='100%'
                      minH={"200px"}
                      _hover={{
                        bg: addBoxHoverBackground,
                        cursor: "pointer",
                      }}
                      alignItems={"center"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                      onClick={onOpen}
                    >
                      <AddIcon boxSize={6} mb={2} />
                      <Text size={"md"}>Add New</Text>
                    </Flex>

                    {bankDetailsList}
                  </Grid>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>

        </Flex>
      </SidebarWithHeader>
    </>
  );
}

export default EditCompanyForClient;
