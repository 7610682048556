import { Global } from '@emotion/react'

const Fonts = () => (
    <Global
        styles={`
        /* latin */
      @font-face {
        font-family: 'Katwijk-Black';
        src: url('./fonts/KatwijkMono-Black.woff2') format('woff2'),
            url('./fonts/KatwijkMono-Black.woff') format('woff');
        font-weight: 800;
        font-style: normal;
        font-display: swap;
    }
    /* latin */
    @font-face {
        font-family: 'Katwijk-Light';
        src: url('./fonts/KatwijkMono-Light.woff2') format('woff2'),
            url('./fonts/KatwijkMono-Light.woff') format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }
    /* latin */
    @font-face {
        font-family: 'Katwijk-Bold';
        src: url('./fonts/KatwijkMono-Bold.woff2') format('woff2'),
            url('./fonts/KatwijkMono-Bold.woff') format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }
    /* latin */
    @font-face {
        font-family: 'Katwijk-Regular';
        src: url('./fonts/KatwijkMono-Regular.woff2') format('woff2'),
            url('./fonts/KatwijkMono-Regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
      `}
    />
)

export default Fonts