import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
  Center,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

import { Formik } from "formik";
import { useLoginUserMutation } from "store/auth/authApi";
import { loginSchema } from "utils/schema";
import HomeBanner from "./HomeBanner";
import BackImage from "assets/images/bg-dots.png";

function SignIn() {
  const [showPassword, setShowPassword] = useState(false);
  const userInfo = useSelector((state) => state.auth?.userInfo);

  const [loginUser, { data, error, isLoading, isFetching }] =
    useLoginUserMutation();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const toast = useToast();
  const textColor = useColorModeValue("gray.400", "white");

  useEffect(() => {
    if (userInfo) navigate(from);
  }, []);

  useEffect(() => {
    // //console.log({ data });
    if (error) {
      //console.log({ error });
      const { message } = error;
      toast({
        position: "top-right",
        title: "Error",
        description: message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
    if (data?._id) {
      //console.log(data);
      //dispatch(setUser(data))
      navigate(from, { replace: true });
    }
  }, [error, data, isFetching]);

  return (
    <Box bg={useColorModeValue("white", "#231f20")}>
      <Formik
        validationSchema={loginSchema}
        initialValues={{ email: "", password: "" }}
        onSubmit={async (values) => {
          //alert(JSON.stringify(values));
          if (values) {
            await loginUser(values);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
        }) => (
          <>
            <Flex position='relative'>
              <Flex
                h={{ sm: "initial", md: "100vh", lg: "100vh" }}
                w='100%'
                maxW='1044px'
                mx='auto'
                justifyContent='space-between'
                pt={{ sm: "100px", md: "0px" }}
              >
                <Flex
                  alignItems='center'
                  justifyContent='start'
                  style={{ userSelect: "none" }}
                  w={{ base: "100%", md: "50%", lg: "42%" }}
                >
                  <Flex
                    direction='column'
                    w='100%'
                    background='transparent'
                    p='48px'
                  >
                    <FormControl id='email_' isRequired mb='20px'>
                      <FormLabel>Email</FormLabel>
                      <Input
                        id='email'
                        borderRadius='5px'
                        fontSize='sm'
                        type='email'
                        size='lg'
                        name='email'
                        placeholder='Email ID'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        isInvalid={touched.email && errors.email ? true : false}
                        isDisabled={isLoading}
                      />
                    </FormControl>

                    <FormControl id='password_' isRequired mb={"20px"}>
                      <FormLabel>Password</FormLabel>
                      <InputGroup>
                        <Input
                          type={showPassword ? "text" : "password"}
                          id='password'
                          borderRadius='5px'
                          fontSize='sm'
                          size='lg'
                          placeholder='Password'
                          name='password'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          isInvalid={
                            touched.password && errors.password ? true : false
                          }
                          isDisabled={isLoading}
                        />
                        <InputRightElement h={"full"}>
                          <Button
                            variant={"ghost"}
                            onClick={() =>
                              setShowPassword((showPassword) => !showPassword)
                            }
                          >
                            {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>

                    <Box>
                      <Flex justifyContent={"end"}>
                        <Link
                          href={"/forgot-password-change"}
                          color={"brand.200"}
                          fontSize={"13px"}
                        >
                          Forgot password?
                        </Link>
                      </Flex>

                      <Button
                        fontSize='14px'
                        type='submit'
                        w='100%'
                        h='45'
                        my='20px'
                        color='white'
                        colorScheme='brand'
                        _hover={{
                          bg: "brand.300",
                        }}
                        bg={"brand.200"}
                        onClick={handleSubmit}
                        isDisabled={!isValid || isLoading}
                      >
                        {isLoading ? <Spinner /> : "SIGN IN"}
                      </Button>
                    </Box>
                    <Flex
                      flexDirection='column'
                      justifyContent='center'
                      alignItems='center'
                      maxW='100%'
                      mt='0px'
                    >
                      <Text color={textColor} fontWeight='medium'>
                        Don't have an account?
                        <Link
                          color={"brand.200"}
                          as={RouterLink}
                          ms='5px'
                          fontWeight='bold'
                          to={"/signUp"}
                        >
                          Sign Up
                        </Link>
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
                <Box
                  display={{ base: "none", md: "block" }}
                  overflowX='hidden'
                  h='100%'
                  w='50%'
                  position='absolute'
                  right='0px'
                  bg={"#231f20"}
                  backgroundImage={BackImage}
                  backgroundPosition='center'
                  backgroundRepeat='no-repeat'
                  backgroundSize={"cover"}
                >
                  <Center
                    justifyContent='flex-start'
                    w='100%'
                    h='100%'
                    bgSize='cover'
                    bgPosition='50%'
                    position='absolute'
                  >
                    <Box>
                      <HomeBanner w='100%' />
                    </Box>
                  </Center>
                </Box>
              </Flex>
            </Flex>
          </>
        )}
      </Formik>
    </Box>
  );
}

export default SignIn;
