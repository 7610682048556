import {
  Badge, Box, Button, Modal,
  ModalBody,
  ModalCloseButton, Tooltip,
  ModalContent, ModalOverlay,
  ModalHeader, Divider, Flex, Grid, Text, Heading, useDisclosure, Tab, TabList, TabPanel, useColorModeValue, TabPanels, Tabs, useToast,
  Spinner
} from "@chakra-ui/react";
import SidebarWithHeader from "components/SidebarWithHeader";
import BankDetailsForm from "components/onBoarding/BankDetailsForm";
import CommissionForm from "components/onBoarding/CommissionForm";
import GeneralDetailsForm from "components/onBoarding/GeneralDetailsForm";
import LegalDetailsForm from "components/onBoarding/LegalDetailsForm";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAddCreditCardCommissionDetailsMutation, useAddCommissionDetailsMutation } from "store/companies/commisionApi";
import {
  useAddIndianBankDetailsMutation,
  useAddIndianLegalDetailsMutation,
  useAddNonIndianBankDetailsMutation,
  useAddNonIndianLegalDetailsMutation,
  useEditCompanyMutation,
  useGetCompanyQuery,
  useEditIndianBankDetailsMutation,
  useEditNonIndianBankDetailsMutation
} from "store/companies/companiesApi";
import { useVerifyCompanyMutation } from "store/companies/verifyCompanyApi";
import { isBankFormFulfilled, isLegalFormFulfilled } from "utils/verifyFormDetails";
import { AddIcon, DeleteIcon, EditIcon, InfoOutlineIcon, ViewIcon } from "@chakra-ui/icons";
import { useActivateCompanyMutation, useSendFormFeedbackMutation } from "store/companies/verifyCompanyApi";
import { isBankDetailsApproved, isBankVerificationChecked, isComissionDetailsFilled, isLegalDetailsApproved, isLegalVerificationChecked } from "utils/verifyFormDetails";


function EditCompanyForAdmin() {
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.auth?.userInfo?.role);
  const addBoxBorder = useColorModeValue("gray.400", "gray.200");
  const addBoxHoverBackground = useColorModeValue("gray.50", "gray.700");
  const boxBorderColor = useColorModeValue("gray.400", "gray.200");
  const editIconColor = useColorModeValue("gray.600", "gray.400");
  const editIconHoverColor = useColorModeValue("gray.500", "gray.300");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: onEditModalClose } = useDisclosure();

  const [activeBank, setactiveBank] = useState(null);

  /* QUERIES */

  const { data: companyData, error: getCompanyError, isLoading: isCompanyDataLoading, refetch } = useGetCompanyQuery(id);

  const [addIndianLegalDetails, indianLegalResposnse] = useAddIndianLegalDetailsMutation();
  const [addNonIndianLegalDetails, nonIndianLegalResponse] = useAddNonIndianLegalDetailsMutation();
  const [addIndianBankDetails, indianBankResponse] = useAddIndianBankDetailsMutation();
  const [addNonIndianBankDetails, nonIndianBankResponse] = useAddNonIndianBankDetailsMutation();
  const [addCommissionDetails, commissionResponse] = useAddCommissionDetailsMutation();
  const [editCompany, editResponse] = useEditCompanyMutation();
  const [verifyCompany, verifyResponse] = useVerifyCompanyMutation();
  const [editIndianBankDetails, editIndianBankResponse] = useEditIndianBankDetailsMutation();
  const [editNonIndianBankDetails, editNonIndianBankResponse] = useEditNonIndianBankDetailsMutation();
  const [activateCompany, activateResponse] = useActivateCompanyMutation()

  useEffect(() => {
    refetch();
  }, [refetch])

  // useEffect(() => {
  //   if(userRole == 'finance'){

  //   }
  // }, [userRole])


  useEffect(() => {
    //console.log(result);
    if (verifyResponse.status == "rejected") {
      const {
        data: { message },
      } = verifyResponse.error;
      toast({
        position: "top-right",
        title: "Error",
        //description: message,
        status: "error",
        duration: 8000,
        isClosable: true,
      });
    }
    if (verifyResponse?.data) {
      toast({
        position: "top-right",
        title: "Sent for verification",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [verifyResponse]);

  useEffect(() => {
    //console.log(uploadResult);
    if (
      indianLegalResposnse?.isSuccess
    ) {
      toast({
        position: "top-right",
        title: "Details saved",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    if (
      (indianLegalResposnse.status) ==
      "rejected"
    ) {
      toast({
        position: "top-right",
        title: "Error saving details",
        //description: message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [indianLegalResposnse]);

  useEffect(() => {
    //console.log(uploadResult);
    if (
      nonIndianLegalResponse?.isSuccess
    ) {
      toast({
        position: "top-right",
        title: "Details saved",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    if (
      (nonIndianLegalResponse.status) ==
      "rejected"
    ) {
      toast({
        position: "top-right",
        title: "Error saving details",
        //description: message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [nonIndianLegalResponse]);

  useEffect(() => {
    //console.log(uploadResult);
    if (
      indianBankResponse?.isSuccess
    ) {
      toast({
        position: "top-right",
        title: "Details saved",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    if (
      (indianBankResponse) ==
      "rejected"
    ) {
      toast({
        position: "top-right",
        title: "Error saving details",
        //description: message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [indianBankResponse]);

  useEffect(() => {
    //console.log(uploadResult);
    if (

      nonIndianBankResponse?.isSuccess
    ) {
      toast({
        position: "top-right",
        title: "Details saved",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    if (
      (nonIndianBankResponse) ==
      "rejected"
    ) {
      toast({
        position: "top-right",
        title: "Error saving details",
        //description: message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [nonIndianBankResponse]);

  useEffect(() => {
    //console.log(uploadResult);
    if (
      commissionResponse?.isSuccess
    ) {
      toast({
        position: "top-right",
        title: "Details saved",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    if (
      (commissionResponse) ==
      "rejected"
    ) {
      toast({
        position: "top-right",
        title: "Error saving details",
        //description: message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [commissionResponse]);

  useEffect(() => {
    //console.log(uploadResult);
    if (

      editIndianBankResponse?.isSuccess
    ) {
      toast({
        position: "top-right",
        title: "Details saved",
        status: "Details Saved",
        duration: 4000,
        isClosable: true,
      });
    }
    if (
      (editIndianBankResponse) ==
      "rejected"
    ) {
      toast({
        position: "top-right",
        title: "Error saving details",
        //description: message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [editIndianBankResponse]);

  useEffect(() => {
    //console.log(uploadResult);
    if (

      editNonIndianBankResponse?.isSuccess
    ) {
      toast({
        position: "top-right",
        title: "Details saved",
        status: "Details Saved",
        duration: 4000,
        isClosable: true,
      });
    }
    if (
      (editNonIndianBankResponse) ==
      "rejected"
    ) {
      toast({
        position: "top-right",
        title: "Error saving details",
        //description: message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [editNonIndianBankResponse]);

  useEffect(() => {
    if (activateResponse?.isSuccess) {
      toast({
        position: "top-right",
        title: "Company Activated",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      navigate('/companies')
    }
    if (activateResponse.status == "rejected") {
      const {
        data: { message },
      } = activateResponse.error;
      toast({
        position: "top-right",
        title: "Error",
        description: message,
        status: "error",
        duration: 8000,
        isClosable: true,
      });
    }
  }, [activateResponse])

  const { companyName, companyType, entity, bank } = companyData || {}

  const isLegalFormDone = companyData && isLegalFormFulfilled(companyData?.entity, companyData?.legal);
  const isBankFormDone = companyData && isBankFormFulfilled(companyData?.entity, companyData?.bank);

  const handleLegalDetailsFormSubmit = async (values) => {
    //console.log(values);
    companyData?.entity == "indian"
      ? await addIndianLegalDetails({
        companyId: companyData?._id,
        payload: values,
      })
      : await addNonIndianLegalDetails({
        companyId: companyData?._id,
        payload: values,
      });
  };

  const handleBankDetailsFormSubmit = async (values) => {
    //console.log(values);
    const payload = {
      ...values,
      company: id
    }

    // console.log({ payload });
    companyData?.entity == "indian"
      ? await addIndianBankDetails({
        payload,
      })
      : await addNonIndianBankDetails({
        payload,
      });

    onClose();
  };

  const handleEditBankDetailsFormSubmit = async (values) => {
    //console.log(values);


    // console.log({ payload });
    companyData?.entity == "indian"
      ? await editIndianBankDetails({
        id: activeBank?._id,
        payload: values,
      })
      : await editNonIndianBankDetails({
        id: activeBank?._id,
        payload: values,
      });

    onEditModalClose();

  };

  const handleCommissionFormSubmit = async (values) => {
    await addCommissionDetails({
      companyId: companyData?._id,
      payload: values,
    })
  };

  const handleGeneralDetailsFormSubmit = async (values) => {
    // //console.log(values);
    await editCompany({ companyId: companyData?._id, data: values })
      .unwrap()
      .then((payload) => {
        const { status } = payload;
        if (status == 'success') {
          toast({
            position: "top-right",
            title: "Details saved",
            status: "success",
            duration: 4000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        toast({
          position: "top-right",
          title: "Error",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      });
  }

  const handleProceedToVerifyClick = async (id) => {
    await verifyCompany(id).unwrap().then(() => (
      navigate(`/companies/${id}/verify`)
    ))
  }

  const bankDetailsList = bank?.map((bankItem, index) => {

    const { _id,
      createdDate,
      updatedDate,
      company,
      accountName,
      accountNumber,
      bankName,
      branchAddress,
      bankingPartner,
      accountAddress,
      swift,
      country, } = bankItem;

    return (
      (
        <Flex
          borderWidth={1}
          borderColor={boxBorderColor}
          borderRadius={5}
          p={"20px"}
          w='100%'
          minH={"200px"}
          justifyContent={"space-between"}
          flexDirection={"column"}
          key={index}
          pos={'relative'}
        >
          <Box >
            <Heading as='h3' size={"md"} mb={2} mt={'5px'}>
              Bank: {bankName}
            </Heading>
            <Text fontSize={'16px'} >Acc Name: {accountName}</Text>
            <Text fontSize={'16px'} fontWeight={'bold'}>Acc No: {accountNumber}</Text>
            <Text fontSize={'14px'} color={'gray.600'}>Address: {branchAddress}</Text>
          </Box>

          <Flex gap={"20px"} mt={"20px"}>
            <>
              <Tooltip label='Edit' fontSize='xs' >
                <EditIcon
                  transition={"0.2s ease"}
                  color={editIconColor}
                  _hover={{ cursor: "pointer", color: editIconHoverColor }}
                  onClick={() => {
                    setactiveBank(bankItem);
                    onEditModalOpen();
                  }}
                />
              </Tooltip>
              {/* <DeleteIcon
                transition={"0.2s ease"}
                color={deleteIconColor}
                _hover={{ cursor: "pointer", color: deleteIconHoverColor }}
                onClick={() => { }}
              /> */}
            </>
          </Flex>
        </Flex>
      )
    )
  });

  const isComissionFormFilled = companyData?.commission && isComissionDetailsFilled(companyData?.commission)
  const isLegalFormApproved = companyData && isLegalDetailsApproved(companyData?.entity, companyData?.legal)


  const handleActivateClick = async (status) => {
    const payload = { status: status }
    await activateCompany({ companyId: id, data: payload })
  }

  return (
    <>
      {/** ADD NEW BANK DETAILS */}

      <Modal
        size={"2xl"}
        isCentered={true}
        isOpen={isOpen}
        scrollBehavior={"inside"}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Bank Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <BankDetailsForm
              companyId={id}
              isLoading={isCompanyDataLoading}
              entity={companyData && companyData?.entity}
              onBankDetailsFormSubmit={handleBankDetailsFormSubmit}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/** EDIT BANK DETAILS */}

      <Modal
        size={"2xl"}
        isCentered={true}
        isOpen={isEditModalOpen}
        scrollBehavior={"inside"}
        onClose={() => {
          onEditModalClose();
          setactiveBank(null)
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Bank Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <BankDetailsForm
              companyId={id}
              isLoading={isCompanyDataLoading}
              entity={companyData && companyData?.entity}
              onBankDetailsFormSubmit={handleEditBankDetailsFormSubmit}
              details={activeBank}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <SidebarWithHeader>
        {(userRole == 'admin' || userRole == 'media' || userRole == 'finance') ? (
          <>
            <Flex mb={"20px"} justify={'space-between'} align={'center'}>
              <Heading as='h1' size='xl'>
                Edit Company Details
              </Heading>

              {companyData && !companyData.verify && isLegalFormDone && isBankFormDone ? (
                <>
                  {userRole == 'admin' ? (
                    <Button colorScheme="orange" size={'sm'} onClick={() => handleProceedToVerifyClick(id)}>Enable Verification</Button>
                  ) : null}
                </>
              ) : null}

              <Flex gap={'5px'}>

                {!companyData?.status && companyData?.verify ? (
                  <>
                    {isLegalFormApproved ? (
                      <Flex gap={2}>
                        {(userRole == 'admin' || userRole == 'media') ? (
                          <>
                            <Button isDisabled={!isComissionFormFilled} onClick={() => handleActivateClick(1)} colorScheme={'orange'} size={'md'}>Activate {activateResponse?.isLoading ? <Spinner ml={2} size={'xs'} /> : ''}</Button>

                            {!isComissionFormFilled ? (
                              <Tooltip
                                borderRadius={5}
                                label={
                                  <Box fontSize={"sm"} p={"5px"}>
                                    <Text> Please fill the commission form to proceed</Text>
                                  </Box>
                                }
                                fontSize='md'
                              >
                                <InfoOutlineIcon pointerEvents={'all'} color={'red.400'} />
                              </Tooltip>
                            ) : null}
                          </>
                        ) : null}

                      </Flex>
                    ) : (null)}
                  </>
                ) : null}


              </Flex>

            </Flex>
            <Flex mb={'20px'} gap={'10px'} align={'center'} justify={'space-between'}>
              <Flex gap={'10px'} align={'baseline'}>
                <Heading as={'h4'} size={'sm'}>{companyName}</Heading>
                <Box>
                  <Badge fontSize={'10px'} colorScheme="gray"> {companyType} </Badge>
                </Box>
                <Box>
                  <Badge fontSize={'10px'} colorScheme="gray"> {entity} </Badge>
                </Box>
              </Flex>
              <Flex gap={'10px'} align={'baseline'} flexDirection={'column'} alignItems={'center'}>

                <Flex gap={'10px'}>
                  <Button size={'sm'} onClick={() => navigate(`/companies/${id}`)}>View</Button>
                  {userRole == 'media' || userRole == 'admin' ? (
                    <Button size={'sm'} onClick={() => navigate(`/companies/${id}/edit`)} colorScheme='orange'>Edit</Button>
                  ) : null}

                  {userRole == 'media' || userRole == 'admin' ? (
                    <>
                      {!companyData?.status ? (
                        <Button size={'sm'} onClick={() => navigate(`/companies/${id}/verify`)}>Verify</Button>
                      ) : null}
                    </>
                  ) : null}
                </Flex>


              </Flex>
            </Flex>
            <Flex >
              <Tabs
                position='relative'
                variant='unstyled'
                sx={{
                  display: "flex",
                  position: "relative",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <TabList gap={"10px"}>
                  {userRole == 'admin' ? (
                    <Tab borderWidth={1} borderRadius={5} _selected={{ color: "white", bg: "brand.200" }}>
                      General{" "}
                    </Tab>
                  ) : null}

                  {userRole != 'finance' ? (
                    <Tab borderWidth={1} borderRadius={5} _selected={{ color: "white", bg: "brand.200" }}>
                      KYC{" "}
                    </Tab>
                  ) : null}

                  {userRole != 'finance' ? (
                    <Tab borderWidth={1} borderRadius={5} _selected={{ color: "white", bg: "brand.200" }}>
                      Bank
                    </Tab>
                  ) : null}


                  <Tab borderWidth={1} borderRadius={5} _selected={{ color: "white", bg: "brand.200" }}>
                    Commission
                  </Tab>

                </TabList>
                <Divider mt={4} />
                <TabPanels py={"40px"}>

                  {userRole == 'admin' ? (
                    <TabPanel p={0}>
                      <GeneralDetailsForm
                        companyId={id}
                        isLoading={isCompanyDataLoading}
                        entity={companyData && companyData?.entity}
                        onGeneralDetailsFormSubmit={handleGeneralDetailsFormSubmit}
                        details={companyData}
                      />
                    </TabPanel>
                  ) : null}

                  {userRole != 'finance' ? (
                    <TabPanel p={0}>
                      <LegalDetailsForm
                        companyId={id}
                        isLoading={isCompanyDataLoading}
                        entity={companyData && companyData?.entity}
                        onLegalDetailsFormSubmit={handleLegalDetailsFormSubmit}
                        details={companyData?.legal}
                      />
                    </TabPanel>
                  ) : null}

                  {userRole != 'finance' ? (
                    <TabPanel p={0}>

                      <Grid gridTemplateColumns={"repeat( auto-fill, minmax(240px, 1fr) )"} gap={"20px"}>

                        <>
                          <Flex
                            borderWidth={1}
                            borderColor={addBoxBorder}
                            borderRadius={5}
                            p={"20px"}
                            w='100%'
                            minH={"200px"}
                            _hover={{
                              bg: addBoxHoverBackground,
                              cursor: "pointer",
                            }}
                            alignItems={"center"}
                            justifyContent={"center"}
                            flexDirection={"column"}
                            onClick={onOpen}
                          >
                            <AddIcon boxSize={6} mb={2} />
                            <Text size={"md"}>Add New</Text>
                          </Flex>

                        </>
                        {bankDetailsList}
                      </Grid>
                    </TabPanel>
                  ) : null}


                  <TabPanel p={0}>
                    <CommissionForm
                      companyId={id}
                      isLoading={isCompanyDataLoading}
                      entity={companyData && companyData?.entity}
                      onCommissionFormSubmit={handleCommissionFormSubmit}
                      details={companyData?.commission}
                    />
                  </TabPanel>

                </TabPanels>
              </Tabs>
            </Flex>
          </>
        ) : (
          <Text>Sorry, you are not authorized to view this page</Text>
        )}

      </SidebarWithHeader>
    </>
  );
}

export default EditCompanyForAdmin;
