import {
  Badge,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Spinner,
  Switch,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { Formik, useFormik } from "formik";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FiDelete, FiDollarSign, FiEye } from "react-icons/fi";
import { useAddNewRequestMutation, useEditRequestMutation, useGetRequestsQuery, useRemoveRequestMutation, useReplyToRequestMutation, useUpdateStatusMutation, useUploadFileMutation } from 'store/requests/requestsApi';
import { editRequestSchema, replyToRequestSchema, requestSchema, transferFormSchema } from "utils/schema";

import { SingleDatepicker } from "chakra-dayzed-datepicker";
import AppTableWithPagination from "components/AppTableWithPagination";
import CircleIcon from 'components/CircleIcon';
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import LightBox from "components/LightBox";
import MultiFileUploadButton from "components/MultiFileUploadButton";
import SelectColumnFilter from "components/SelectColumnFilter";
import SidebarWithHeader from "components/SidebarWithHeader";
import moment from 'moment';
import { useSelector } from "react-redux";
import { useGetAccountsByUserIDQuery } from "store/adAccounts/adAccountsApi";
import { useGetCompaniesByUserIDQuery } from "store/companies/companiesApi";
import { useTransferAccountBalanceMutation } from "store/topUp/topUpApi";


function RequestsForAdmin() {
  const [selectedRequest, setselectedRequest] = useState(null);
  const [replyFiles, setreplyfiles] = useState([]);
  const [files, setfiles] = useState([]);
  const [activeRequest, setactiveRequest] = useState(null);
  const editFormRef = useRef();
  const toast = useToast();
  const userInfo = useSelector((state) => state.auth?.userInfo);
  const userRole = useSelector((state) => state.auth?.userInfo?.role);
  const btnRef = React.useRef()

  const [activeFromAccount, setactiveFromAccount] = useState(null);
  const [activeToAccount, setactiveToAccount] = useState(null);

  const { _id: clientId } = userInfo;

  const { isOpen, onOpen, onClose } = useDisclosure();

  let statusBadgeColor = 'gray';

  if (activeRequest?.status === 'pending') {
    statusBadgeColor = 'yellow';
  } else if (activeRequest?.status === 'closed') {
    statusBadgeColor = 'gray';
  } else if (activeRequest?.status === 'open') {
    statusBadgeColor = 'green';
  }

  const requestTypeLabel = {
    ad_account_disabled: "Review disabled Ad account ",
    share_access_to_bm: "Share access to a different BM",
    manually_charge_credit_card: "Manually charging Credit Card",
    change_credit_card: "Change Credit Card",
    ad_account_fund_transfer: "Ad account fund transfer",
    others: "Others",
  };

  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();
  const {
    isOpen: isEditModalOpen,
    onOpen: onEditModalOpen,
    onClose: onEditModalClose,
  } = useDisclosure();

  const {
    isOpen: isDescriptionModalOpen,
    onOpen: onDescriptionModalOpen,
    onClose: onDescriptionModalClose,
  } = useDisclosure();

  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();

  const {
    isOpen: isTransferModalOpen,
    onOpen: onTransferModalOpen,
    onClose: onTransferModalClose,
  } = useDisclosure();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setsearchValue] = useState('');

  const [requestToDelete, setrequestToDelete] = useState(null);

  const scrollBoxRef = useRef();
  const scrollToBottom = () => {
    scrollBoxRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  useEffect(() => {
    if (activeRequest) {
      setTimeout(() => {
        scrollToBottom();
      }, 10);
    }
  }, [activeRequest]);

  /* QUERIES */

  const {
    data: requests,
    isLoading: isRequestsLoading,
    refetch,
    isFetching
  } = useGetRequestsQuery({ currentPage, searchValue }, {
    pollingInterval: 10000,
    skipPollingIfUnfocused: true,
  });

  // const {
  //   data: companies,
  //   error: getCompaniesError,
  //   isLoading: isCompaniesDataLoading,
  // } = useGetAllCompaniesQuery();

  // const {
  //   data: accounts,
  //   error: getAccountsError,
  //   isLoading: isAccountsDataLoading,
  // } = useGetAllAccountsQuery();

  const {
    data: accounts,
    error: getAccountsError,
    isLoading: isAccountsDataLoading
  } = useGetAccountsByUserIDQuery({ userId: activeRequest?.clientId?.id }, {
    skip: !activeRequest
  });


  const {
    data: companies,
    error: getCompaniesError,
    isLoading: isCompaniesDataLoading,
    refetch: refetchUserCompanies
  } = useGetCompaniesByUserIDQuery({ userId: activeRequest?.clientId?.id }, {
    skip: !activeRequest
  });



  const [removeRequest, removeResponse] = useRemoveRequestMutation();
  const [editRequest, editResponse] = useEditRequestMutation();
  const [addNewRequest] = useAddNewRequestMutation();
  const [replyToRequest, replyResponse] = useReplyToRequestMutation();
  const [updateStatus, updateStatusResponse] = useUpdateStatusMutation();
  const [uploadFile, uploadResponse] = useUploadFileMutation();
  const [transferAccountBalance, transferResponse] = useTransferAccountBalanceMutation();

  //console.log({ accounts });

  useEffect(() => {
    if (searchValue != '') {
      setCurrentPage(1);
    }
  }, [searchValue])

  useEffect(() => {
    //console.log(newTopUpResponse);
    if (removeResponse?.isSuccess) {
      toast({
        position: "top-right",
        title: "Request removed",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    if (removeResponse.status == "rejected") {
      const {
        data: { message },
      } = removeResponse.error;
      toast({
        position: "top-right",
        title: "Error",
        description: message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [removeResponse]);

  useEffect(() => {
    if (replyResponse.status == "rejected") {
      toast({
        position: "top-right",
        title: "Error",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
    if (replyResponse?.data) {
      const { data: { _id } } = replyResponse.data;
      if (activeRequest?._id == _id) {
        setactiveRequest(replyResponse?.data?.data)
      }
      toast({
        position: "top-right",
        title: "Reply sent",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [replyResponse]);

  useEffect(() => {
    //console.log(newTopUpResponse);
    if (updateStatusResponse?.isSuccess) {
      const { data: { _id } } = updateStatusResponse.data;
      if (activeRequest?._id == _id) {
        setactiveRequest(updateStatusResponse?.data?.data)
      }
      toast({
        position: "top-right",
        title: "Status updated",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    if (updateStatusResponse.status == "rejected") {

      toast({
        position: "top-right",
        title: "Error",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [updateStatusResponse]);

  useEffect(() => {
    //console.log(newTopUpResponse);
    if (editResponse?.isSuccess) {
      toast({
        position: "top-right",
        title: "Reply sent",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    if (editResponse.status == "rejected") {
      const {
        data: { message },
      } = editResponse.error;
      toast({
        position: "top-right",
        title: "Error",
        description: message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [editResponse]);

  useEffect(() => {
    if (uploadResponse?.isSuccess) {
      toast({
        position: "top-right",
        title: "File uploaded",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    if (uploadResponse.status == "rejected") {

      toast({
        position: "top-right",
        title: "Error",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [uploadResponse]);

  useEffect(() => {
    //console.log(newTopUpResponse);
    if (transferResponse?.isSuccess) {

      toast({
        position: "top-right",
        title: "Request placed",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    if (transferResponse.status == "rejected") {

      toast({
        position: "top-right",
        title: "Error",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [transferResponse]);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "ticketNumber",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Text>{"#" + original.ticketNumber}</Text>
        ),
      },
      {
        Header: "Ad Account",
        accessor: "adsAccount",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Text>
            {original.adsAccount?.id ? original.adsAccount?.title : "NA"}
          </Text>
        ),
      },
      {
        Header: 'Ads Account Id',
        accessor: '',
        Filter: '',
        filter: '',
        Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.adsAccount?.id}</Text>)
      },
      {
        Header: "Request Type",
        accessor: "requestType",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Text>{requestTypeLabel[original.requestType]}</Text>
        ),
      },
      {
        Header: "Client Email",
        accessor: "",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Text>{original.clientId?.email}</Text>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: SelectColumnFilter,
        filter: "contains",
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
          // console.log(original);
          let badgeColor = "gray";
          let statusCopy = original.status;
          if (original.status == "pending") {
            badgeColor = "yellow";
            statusCopy = "In review"
          }
          if (original.status == "open") {
            badgeColor = "green";
          }
          return (
            <>
              <Badge fontSize={"10px"} marginRight={1} colorScheme={badgeColor}>
                {statusCopy}
              </Badge>
              {original.adminRead ? <CircleIcon boxSize={2} color='green.500' /> : ""}
            </>
          );
        },
      },
      {
        Header: "Created date",
        accessor: "createdDate",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Text>
            {moment(original.createdDate).format("DD-MM-YYYY hh:mm A")}
          </Text>
        ),
      },
      {
        Header: "Updated date",
        accessor: "updatedDate",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Text>
            {moment(original.updatedDate).format("DD-MM-YYYY hh:mm A")}
          </Text>
        ),
      },
      {
        Header: "",
        accessor: "action",
        Filter: "",
        filter: "",
        disableSortBy: true,
        Cell: ({ row: { original } }) => (
          <Flex justifyContent={"end"} alignItems={"center"} gap={"20px"}>
            {original.requestType == 'ad_account_fund_transfer' ? (
              <Tooltip label="Fund Transfer" fontSize="xs">
                <Box>
                  <Icon
                    _hover={{ color: "brand.300" }}
                    as={FiDollarSign}
                    cursor={"pointer"}
                    onClick={() => {
                      onTransferModalOpen()
                      setactiveRequest(original);
                    }}
                  />
                </Box>
              </Tooltip>
            ) : null}


            <Tooltip label="View" fontSize="xs">
              <Box>
                <Icon
                  _hover={{ color: "gray.500" }}
                  as={FiEye}
                  cursor={"pointer"}
                  onClick={() => {
                    setactiveRequest(original);
                    onDrawerOpen();
                  }}
                />
              </Box>
            </Tooltip>
            <Tooltip label="Delete" fontSize="xs">
              <Box>
                <Icon
                  color={"red.500"}
                  _hover={{ color: "red.300" }}
                  as={FiDelete}
                  cursor={"pointer"}
                  onClick={async () => {
                    setrequestToDelete(original?._id);
                    onDeleteModalOpen();
                  }}
                />
              </Box>
            </Tooltip>
          </Flex>
        ),
      },
    ],
    []
  );

  const initialTableState = {
    sortBy: [
      {
        id: "updatedDate",
        desc: true
      }
    ],
  };

  const handleDeleteCancel = () => {
    onDeleteModalClose();
    setrequestToDelete(null);
  };

  const handleDeleteConfirm = async () => {
    onDeleteModalClose();
    await removeRequest(requestToDelete);
  };

  // const {
  //   data: adAccount,
  //   error: getAdsAccountError,
  //   isLoading: isAccountDataLoading,
  // } = useGetAdsAccountQuery();

  const getAdAccountTitle = (id) => {

    // console.log({ adAccount });

    const filteredAccount = accounts?.data?.filter((account) => {
      return account._id === id;
    })
    return filteredAccount && filteredAccount[0]?.adsAccountName;
  }

  const formik = useFormik({
    initialValues: {
      adsAccount: "",
      company: "",
      description: "",
    },
    validationSchema: requestSchema,
    onSubmit: async (values) => {
      const companyDetails = companies?.filter((company) => company._id == values.company);
      const { _id: companyId, companyName } = companyDetails[0] || {}
      const AdAccountDetails = accounts.filter((account) => account._id == values.adsAccount);
      const { _id: adAccountId, adsAccountName } = AdAccountDetails[0] || {}
      const payload = {
        description: values.description,
        adsAccount: { id: adAccountId, title: adsAccountName },
        company: { id: companyId, title: companyName },
      }
      if (values) {
        await addNewRequest(payload)
      }
      formik.resetForm();
      onClose();
    },
  });

  const replyForm = useFormik({
    initialValues: {
      content: '',
      attachments: '',
    },
    validationSchema: replyToRequestSchema,
    onSubmit: async (values) => {
      const { content } = values
      const attachments_ = replyFiles?.map((file, index) => file.value)

      const payload = {
        content,
        attachments: attachments_,
        checked: true
      }

      // //console.log({ payload });

      if (values) {
        await replyToRequest({ requestId: activeRequest?._id, payload });

      }
      replyForm.resetForm();
      setreplyfiles([])
    },
  });

  const transferForm = useFormik({
    initialValues: {
      fromAdsAccount: "",
      toAdsAccount: "",
      amount: "",
      transferDate: new Date()
    },
    validationSchema: transferFormSchema,
    onSubmit: async (values) => {

      const { fromAdsAccount, toAdsAccount, transferDate, amount } = values;
      setactiveFromAccount(fromAdsAccount)
      setactiveToAccount(toAdsAccount)
      const fromAdsAccountTitle = getAdAccountTitle(fromAdsAccount)
      const toAdsAccountTitle = getAdAccountTitle(toAdsAccount)

      const { clientId: { id, name, email }, ticketNumber } = activeRequest;

      const payload = {
        fromAdsAccount: { id: fromAdsAccount, name: fromAdsAccountTitle },
        toAdsAccount: { id: toAdsAccount, name: toAdsAccountTitle },
        amount,
        transferDate,
        clientId: { id, name, email },
        ticketNumber: `#${ticketNumber}`
      }

      //console.log({ payload });

      if (values) {
        await transferAccountBalance(payload)
      }
      transferForm.resetForm();
      onTransferModalClose();
    },
  });


  const companyOptions = companies?.data?.map(({ _id, companyName }, index) => (
    <option value={_id} key={_id}>
      {companyName}
    </option>
  ));

  const adAccountsOptions = accounts?.data?.map(
    ({ adsAccountId, adsAccountName, _id }) => (
      <option value={_id} key={_id}>
        {adsAccountName}
      </option>
    )
  );

  const messageHistory = activeRequest && activeRequest.messages?.map(({ content, author: { name, id }, createdAt, attachments }, index) => {

    return (
      <Box mb={'10px'} key={'request' + index}>
        <Box
          ml={id == clientId ? '20%' : '0'}
          mr={id != clientId ? '20%' : '0'}
          boxShadow='sm'
        >
          <Box
            background={id == clientId ? 'blue.50' : 'white'}
            p={'5px 10px'} borderRadius={5} borderColor={'gray.200'} borderWidth={1}>
            <Text fontSize={'sm'} color={"gray.600"}>{content}</Text>
            <Flex gap={'5px'}>
              {attachments?.length > 0 && attachments?.map((file, i) => (
                <LightBox fileSource={file} key={'attach_' + i} />
              ))}
            </Flex>
            <Text textAlign={'right'} mt={'5px'} fontSize={'9px'} color={'gray.600'}>{moment(createdAt).format("DD-MM-YYYY hh:mm A") + " • " + name}</Text>
          </Box>
        </Box>
      </Box>
    )
  })

  const handleFileUpload = (filename, acceptedFiles) => {
    acceptedFiles.map((file, index) => {
      if (filename == 'attachments') {
        formik.setFieldValue('attachments', file)
      }
      if (filename == 'replyAttachments') {
        replyForm.setFieldValue('attachments', file)
      }
      const reader = new FileReader();
      reader.onload = async function (e) {
        //console.log({ filename, file });
        const formData = new FormData();
        formData.append('attachments', file)
        await uploadFile(formData)
          .unwrap()
          .then((payload) => {
            //console.log('fulfilled', payload)
            const { name, value } = payload?.data;
            if (filename == 'attachments') {
              setfiles(files => [...files, { name, value }]);
            }
            if (filename == 'replyAttachments') {
              setreplyfiles(files => [...files, { name, value }]);
            }


          })
      };
      reader.readAsDataURL(file);
      return file;
    });
  }

  const removeFileSource = (filekey, index) => {
    if (filekey == 'attachments') {
      if (index < 0 || index >= files.length) {
        return;
      }
      const newArray = [...files];
      newArray.splice(index, 1);
      setfiles(newArray)
    }

    if (filekey == 'replyAttachments') {
      if (index < 0 || index >= replyFiles.length) {
        return;
      }
      const newArray = [...replyFiles];
      newArray.splice(index, 1);
      setreplyfiles(newArray)
    }
  }


  return (
    <>
      {/* AD ACCOUNT TRANSFER FORM */}

      <Modal scrollBehavior={'inside'} size={'2xl'} isCentered={true} isOpen={isTransferModalOpen} onClose={() => { onTransferModalClose(); transferForm.resetForm() }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'}>Ads account balance transfer</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl display={'flex'} flexDirection={'column'} gap={'20px'}>
              <Box flex={1}>
                <FormLabel fontSize='sm' mb={'10px'}>
                  Confirm from ad account
                </FormLabel>
                <Select
                  flex={1}
                  name="fromAdsAccount"
                  placeholder='Select *'
                  onChange={transferForm.handleChange}
                  onBlur={transferForm.handleBlur}
                  value={transferForm.values.fromAdsAccount}
                  isInvalid={transferForm.touched.fromAdsAccount && transferForm.errors.fromAdsAccount ? true : false}
                >
                  {accounts?.data?.filter(({ accountStatus, clientId }, index) => accountStatus == 1 && clientId?.id == activeRequest?.clientId?.id).map(({ adsAccountName, _id, adsAccountId }) => (
                    <option
                      value={_id}
                      key={_id}
                      disabled={_id == transferForm.values.toAdsAccount}
                    >
                      {adsAccountName + " " + `(${adsAccountId})`}
                    </option>
                  ))}
                </Select>
                {transferForm.touched.fromAdsAccount && transferForm.errors.fromAdsAccount && (
                  <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{transferForm.errors.fromAdsAccount}</Text>
                )}
              </Box>

              <Box flex={1}>
                <FormLabel fontSize='sm' mb={'10px'}>
                  Confirm to ad account
                </FormLabel>
                <Select
                  flex={1}
                  name="toAdsAccount"
                  placeholder='Select *'
                  onChange={transferForm.handleChange}
                  onBlur={transferForm.handleBlur}
                  value={transferForm.values.toAdsAccount}
                  isInvalid={transferForm.touched.toAdsAccount && transferForm.errors.toAdsAccount ? true : false}
                >
                  {accounts?.data?.filter(({ accountStatus, clientId }, index) => accountStatus == 1 && clientId?.id == activeRequest?.clientId?.id).map(({ adsAccountName, _id, adsAccountId }) => (
                    <option
                      value={_id}
                      key={_id}
                      disabled={_id == transferForm.values.fromAdsAccount}
                    >
                      {adsAccountName + " " + `(${adsAccountId})`}
                    </option>
                  ))}
                </Select>
                {transferForm.touched.toAdsAccount && transferForm.errors.toAdsAccount && (
                  <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{transferForm.errors.toAdsAccount}</Text>
                )}
              </Box>
              <Box flex={1}>
                <FormLabel fontSize='sm' mb={'10px'}>
                  Amount
                </FormLabel>
                <Input
                  name='amount'
                  placeholder='Amount'
                  onChange={transferForm.handleChange}
                  onBlur={transferForm.handleBlur}
                  value={transferForm.values.amount}
                  isInvalid={transferForm.touched.amount && transferForm.errors.amount ? true : false}
                />
                {transferForm.touched.amount && transferForm.errors.amount && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {transferForm.errors.amount}
                  </Text>
                )}
              </Box>
              <Box flex={1}>
                <FormLabel fontSize='sm' mb={'10px'}>
                  Date of transfer
                </FormLabel>
                <SingleDatepicker
                  name="transferDate"
                  date={transferForm.values.transferDate}
                  maxDate={new Date()}
                  onDateChange={(date) => {
                    const timestamp = moment(date, "ddd MMM DD YYYY HH:mm:ss Z").valueOf();
                    transferForm.setFieldValue('transferDate', timestamp)
                  }}
                />
              </Box>

              <Flex gap={'20px'} mb={'10px'} mt={'20px'} justify={'center'}>
                <Button colorScheme='orange' onClick={transferForm.handleSubmit}>
                  Submit
                </Button>
                <Button onClick={() => { transferForm.resetForm(); onClose() }}>
                  Cancel
                </Button>
              </Flex>
            </FormControl>

          </ModalBody>
        </ModalContent>
      </Modal>

      {/* REQUEST HISTORY MODAL */}
      <Drawer
        isOpen={isDrawerOpen}
        placement='right'
        onClose={() => {
          onDrawerClose();
          setactiveRequest(null)
        }}
        finalFocusRef={btnRef}
        size={'md'}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Flex align={'center'}>
              <Text>Request History</Text>
              <Badge ml={'10px'} fontSize={'10px'} colorScheme={statusBadgeColor}>{activeRequest?.status == 'pending' ? 'In review' : activeRequest?.status}</Badge>
            </Flex>
            <Text color={'gray.600'} fontSize={'xs'}>User: {activeRequest?.clientId?.name}</Text>
          </DrawerHeader>


          <DrawerBody background={'gray.50'} borderWidth={1} borderColor={'gray.300'} borderLeftWidth={0} borderRightWidth={0}>
            <Box p={'10px'} ref={scrollBoxRef}>
              {messageHistory}
            </Box>
          </DrawerBody>

          {(userRole == 'admin' || userRole == 'media') ? (
            <DrawerFooter>
              <Box flex={1}>
                <>
                  <Flex mb={'20px'} gap={'10px'}>
                    <>
                      {activeRequest?.status != 'pending' ? (
                        <Button
                          size={'xs'}
                          variant='outline'
                          colorScheme='orange'
                          onClick={async () => {
                            await updateStatus({ requestId: activeRequest._id, payload: { status: 'pending' } })
                          }}
                        >Raise issue</Button>
                      ) : null}

                      <Button
                        size={'xs'}
                        variant='outline'
                        colorScheme='red'
                        onClick={async () => {
                          await updateStatus({ requestId: activeRequest._id, payload: { status: 'closed' } });
                          onDrawerClose();
                        }}
                      >Close ticket</Button>
                    </>
                  </Flex>
                  <Box gap={'10px'}>
                    <Box flex={1} mb={'10px'}>
                      <Textarea
                        size='md'
                        name='content'
                        placeholder='Message'
                        onChange={replyForm.handleChange}
                        onBlur={replyForm.handleBlur}
                        value={replyForm.values.content}
                        isInvalid={replyForm.touched.content && replyForm.errors.content ? true : false}
                      />
                      {replyForm.touched.content && replyForm.errors.content && (
                        <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{replyForm.errors.content}</Text>
                      )}
                    </Box>

                    <Flex flex={1} justify={'space-between'}>
                      <MultiFileUploadButton
                        fileSource={replyFiles}
                        fileKey='replyAttachments'
                        onFilesSelected={handleFileUpload}
                        onRemoveClick={(index) => removeFileSource('replyAttachments', index)}
                        isDisabled={!replyForm.values.content}
                      />

                      {replyForm.touched.attachments && replyForm.errors.attachments && (
                        <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                          {replyForm.errors.attachments}
                        </Text>
                      )}
                      <Button
                        size={'sm'}
                        onClick={replyForm.handleSubmit}
                        isDisabled={!replyForm.values.content || uploadResponse.isLoading}
                      >Reply</Button>
                    </Flex>
                  </Box>
                </>
              </Box>
            </DrawerFooter>
          ) : null}


        </DrawerContent>
      </Drawer>

      {/* ADD REQUEST MODAL */}

      <Modal
        size={"lg"}
        isCentered={true}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          formik.resetForm();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"}>Place a Request</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl display={"flex"} flexDirection={"column"} gap={"20px"}>
              {adAccountsOptions && adAccountsOptions?.length > 0 ? (
                <Box flex={1}>
                  <Select
                    flex={1}
                    name='adsAccount'
                    placeholder='Ads Account'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.adsAccount}
                    isInvalid={
                      formik.touched.adsAccount && formik.errors.adsAccount
                        ? true
                        : false
                    }
                  >
                    {adAccountsOptions}
                  </Select>
                  {formik.touched.adsAccount && formik.errors.adsAccount && (
                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                      {formik.errors.adsAccount}
                    </Text>
                  )}
                </Box>
              ) : null}

              <Box flex={1}>
                <Select
                  flex={1}
                  name='company'
                  placeholder='For Company'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.company}
                  isInvalid={
                    formik.touched.company && formik.errors.company
                      ? true
                      : false
                  }
                >
                  {companies && companyOptions}
                </Select>
                {formik.touched.company && formik.errors.company && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {formik.errors.company}
                  </Text>
                )}
              </Box>
              <Box flex={1}>
                <Textarea
                  size='md'
                  name='description'
                  placeholder='Enter the request text'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  isInvalid={
                    formik.touched.description && formik.errors.description
                      ? true
                      : false
                  }
                />
                {formik.touched.description && formik.errors.description && (
                  <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                    {formik.errors.description}
                  </Text>
                )}
              </Box>
              <Flex gap={"20px"} mb={"10px"} mt={"20px"} justify={"center"}>
                <Button onClick={formik.handleSubmit}>Submit</Button>
                <Button
                  onClick={() => {
                    formik.resetForm();
                    onClose();
                  }}
                >
                  Cancel
                </Button>
              </Flex>
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* EDIT REQUEST MODAL */}

      <Modal
        scrollBehavior={"inside"}
        size={"md"}
        isCentered={true}
        isOpen={isEditModalOpen}
        onClose={() => {
          onEditModalClose();
          editFormRef.current?.resetForm();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize={"2xl"} textAlign={"center"} mb={"20px"}>
              Reply
            </Text>

            <Formik
              innerRef={editFormRef}
              validationSchema={editRequestSchema}
              initialValues={{ checked: "", notes: "" }}
              enableReinitialize
              onSubmit={async (values, { resetForm }) => {
                // alert(JSON.stringify(values));
                if (values) {
                  await editRequest({ requestId: selectedRequest, payload: values });
                }
                setselectedRequest(null);
                resetForm();
                onEditModalClose();
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid,
              }) => (
                <Flex gap={"20px"} flexDirection={"column"}>
                  {/* <Box flex={1}>
                    <FormLabel ms='4px' fontSize='sm' fontWeight='bold'>
                      Status
                    </FormLabel>
                    <Select
                      flex={1}
                      name='status'
                      placeholder='Select'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.status}
                      isInvalid={touched.status && errors.status ? true : false}
                    >
                      <option value='open'>Open</option>
                      <option value='closed'>Closed</option>
                    </Select>
                    {touched.status && errors.status && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {errors.status}
                      </Text>
                    )}
                  </Box> */}
                  <FormControl display={"flex"} flexDirection={"row"}>
                    <FormLabel fontSize={"sm"} mb='0'>
                      Checked
                    </FormLabel>
                    <Switch
                      id={'checked'}
                      onChange={handleChange}
                      name={'checked'}
                      colorScheme='green'
                      isChecked={values.checked}

                    />
                    {touched.checked && errors.checked && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {errors.checked}
                      </Text>
                    )}
                  </FormControl>
                  <Box flex={1}>
                    <Textarea
                      name='notes'
                      placeholder='Notes'
                      onChange={handleChange}
                      value={values.notes}
                      isInvalid={touched.notes && errors.notes ? true : false}
                      minLength={10}
                    />
                    {touched.notes && errors.notes && (
                      <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                        {errors.notes}
                      </Text>
                    )}
                  </Box>

                  <Button
                    w={"full"}
                    mt={4}
                    mb={4}
                    onClick={handleSubmit}
                    isDisabled={!isValid}
                  >
                    Submit
                  </Button>
                </Flex>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
      <ConfirmDeleteModal
        copy='Are you sure you want to delete the request'
        isModalOpen={isDeleteModalOpen}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />
      <SidebarWithHeader>
        <Flex mb={"20px"}>
          <Heading as='h1' size='xl'>
            Track Requests
          </Heading>
          <Spacer />
        </Flex>
        <Flex flexDirection={"column"} flex={"1"}>

          {isRequestsLoading ? <Spinner /> : (
            <>
              {/* {requests && requests?.length > 0 ? (
                <Box >
                  <AppTable columns={columns} data={requests} searchEnabled={true} initialState={initialTableState} />
                </Box>
              ) : (
                <Text>
                  No requests yet.
                </Text>
              )} */}

              <AppTableWithPagination
                columns={columns}
                data={requests?.data}
                searchTerm={searchValue}
                paginationMeta={requests?.meta}
                onPageChange={(index) => setCurrentPage(index)}
                onSearchInputChange={(searchInput) => setsearchValue(searchInput)}
                searchEnabled={true}
                isFetching={isFetching}
              />

            </>

          )}

        </Flex>
      </SidebarWithHeader>
    </>
  );
}


export default RequestsForAdmin;
