import { Box, Text, Center } from "@chakra-ui/react";
import { Component } from "react";

const ErrorView = ({ error, errorInfo }) => (
    <Box minH={'100vh'} minW={'100vw'} p={6}>
        <Center alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
            <Text fontSize={'medium'} fontWeight={'bold'} color={'red.600'}>SOMETHING WENT WRONG</Text>
            {error && (
                <Text>{error.toString()}</Text>
            )}
        </Center>
    </Box>
);

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
        // You can also log error messages to an error reporting service here
    }

    render() {
        const { error, errorInfo } = this.state;
        if (errorInfo) {
            // Error path
            return <ErrorView {...{ error, errorInfo }} />;
        }
        // Normally, just render children
        return this.props.children;
    }
}

export function errorBoundary(WrappedComponent) {
    return class extends ErrorBoundary {
        render() {
            const { error, errorInfo } = this.state;
            if (errorInfo) {
                // Error path
                return <ErrorView {...{ error, errorInfo }} />;
            }
            //Normally, just render wrapped component
            return <WrappedComponent {...this.props} />;
        }
    };
}