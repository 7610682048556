import { api } from '../api';
import { setUser } from './authSlice';

export const authApi = api.injectEndpoints({
    endpoints: (builder) => ({
        loginUser: builder.mutation({
            query(body) {
                return {
                    url: '/accounts/login',
                    method: 'POST',
                    body
                }
            },
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => response.data,
            // Pick out errors and prevent nested properties in a hook or selector
            transformErrorResponse: (response, meta, arg) => {
                const error = new Error(response.data.message);
                error.status = response.status;
                return error;
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    //console.log(data);
                    dispatch(setUser(data));
                    localStorage.removeItem('forexUser');
                    localStorage.setItem("forexUser", JSON.stringify(data));
                } catch (error) {
                    //console.log('onQuery', error);
                }
            },
        }),
        signUpUser: builder.mutation({
            query(body) {
                return {
                    url: '/accounts/signUp',
                    method: 'POST',
                    body
                }
            },
            transformErrorResponse: (response, meta, arg) => {
                const error = new Error(response.data.message);
                error.status = response.status;
                return error;
            },
        }),
        forgotPassword: builder.mutation({
            query: payload => ({
                url: '/accounts/forgot-password',
                method: 'POST',
                body: payload
            }),
        }),
        forgotPasswordChange: builder.mutation({
            query: payload => ({
                url: '/accounts/forgot-password-change',
                method: 'POST',
                body: payload
            }),
        }),
        changePassword: builder.mutation({
            query: payload => ({
                url: '/accounts/change-password',
                method: 'POST',
                body: payload
            }),
        }),
        verifyEmail: builder.mutation({
            query: payload => ({
                url: '/accounts/verify',
                method: 'POST',
                body: payload
            }),
        }),
        getSingleClient: builder.query({
            query: id => ({
                url: `/accounts/${id}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
        }),
    })
})

export const { useLoginUserMutation, useSignUpUserMutation, useForgotPasswordMutation, useForgotPasswordChangeMutation, useChangePasswordMutation, useVerifyEmailMutation, useGetSingleClientQuery } = authApi;